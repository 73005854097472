
import Vue from 'vue';
import { endpoints } from '@/constants';
import validationRules from '@/validation-rules';
import { getDoc } from 'firebase/firestore';
import { t } from '@/util-functions/language-utils';
import { getUserDoc, updateUserLocal } from '@/util-functions/user-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { standardApiFetch } from '@/util-functions/initialization-utils';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data(): {
    rules: typeof validationRules;
    emailOptions: TextValue[];
    newEmailToAdd: string;
    emailAwaitingVerificationFor: string;
    isAddingNewEmail: boolean;
    currSelectedEmail: string;
  } {
    const emailOptions = [];
    this.$store.state.currUser.additionalVerifiedEmails.forEach((email: string) => {
      emailOptions.push({
        text: email,
        value: email,
      });
    });
    emailOptions.push({
      text: this.$store.state.currUser.email,
      value: this.$store.state.currUser.email,
    });
    emailOptions.push({
      text: t.addNewEmail,
      value: 'addNewEmail',
    });
    emailOptions.push({
      text: t.doNotNotify,
      value: '',
    });
    return {
      rules: validationRules,
      emailOptions,
      newEmailToAdd: '',
      emailAwaitingVerificationFor: '',
      isAddingNewEmail: false,
      currSelectedEmail: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.currSelectedEmail = newValue;
    },
  },
  computed: {
    newEmailToAddIsLegitEmail(): boolean {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(this.newEmailToAdd);
    },
  },
  methods: {
    onFinishedVerifyingEmail() {
      getDoc(getUserDoc())
        .then((currUserDocSnap) => {
          if (currUserDocSnap.exists()) {
            const newUserData = currUserDocSnap.data() as PlatformUser;
            updateUserLocal(newUserData);
            if (newUserData.additionalVerifiedEmails.includes(this.emailAwaitingVerificationFor)) {
              this.currSelectedEmail = this.emailAwaitingVerificationFor;
              this.emailOptions.unshift({
                text: this.emailAwaitingVerificationFor,
                value: this.emailAwaitingVerificationFor,
              });
              this.$emit('input', this.emailAwaitingVerificationFor);
              this.emailAwaitingVerificationFor = '';
            } else {
              showError(t.emailNotYetVerified);
            }
          }
        })
        .catch((error) => {
          showError(`Could not get the verified email. ${error?.message}`, true);
        });
    },
    verifyEmail() {
      const emailToVerify = this.newEmailToAdd;

      if (!emailToVerify) {
        showError(t.thingRequired.supplant([t.email]));
        return;
      }

      this.isAddingNewEmail = false;
      this.newEmailToAdd = '';
      if ([...this.$store.state.currUser.additionalVerifiedEmails, this.$store.state.currUser.email].includes(emailToVerify)) {
        showError(t.alreadyVerified);
        return;
      }
      standardApiFetch(endpoints.sendVerificationEmail, {
        emailToVerify,
        userId: this.$store.state.userId,
        locale: navigator?.languages[0] || navigator.language || '',
      }).then(() => {
        this.emailAwaitingVerificationFor = emailToVerify;
      });
    },
    onEmailSelectChange(val: string) {
      this.isAddingNewEmail = false;
      switch (val) {
        case 'addNewEmail':
          this.isAddingNewEmail = true;
          return;
        case '':
          this.$emit('input', '');
          return;
        default:
          this.$emit('input', val);
          return;
      }
    },
  },
});
