import { store } from '@/store';
import {
  eMetaTheme,
  eCardStyle,
  eChargeStatuses,
  eCustomFieldTypes,
  eDeliveryTypes,
  eInteractionDeviceTypes,
  eMenuIconTypes,
  eModeType,
  eOrderStatuses,
  eTaxTypes,
  eTriviaCategories,
  eTriviaDifficulty,
} from './enums';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { generateId } from './util-functions/misc-firestore-utils';

export const TEAMS_MAX = 5;
export const TEAM_MEMBERS_MAX = 10;
export const MANAGED_ACCOUNTS_MAX = 10;
export const STANDARD_MAX_SITCHES = 5;
export const PURCHASER_MAX_SITCHES = 10;
export const PREMIUM_MAX_SITCHES = 100;
export const maxSitchLimitEmailAccountExceptions = ['demo@sitch.cards', 'test@sitch.app', 'cmcadjprojets7575@gmail.com']

export const STANDARD_MAX_SHORT_LINKS = 5;
export const PREMIUM_MAX_SHORT_LINKS = 20;

export const STANDARD_UPLOAD_LIMIT_IN_BYTES = 52428800; // 50 MB
export const PREMIUM_UPLOAD_LIMIT_IN_BYTES = 1073741824; // 1 GB

export const STANDARD_STORAGE_LIMIT_IN_BYTES = 524288000; // 500 MB
export const PREMIUM_STORAGE_LIMIT_IN_BYTES = 10737418240; // 10 GB

export const MAX_SHOP_ITEM_QUANTITY = 10_000;

export const ZERO_DECIMAL_CURRENCIES = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'];
export const COUNTRY_CODES = ['de', 'es', 'fr', 'zh', 'en', 'fi', 'sv'];

export const LOCALES = {
  en: 'English',
  zh: 'Chinese',
  es: 'Spanish',
  hi: 'Hindi',
  ar: 'Arabic',
  bn: 'Bengali',
  pt: 'Portuguese',
  ru: 'Russian',
  ja: 'Japanese',
  pa: 'Punjabi',
  it: 'Italian',
  de: 'German',
  fr: 'French',
};

export const draggableOptions = {
  delay: 150,
  delayOnTouchOnly: false,
  ghostClass: 'sortable-ghost',
  chosenClass: 'sortable-chosen',
  dragClass: 'sortable-drag',
};

export const editorOptions = {
  formats: ['bold', 'code', 'italic', 'link', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 'image', 'video'],
  // Removed from the above: background, color.
  modules: {
    imageDrop: true,
    imageResize: {
      toolbarStyles: {
        display: 'none',
      },
    },
    imageCompress: {
      quality: 0.7,
      maxWidth: 700,
      maxHeight: 700,
      imageType: 'image/jpeg',
      debug: true,
    },
  },
};

export const SMALL_FILE_IMAGE_MAX_DIMENSION = 150;
export const LARGE_FILE_IMAGE_MAX_DIMENSION = 3840;
export const DEFAULT_MODE_THEME = 'sitchLight';

export const URL_REGEX = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const getEmptyBasicMode = (): Mode => {
  return {
    docId: '',
    linkId: '',
    type: null, // Just as a default,
    name: '',
    displayName: '',
    index: 1,
    themeId: DEFAULT_MODE_THEME,
  };
};

export const getEmptyLinkListMode = (): Omit<LinkListMode, keyof Mode> => {
  return {
    links: [getEmptyLink()],
    message: '',
    images: [],
  };
};

export const getEmptyBusinessPaymentsMode = (): Omit<BusinessPaymentsMode, keyof Mode> => {
  return {
    message: '',
    currency: 'USD',
    stripeAccountId: store.getters.stripeAccounts.length ? store.getters.stripeAccounts[0].stripeUserId : '',
    tipOptionsEnabled: false,
    taxRate: 0,
    amount: 0,
    requireName: false,
    preSubmissionCustomFormModeId: '',
    postSubmissionCustomFormModeId: '',
  };
};

export const getEmptyCustomFormMode = (): Omit<CustomFormMode, keyof Mode> => {
  return {
    message: '',
    requireName: true,
    requireEmail: true,
    requirePhoneNumber: false,
    customFields: [],
    emailForReceivingNotifications: store.state.currUser.email || '',
  };
};

export const getEmptyFilesMode = (): Omit<FilesMode, keyof Mode> => {
  return {
    message: '',
    hideIcons: false,
    files: [],
  };
};

export const getEmptyGalleryMode = (): Omit<GalleryMode, keyof Mode> => {
  return {
    galleryItems: [getEmptyGalleryItem()],
    message: '',
    galleryItemNamesEnabled: false,
  };
};

export const getEmptyBlogMode = (): Omit<BlogMode, keyof Mode> => {
  return {
    message: '',
    postReferences: [getEmptyBlogPostReference()],
  };
};

export const getEmptyAccordionMode = (): Omit<AccordionMode, keyof Mode> => {
  return {
    message: '',
    sections: [getEmptyAccordionSection()],
  };
};

export const getEmptyGroupMode = (): Omit<GroupMode, keyof Mode> => {
  return {
    groupModeIds: [],
  };
};

export const getEmptySiteMode = (): Omit<SiteMode, keyof Mode> => {
  return {
    menuIconType: eMenuIconTypes.standard,
    emojiIcons: [],
    landingPageModeId: '',
    sitePageModeIds: [],
    images: [],
  };
};

export const getEmptyHtmlMode = (): Omit<HtmlMode, keyof Mode> => {
  return {
    html: '',
    js: '',
    css: '',
    minHeight: '',
    maxWidth: '',
  };
};

export const getEmptyShopMode = (): Omit<ShopMode, keyof Mode> => {
  return {
    message: '',
    checkoutSuccessMessage: '',
    requiresDeliveryAddress: false,
    stripeAccountId: store.getters.stripeAccounts.length ? store.getters.stripeAccounts[0].stripeUserId : '',
    tipOptionsEnabled: false,
    taxRate: 0,
    flatDeliveryFee: 0,
    hasDeliveryTaxRate: false,
    deliveryTaxRate: 0,
    currency: 'USD',
    preCheckoutOrderBreakdown: [],
    shopItemList: [getEmptyShopItem()],
    hasPromoCodes: false,
    allowSpecialRequests: false,
    categories: [],
    requireName: false,
    preSubmissionCustomFormModeId: '',
    postSubmissionCustomFormModeId: '',
    hasMaxOrderDistance: false,
    maxOrderDistance: 25,
    locationAddress: getEmptyDetailedAddress(),
    allowedCountriesForDelivery: [],
    freeShippingCountries: [],
    computeTaxRateBasedOnLocation: false,
    locationsToComputeTaxRateFor: {},
    deliveryType: eDeliveryTypes.local, // local or shipping
    taxType: eTaxTypes.userSpecified,
    images: [],
  };
};

export const getEmptyCategory = (): Category => {
  return {
    id: generateId(),
    name: '',
    shopItemIds: [],
    isHidden: false,
  };
};

export const getEmptyChatMode = (): Omit<ChatMode, keyof Mode> => {
  return {
    message: '',
    qrMessage: '',
    hasAutoTranslate: true,
    maximumParticipants: 50,
  };
};

export const getEmptyReturnToMode = (): Omit<ReturnToMode, keyof Mode> => {
  return {
    message: '',
    email: '',
    phone: '',
    phoneWork: '',
    locationAddress: getEmptyDetailedAddress(),
  };
};

export const getEmptyChessMode = (): Omit<ChessMode, keyof Mode> => {
  return {
    allowShowThreatsOption: true,
  };
};

export const getEmptyWordleMode = (): Omit<WordleMode, keyof Mode> => {
  return {
    pointGoal: 5,
  };
};

export const getEmptyTriviaMode = (): Omit<TriviaMode, keyof Mode> => {
  return {
    numberOfQuestions: 10,
    category: eTriviaCategories.anyCategory,
    difficulty: eTriviaDifficulty.medium,
  };
};

export const getEmptyCardMode = (): Omit<CardMode, keyof Mode> => {
  return {
    images: [],
    firstText: '',
    secondText: '',
  };
};

export const getEmptyProfileMode = (): Omit<ProfileMode, keyof Mode> => {
  return {
    images: [],
    title: '',
    email: '',
    phone: '',
    phoneWork: '',
    phoneWorkExtension: '',
    organization: '',
    organizationUrl: '',
    website: '',
    websiteLabel: '',
    ctaButtonLabel: '',
    ctaButtonUrl: '',
    description: '',
    showLabelsForSocials: false,
    locationAddress: getEmptyDetailedAddress(),
    locationLabel: '',
    showAddAsContactPrompt: false,
    showContactDetailsPrompt: false,
    allowPeopleToSendTheirContactDetails: false,
    contactDetailsFormUrl: '',
    contactDetailsCustomFormModeId: '',
    hidePhone: false,
    hidePhoneWork: false,
    hideEmail: false,
    hideWebsite: false,
    hideOrganization: true,
    hideLocationAddress: false,
    hideSaveForLaterButton: false,
  };
};

export const getEmptyPersonalPaymentsMode = (): Omit<PersonalPaymentsMode, keyof Mode> => {
  return {
    // Crypto.
    bitcoinAddress: '',
    bitcoinEnabled: false,
    ethereumAddress: '',
    ethereumEnabled: false,
    litecoinAddress: '',
    litecoinEnabled: false,
    // Other.
    paypalEnabled: false,
    googlePayEnabled: false,
    applePayEnabled: false,
    venmoEnabled: false,
    cashAppEnabled: false,
    interacEnabled: false,
    paypalMeUrl: '',
    googlePayMeUrl: '',
    applePayMeUrl: '',
    venmoUrl: '',
    cashAppUrl: '',
    interacEmail: '',
    interacPhoneNumber: '',
    interacPassword: '',
    // Required.
    amount: 0,
    currency: '',
  };
};

export const getEmptyWifiMode = (): Omit<WifiMode, keyof Mode> => {
  return {
    ssid: '',
    wifiEncryption: 'WPA',
    wifiPassword: '',
  };
};

export const getEmptyUrlRedirectMode = (): Omit<UrlRedirectMode, keyof Mode> => {
  return {
    redirectUrl: '',
    putInIframe: false,
  };
};

export const getEmptyArticleMode = (): Omit<ArticleMode, keyof Mode> => {
  return {
    images: [],
    text: '',
  };
};

export const getEmptyEventsMode = (): Omit<EventsMode, keyof Mode> => {
  return {
    images: [],
    message: '',
    events: [getEmptySitchEvent()],
  };
};

export const getEmptyBookingMode = (): Omit<BookingMode, keyof Mode> => {
  return {
    message: '',
    organizationName: '',
    availabilityTimeSlots: [],
    maxBookingsPerDay: null,
    bufferTimeBeforeInMinutes: 5,
    bufferTimeAfterInMinutes: 5,
    minimumSchedulingNoticeInMinutes: 60,
    timeSteps: 15,
    maximumSchedulingNoticeInDays: 30,
    maxSimultaneousBookingsForSameSlot: 1,
    durationPerBookingHours: 1,
    durationPerBookingMinutes: 0,
    closureDates: [],
    locationAddress: getEmptyDetailedAddress(),
    emailForReceivingNotifications: store.state.currUser.email || '',
    preSubmissionCustomFormModeId: '',
    postSubmissionCustomFormModeId: '',
  };
};

export const getEmptyTimeslot = (): TimeSlot => {
  return {
    id: generateId(),
    timeOfDayStart: '',
    timeOfDayEnd: '',
    daysOfWeek: [],
    isHidden: false,
  };
};

export const getEmptyDetailedAddress = (): DetailedAddress => {
  return {
    addressLine2: '',
    additionalAddressDetails: '',
    formattedAddress: '',
    latitude: null,
    longitude: null,
    googlePlaceId: '',
    utcOffset: -1 * new Date().getTimezoneOffset(),
    address: {
      administrativeAreaLevel1: '',
      administrativeAreaLevel2: '',
      country: '',
      locality: '',
      postalCode: '',
      route: '',
      streetNumber: '',
    } as GoogleAddress,
  };
};

export const getEmptySitchEvent = (): SitchEvent => {
  return {
    id: generateId(),
    name: '',
    eventDate: '',
    startTime: '',
    durationHours: 0,
    durationMinutes: 30,
    url: '',
    description: '',
    locationAddress: getEmptyDetailedAddress(),
    images: [],
    isHidden: false,
  };
};

export const getEmptyGalleryItem = (): GalleryItem => {
  return {
    id: generateId(),
    name: '',
    description: '',
    images: [],
    isHidden: false,
  };
};

export const getEmptyBlogPostReference = (): BlogPostReference => {
  return {
    id: generateId(),
    name: '',
    subtitle: '',
    author: '',
    images: [],
    goLiveDate: format(new Date(), 'yyyy-MM-dd'),
    goLiveTime: format(new Date(), 'HH:mm'),
    utcOffset: -1 * new Date().getTimezoneOffset(),
    isHidden: false,
  };
};

export const getEmptyAccordionSection = (): AccordionSection => {
  return {
    id: generateId(),
    name: '',
    text: '',
    isHidden: false,
  };
};

export const getEmptyShopItem = (): ShopItem => {
  return {
    id: generateId(),
    name: '',
    isHidden: false,
    price: 0,
    description: '',
    isSoldOut: false,
    hasStock: false,
    isAgeGated: false,
    minimumAge: 0,
    stock: 0,
    modifierGroups: [],
    images: [],
    minimumQuantity: 1,
    maximumQuantity: MAX_SHOP_ITEM_QUANTITY,
    hasUniqueTaxRate: false,
    uniqueTaxRate: 0,
  };
};

export const getEmptyLink = (): Link => {
  return {
    id: generateId(),
    name: '',
    url: '',
    description: '',
    images: [],
    isEmbedded: false,
    isHidden: false,
  };
};

export const getEmptyCustomField = (): CustomField => {
  return {
    id: generateId(),
    name: '',
    type: eCustomFieldTypes.shortText,
    isRequired: true,
    choices: [],
    description: '',
    isHidden: false,
  };
};

export const defaultThemeBackgroundColor = '#ededf7';

export const getEmptyAestheticTheme = (): AestheticTheme => {
  return {
    id: generateId(),
    name: '',
    backgroundColor: defaultThemeBackgroundColor,
    images: [],
    accentColor: '#0270d9',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  };
};

export const defaultThemes: Record<string, AestheticTheme> = {
  sitchLight: {
    id: 'sitchLight',
    name: 'Sitch Light',
    backgroundColor: '#ededf7',
    images: [],
    accentColor: '#0270d9',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,      
  },
  sitchDark: {
    id: 'sitchDark',
    name: 'Sitch Dark',
    backgroundColor: '#212121',
    images: [],
    accentColor: '#a1d1ff',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.dark,
  },
  flashy: {
    id: 'flashy',
    name: 'Flashy',
    backgroundColor: 'linear-gradient(to right, #d66547, #58b8c7)',
    images: [],
    accentColor: '#aaffd5',
    primaryFontName: 'Poppins',
    headerFontName: 'Poppins',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
  happy: {
    id: 'happy',
    name: 'Happy',
    backgroundColor: 'linear-gradient(to right, #ff4b1f, #a83279)',
    images: [],
    accentColor: '#9be6ff',
    primaryFontName: 'Quicksand',
    headerFontName: 'Quicksand',
    primaryFontVariant: 'regular',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
  sporty: {
    id: 'sporty',
    name: 'Sporty',
    backgroundColor: 'linear-gradient(to right, #424242, #212121)',
    images: [],
    accentColor: '#ffa500',
    primaryFontName: 'Roboto',
    headerFontName: 'Roboto',
    primaryFontVariant: 'italic',
    headerFontVariant: '700italic',
    primaryFontScale: 1,
    headerFontScale: 1.1,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.dark,
  },
  techy: {
    id: 'techy',
    name: 'Techy',
    backgroundColor: 'linear-gradient(to right, #141e30, #243b55)',
    images: [],
    accentColor: '#04e9ff',
    primaryFontName: 'Open Sans',
    headerFontName: 'Roboto Condensed',
    primaryFontVariant: 'regular',
    headerFontVariant: 'regular',
    primaryFontScale: 1,
    headerFontScale: 1,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.dark,
  },
  classy: {
    id: 'classy',
    name: 'Classy',
    backgroundColor: '#fff8e1',
    images: [],
    accentColor: '#fd8544',
    primaryFontName: 'Quicksand',
    headerFontName: 'Raleway',
    primaryFontVariant: '500',
    headerFontVariant: '600',
    primaryFontScale: 1,
    headerFontScale: 0.9,
    uppercaseHeaderFont: true,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
  healthy: {
    id: 'healthy',
    name: 'Healthy',
    backgroundColor: '#f1f8e9',
    images: [],
    accentColor: '#2caf15',
    primaryFontName: 'Quicksand',
    headerFontName: 'Economica',
    primaryFontVariant: '500',
    headerFontVariant: 'bold',
    primaryFontScale: 1,
    headerFontScale: 1.2,
    uppercaseHeaderFont: false,
    cardStyle: eCardStyle.round,
    secondaryImages: [],
    ambientText: '',
    metaTheme: eMetaTheme.light,
  },
};

export const getEmptyGlobalPaymentRecord = (): GlobalPaymentRecord => {
  return {
    referenceId: '',
    paymentIntentId: '',
    stripeReceiptUrl: '',
    isLive: false,
    orderBreakdown: [],
    platformUserId: '', // User ID on the Sitch platform.
    stripeUserId: '',
    formattedBaseAmount: '',
    formattedDiscountAmount: '',
    formattedTaxAmount: '',
    formattedTipAmount: '',
    formattedTotalAmount: '',
    formattedDeliveryFee: '',
    formattedRefundAmount: '',
    formattedDate: '',
    baseAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    tipAmount: 0,
    totalAmount: 0,
    currency: '',
    chargeStatus: eChargeStatuses.authorized,
    chargeId: '',
    creditCardBrand: '',
    creditCardLastFour: '',
    refundAmount: 0,
    deliveryFee: 0,
    deliveryAddress: null,
    personWhoPaidEmail: '',
    personWhoPaidName: '',
    businessEmail: '',
    businessUrl: '',
    displayName: '',
    statementDescriptor: '',
    customerFacingBusinessName: '',
    businessPhone: '',
    paymentIntent: '',
    modeId: '',
    promoCodes: [],
    locale: '',
    originalOrderBreakdownChecksum: '',
    retrievedOrderBreakdownChecksum: '',
    errors: [],
    orderStatus: eOrderStatuses.noStatus,
    dateCreated: Timestamp.fromMillis(Date.now()),
  };
};

export const getEmptyLinkedDevice = (): LinkedDevice => {
  return {
    genericId: '',
    name: '',
    dateActivated: Date.now(),
    assignedModeId: '',
    assignedLinkId: '',
    assignedModeOwnerId: '',
    interactionDeviceType: eInteractionDeviceTypes.blackSitchCard,
  };
};

export const getEmptyExternalUseMode = (): ExternalUseMode => {
  return {
    docId: '',
    linkId: '',
    type: eModeType.profile,
    name: '',
    displayName: '',
    ownerId: '',
  };
};

export const getEmptyTeam = (): Team => {
  return {
    docId: '',
    teamOwnerId: '',
    name: '',
    canAnyoneJoin: false,
    hasLeaderboard: false,
    modeIds: [],
    modes: [],        
    whiteListedEmails: [], // Emails that have access.
    whiteListedEmailDomains: [], // Email domains that have access.
    memberCount: 0,
    wrapperModeIdToAssign: '', // a TeamMode docId.
    assignedThemeId: '',
    dateCreated: Timestamp.fromMillis(Date.now()),
    dateUpdated: Timestamp.fromMillis(Date.now()),
  };
};

export const endpoints = {
  // Stripe endpoints.
  getCurrencyData: 'get-currency-data',
  getStripeAccount: 'get-stripe-account',
  getStripeSubscription: 'get-stripe-subscription',
  cancelStripeSubscription: 'cancel-stripe-subscription',
  reactivateStripeSubscription: 'reactivate-stripe-subscription',
  stripeConnectWebhook: 'stripe-connect-webhook',
  stripeWebhook: 'stripe-webhook',
  connectStripeAccount: 'connect-stripe-account',
  createStripeIntent: 'create-stripe-intent',
  createRefund: 'create-refund',
  saveOrderBreakdown: 'create-order-breakdown',
  getAllSubscriptions: 'get-all-subscriptions',
  cancelSubscription: 'cancel-subscription',
  getInvoices: 'get-invoices',
  saveCustomFieldsForPayment: 'save-custom-fields-for-payment',
  getPromoCodePayments: 'get-promo-code-payments',
  // Sitch Client Form submissions.
  customFormSubmit: 'custom-form-submit',
  // Permalinks.
  createCustomPermalink: 'create-custom-permalink',
  createShortPermalink: 'create-short-permalink',
  // Sitch API.
  sitchApi: 'sitch-api',
  // File Generation
  vcard: 'vcard',
  ical: 'ical',
  // Chat
  joinChat: 'join-chat',
  sendChatMessage: 'send-chat-message',
  deleteChatMessage: 'delete-chat-message',
  updateChatMessage: 'update-chat-message',
  updateChatUser: 'update-chat-user',
  addWebPushTokenToChat: 'add-web-push-notification-to-chat',
  removeWebPushTokenFromChat: 'remove-web-push-notification-from-chat',
  leaveChat: 'leave-chat',
  deleteChat: 'delete-chat',
  resetChat: 'reset-chat',
  // Translations
  doTranslations: 'do-translations',
  // External Groups
  setActiveModeIdWithExternalGroupCode: 'set-active-mode-id-with-external-group-code',
  setAutoSitchWithExternalGroupCode: 'set-auto-sitch-with-external-group-code',
  getExternalUseModeGroup: 'get-external-use-mode-group',
  findTeamModeFromDocId: 'find-team-mode-from-doc-id',
  // Teams
  getLeaderboardData: 'get-leaderboard-data',
  // Mail
  sendVerificationEmail: 'send-verification-email',
  unsubscribeFromMarketingEmails: 'unsubscribe-from-marketing-emails',
  // Booking
  bookAppointment: 'book-appointment',
  cancelBooking: 'cancel-booking',
  getBookingSlotSpacesRemaining: 'get-booking-slot-spaces-remaining',
  setReminder: 'set-reminder',
  // Users
  deleteUser: 'deleteUser',
  createUser: 'create-user',
  createUserMultipleUsersWithProfileMode: 'create-multiple-users-with-profile-mode',
  adminCreateUser: 'admin-create-user',
  updateAuthUser: 'update-auth-user',
  // Misc.
  affiliateSubmit: 'affiliate-submit',
  activateSitchLink: 'activate-sitch-link',
  notificationTest: 'notification-test',
  getCountryFromIp: 'get-country-from-ip',
  sendProductSiteTelemetry: 'send-product-site-telemetry',
  // Admin only
  verifyEmail: 'verify-email',
  manuallyVerifyEmail: 'manually-verify-email',
  manuallyResetPassword: 'manually-reset-password',
  bulkEmail: 'bulk-email',
  // CRM integrations
  // sendCrmFormData: 'send-crm-form-data',
};
