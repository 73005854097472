import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const CUSTOM_ICONS = {
  //complete:
  //cancel:
  //close:
  //delete:
  //clear:
  //success:
  //info:
  //warning:
  //error:
  //prev:
  //next:
  //checkboxOn:
  //checkboxOff:
  //checkboxIndeterminate:
  //delimiter:
  //sort:
  //expand:
  //menu:
  //subgroup:
  //dropdown:
  //radioOn:
  //radioOff:
  //edit:
  //ratingEmpty:
  //ratingFull:
  //ratingHalf:
  //loading:
  //first:
  //last:
  //unfold:
  //file:
  //delimiter:
  sort: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'sort-up'],
    },
  },
  dropdown: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'caret-down'],
    },
  },
  expand: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'chevron-down'],
    },
  },
  prev: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'chevron-left'],
    },
  },
  next: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'chevron-right'],
    },
  },
  clock: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'clock'],
    },
  },
  calendar: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'calendar-alt'],
    },
  },
};

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: CUSTOM_ICONS,
  },
  theme: {
    themes: {
      light: {
        primary: '#e0720a',
      },
      dark: {
        primary: '#e0720a',
      },
    },
  },
});
