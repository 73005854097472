
import { isMobileCheck } from '@/util-functions/misc-utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    hint: {
      type: String,
      default: '',
    },
  },
  data(): {
    isMobile: boolean;
  } {
    return {
      isMobile: isMobileCheck(),
    };
  },
});
