import { DEFAULT_MODE_THEME, LARGE_FILE_IMAGE_MAX_DIMENSION, SMALL_FILE_IMAGE_MAX_DIMENSION, URL_REGEX } from '@/constants';
import { eModeType } from '@/enums';
import { store } from '@/store';
import { getDownloadURL, ref, uploadBytesResumable, UploadMetadata } from 'firebase/storage';
import { saveMode } from './firestore-mode-utils';
import { compressImage } from './image-utils';
import { fbStorage } from '@/util-functions/initialization-utils';
import { t } from './language-utils';
import { hideLoading, showLoading } from './loading-utils';
import { generateId, showError } from './misc-firestore-utils';
import { isValidUpload, stopTrackingUploadProgress, trackUploadProgress } from './storage-utils';

export const processServiceWorkerEvent = (event: any) => {
  const data = event.data;
  const modeId = generateId();
  if (data) {
    const titleIsDomain = URL_REGEX.test(data.title);
    const textIsDomain = URL_REGEX.test(data.text);
    const urlIsDomain = URL_REGEX.test(data.url);
    const isDomain = titleIsDomain || textIsDomain || urlIsDomain;
    if (isDomain) {
      let url = '';
      if (textIsDomain) {
        url = data.text;
      } else if (urlIsDomain) {
        url = data.url;
      } else if (titleIsDomain) {
        url = data.title;
      }
      const urlRedirectMode: UrlRedirectMode = {
        docId: modeId,
        type: eModeType.urlRedirect,
        name: data.title?.substring(0, 50) || data.text?.substring(0, 50),
        displayName: '',
        linkId: '',
        themeId: DEFAULT_MODE_THEME,
        index: 0,
        redirectUrl: url,
        putInIframe: false,
      };
      saveMode(urlRedirectMode);
    } else if (data.file) {
      // TODO: Handle shared file by uploading and creating url redirect sitch.
      const file = data.file;
      const fileName = file.name;
      if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
        showError(t.cannotUpload);
        return;
      }
      if (fileName && fileName.lastIndexOf('.') <= 0) {
        showError(t.mustUploadAValidFile);
        return;
      }
      if (!file || !isValidUpload(file.size)) {
        return;
      }

      showLoading();

      const onFileReady = (compressedFile: CompressedFile) => {
        const storagePath = `userFiles/${store.state.userId}/${modeId}`;
        const file = compressedFile.file;
        const fileName = compressedFile.fileName;
        const fileSize = file.size;
        const fullPath = `${storagePath}/${compressedFile.id}`;
        const metadata: UploadMetadata = {
          customMetadata: {
            fullPath,
            userId: store.state.userId,
          },
        };

        const uploadTask = uploadBytesResumable(ref(fbStorage, fullPath), file, metadata);

        const uploadTaskId = generateId();
        trackUploadProgress(uploadTask, uploadTaskId);

        uploadTask
          .then((uploadTaskSnapshot) => {
            getDownloadURL(uploadTaskSnapshot.ref)
              .then((downloadUrl: string) => {
                stopTrackingUploadProgress(uploadTaskId);
                // Push the uploaded image into data.
                const storageFileArray: StorageFile[] = [];

                storageFileArray.push({
                  id: compressedFile.id,
                  mimeType: compressedFile.file.type,
                  fileName: compressedFile.fileName,
                  description: compressedFile.description,
                  storagePath,
                  downloadUrl,
                  base64ImagePreview: compressedFile.base64ImagePreview || '',
                  isHidden: false,
                });

                const modeData: FilesMode = {
                  docId: modeId,
                  type: eModeType.files,
                  linkId: '',
                  message: '',
                  displayName: '',
                  themeId: DEFAULT_MODE_THEME,
                  index: 0,
                  hideIcons: false,
                  name: fileName,
                  files: storageFileArray,
                };

                store.commit('storageQuota', store.state.storageQuota + fileSize);
                saveMode(modeData);
              })
              .catch((error: any) => {
                showError(`Unable to get the download URL for ${fullPath}.`, error, true);
              })
              .finally(() => {
                hideLoading();
              });
          })
          .catch((error: any) => {
            showError(`Unable to complete the upload task for ${fullPath}.`, error, true);
            hideLoading();
          });
      };
      if (file.type.includes('image/')) {
        compressImage(file, SMALL_FILE_IMAGE_MAX_DIMENSION).then((imagePreviewFileObj) => {
          compressImage(file, LARGE_FILE_IMAGE_MAX_DIMENSION).then((compressedFileObj: any) => {
            if (!isValidUpload(compressedFileObj.compressedFile.size)) {
              return;
            }
            const compressedFile: CompressedFile = {
              id: generateId(),
              file: compressedFileObj.compressedFile,
              fileName: compressedFileObj.compressedFile.name,
              description: '',
              mimeType: compressedFileObj.compressedFile.type,
              base64ImagePreview: imagePreviewFileObj.base64data,
            };
            onFileReady(compressedFile);
          });
        });
      } else {
        if (!isValidUpload(file.size)) {
          return;
        }
        const compressedFile: CompressedFile = {
          id: generateId(),
          file,
          fileName: file.name,
          description: '',
          mimeType: file.type,
        };
        onFileReady(compressedFile);
      }
    } else {
      console.error('Unknown share type.');
    }
  }
};
