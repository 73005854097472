
import SitchOrderItem from '@/components/custom-ui-components/SitchOrderItem.vue';
import { getOrderItemTaxValues } from '@/util-functions/misc-utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: {
    SitchOrderItem,
  },
  props: {
    taxRate: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: '',
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    orderBreakdown: {
      type: Array as PropType<OrderItem[]>,
      required: true,
    },
    currPayment: {
      type: Object as PropType<GlobalPaymentRecord | null>,
      default: null,
    },
  },
  data(): {
    currentOrderItem: OrderItem | null;
    showAdditionalOptionsModal: boolean;
  } {
    return {
      currentOrderItem: null,
      showAdditionalOptionsModal: false,
    };
  },
  methods: {
    onDeleteOrderItemClick(orderItem: OrderItem) {
      this.currentOrderItem = orderItem;
      this.showAdditionalOptionsModal = true;
    },
    closeAdditionalOptionsModal() {
      this.currentOrderItem = null;
      this.showAdditionalOptionsModal = false;
    },
    removeOrderItem() {
      const orderBreakdown = this.orderBreakdown.filter((orderItem: OrderItem) => orderItem.id !== (this.currentOrderItem as OrderItem).id);
      this.closeOrderItemDeletionOptionsModal(orderBreakdown);
    },
    removeSingleOrderItem() {
      const orderBreakdown = this.orderBreakdown.map((oi: OrderItem) => {
        if (oi.id === (this.currentOrderItem as OrderItem).id) {
          const quantity = oi.quantity - 1;
          const totalAmount = oi.totalAmount - oi.amountWithModifiers;
          const { totalTaxAmount, totalAmountWithTax } = getOrderItemTaxValues(oi, this.taxRate, totalAmount);

          const ret: OrderItem = {
            ...oi,
            totalAmount,
            totalTaxAmount,
            totalAmountWithTax,
            quantity,
          };
          return ret;
        }
        return oi;
      });

      this.closeOrderItemDeletionOptionsModal(orderBreakdown);
    },
    closeOrderItemDeletionOptionsModal(orderBreakdown: OrderItem[]) {
      this.$emit('change', orderBreakdown);
      this.showAdditionalOptionsModal = false;
      this.currentOrderItem = null;
    },
    getOrderItemMinimumQuantity(orderItem: OrderItem) {
      return orderItem.progenitorMinimumQuantity || 1;
    },
  },
});
