
import { eInteractionDeviceTypes } from '@/enums';
import { currFirestore } from '@/util-functions/initialization-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { formatCurrency } from '@/util-functions/misc-utils';
import { copyText } from '@/util-functions/user-utils';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      required: true,
    },
    currency: {
      type: String,
      default: '',
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    onDeleteClick: {
      type: Function,
      default: (): void => undefined,
    },
    currPayment: {
      type: Object as PropType<GlobalPaymentRecord | null>,
      default: null,
    },
  },
  computed: {
    showCopyRowButton(): boolean {
      return this.orderItem.name.includes('Sitch Card');
    },
    showLogoSideForegroundColor(): boolean {
      const nameSelectedModifier = this.orderItem.selectedModifiers['Print name on card'];
      const qrSelectedModifier = this.orderItem.selectedModifiers['Place QR code on front too?'];
      if (nameSelectedModifier) {
        const bothSidesPrintModifier = nameSelectedModifier.find((modifier) => modifier.name.toLowerCase() === 'yes (both sides)');
        if (bothSidesPrintModifier && bothSidesPrintModifier.quantity) {
          return true;
        }
      }
      if (qrSelectedModifier) {
        const qrModifier = qrSelectedModifier.find((modifier) => modifier.name.toLowerCase() === 'yes');

        if (qrModifier && qrModifier.quantity) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    hexToRgb(hex = ''): string {
      if (!hex) {
        return '';
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      if (rgb) {
        return `Hex: ${hex} RGB: (${rgb.r}, ${rgb.g}, ${rgb.b})`;
      }
      return hex;
    },
    formatModifierPrice(price: number, showSign = false): string {
      return formatCurrency(price, this.currency, showSign);
    },
    copyText(text: string) {
      const regex = / for item \d/g;
      text = text.replaceAll(regex, '').replaceAll(', ', '\n');
      copyText(text);
    },
    copyRowForEncodingCSV() {
      if (this.showCopyRowButton) {
        const regex = / for item \d/g;
        const printNameOnCardModifier = this.orderItem.selectedModifiers['Print name on card?']?.[0];
        const qrCodeOnFrontModifier = this.orderItem.selectedModifiers['Place QR code on front too?']?.[0] || null;
        const printNameOnBothSides = printNameOnCardModifier?.name.toLowerCase().includes('both sides') || false;
        const printQrCodeOnFront = qrCodeOnFrontModifier?.name.toLowerCase().includes('yes') || false;
        const names = printNameOnCardModifier?.customerInput?.replaceAll(regex, '').split('; ') || [];
        let style = this.orderItem.selectedModifiers['Color scheme'] ? `${this.orderItem.name} ${this.orderItem.selectedModifiers['Color scheme'][0].name}` : '';

        let deviceType: eInteractionDeviceTypes | null = null;
        switch (style.toLowerCase()) {
          case 'sitch card white print on black':
            deviceType = eInteractionDeviceTypes.blackSitchCard;
            break;
          case 'sitch card silver print on black':
            deviceType = eInteractionDeviceTypes.blackSilverSitchCard;
            break;
          case 'sitch card gold print on black':
            deviceType = eInteractionDeviceTypes.blackGoldSitchCard;
            break;
          case 'sitch card black print on white':
            deviceType = eInteractionDeviceTypes.whiteSitchCard;
            break;
          case 'sitch card gold print on white':
            deviceType = eInteractionDeviceTypes.whiteGoldSitchCard;
            break;
          case 'chat sitch card white print on black':
            deviceType = eInteractionDeviceTypes.blackChatSitchCard;
            break;
          case 'chat sitch card silver print on black':
            deviceType = eInteractionDeviceTypes.blackSilverChatSitchCard;
            break;
          case 'chat sitch card gold print on black':
            deviceType = eInteractionDeviceTypes.blackGoldChatSitchCard;
            break;
          case 'chat sitch card black print on white':
            deviceType = eInteractionDeviceTypes.whiteChatSitchCard;
            break;
          case 'chat sitch card gold print on white':
            deviceType = eInteractionDeviceTypes.whiteGoldChatSitchCard;
            break;
          case 'custom logo sitch card color print on white':
            deviceType = eInteractionDeviceTypes.whiteCustomLogoSitchCard;
            break;
          case 'custom logo sitch card white print on black':
            deviceType = eInteractionDeviceTypes.blackWhiteCustomLogoSitchCard;
            break;
          case 'custom logo sitch card silver print on black':
            deviceType = eInteractionDeviceTypes.blackSilverCustomLogoSitchCard;
            break;
          case 'custom logo sitch card gold print on black':
            deviceType = eInteractionDeviceTypes.blackGoldCustomLogoSitchCard;
            break;
          case 'custom logo sitch card gold print on white':
            deviceType = eInteractionDeviceTypes.whiteGoldCustomLogoSitchCard;
            break;
          default:
            switch (this.orderItem.name.toLowerCase()) {
              case 'spg sitch card': {
                deviceType = eInteractionDeviceTypes.blackSilverCustomLogoSitchCard;
                const logoOnTheFront = this.orderItem.selectedModifiers['Logo on the front']?.[0]?.name;
                style = logoOnTheFront;
                break;
              }
              case 'umb sitch card':
                deviceType = eInteractionDeviceTypes.umbCard;
                break;
            }
            break;
        }

        if (!deviceType) {
          return;
        }

        let text = '';
        const promises = [];
        const customerName = this.currPayment?.personWhoPaidName || this.currPayment?.personWhoPaidEmail || '';

        const newDeviceEntry: GenericIdData = {
          userId: 'TBD',
          interactionDeviceType: deviceType,
          name: '',
          autoJoinTeamId: '',
          dateCreated: Timestamp.fromMillis(Date.now()),
          dateUpdated: Timestamp.fromMillis(Date.now()),
        };

        for (let i = 0; i < this.orderItem.quantity; i++) {
          promises.push(
            addDoc(collection(currFirestore, 'genericIdToUserIdMappings'), newDeviceEntry)
              .then((docRef) => {
                const nfcString = `https://sitch.app/?g=${docRef.id}&m=1`;
                const qrString = `https://sitch.app/?g=${docRef.id}&m=2`;
                let notesString = '';
                if (printNameOnBothSides) {
                  notesString += 'Print name on both sides, ';
                }
                if (printQrCodeOnFront) {
                  notesString += 'QR code on front, ';
                }
                if ((printNameOnBothSides || printQrCodeOnFront) && this.orderItem.logoSideForegroundColor) {
                  notesString += `Foreground color: ${this.orderItem.logoSideForegroundColor}, ${this.hexToRgb(this.orderItem.logoSideForegroundColor)}, `;
                }
                if (this.orderItem.logoUrl) {
                  notesString += `Logo URL: ${this.orderItem.logoUrl}`;
                }
                const name = names[i] ? (names[i].includes('---') ? names[i].replace('---', '\t').replace('———', '\t').replace('—', '\t').replace('—-', '\t') : `${names[i]}\t`) : '\t';
                text += `${nfcString}\t${qrString}\t${name}\t${style}\t${notesString}\t${customerName}\n`;
              })
              .catch((err) => {
                showError(err);
              })
          );
        }
        Promise.all(promises).then(() => {
          copyText(text);
        });
      }
    },
  },
});
