
import { Capacitor } from '@capacitor/core';
import { sendEmailVerification, User } from 'firebase/auth';
import Vue from 'vue';
import { eModeType, eThemes } from './enums';
import { signOutAccount, emailIsVerified } from './util-functions/auth-utils';
import { t } from './util-functions/language-utils';
import { showError } from './util-functions/misc-firestore-utils';
import { isMobileCheck, isFeatureEnabled, localStoreSet, localStoreGet, hardReload, getParameterByName } from './util-functions/misc-utils';
import { showNotice } from './util-functions/notice-utils';
import { saveSettings, isPremiumActivated } from './util-functions/user-utils';

export default Vue.extend({
  data(): {
    eThemes: typeof eThemes;
    showUpdateOption: boolean;
    showUpdateDialog: boolean;
    isMounted: boolean;
    VUE_APP_VERSION: string;
    versionChangeNotes: string;
    snackBarMsg: string;
    showSnackbar: boolean;
    isMobile: boolean;
    drawer: boolean;
    showConfirmationDialog: boolean;
    confirmationDialogMsg: string;
    currentConfirmationDialogAffirmativeFunc: () => undefined;
    currentConfirmationDialogRejectionFunc: () => undefined;
    confirmationDialogHasACancelOption: boolean;
    verificationSent: boolean;
    isAlreadyInstalled: boolean;
    isNativePlatform: boolean;
  } {
    return {
      eThemes,
      showUpdateOption: false,
      showUpdateDialog: false,
      isMounted: false,
      VUE_APP_VERSION: process.env.VUE_APP_VERSION || '',
      versionChangeNotes: '',
      snackBarMsg: '',
      showSnackbar: false,
      isMobile: isMobileCheck(),
      drawer: false,
      showConfirmationDialog: false,
      confirmationDialogMsg: '',
      currentConfirmationDialogAffirmativeFunc: () => undefined,
      currentConfirmationDialogRejectionFunc: () => undefined,
      confirmationDialogHasACancelOption: true,
      verificationSent: false,
      isAlreadyInstalled: window.matchMedia('(display-mode: standalone)').matches,
      isNativePlatform: Capacitor.isNativePlatform(),
    };
  },
  mounted() {
    this.setTheme();
    this.isMounted = true;
    this.onFirebaseUserLoad();
  },
  watch: {
    currTheme() {
      this.setTheme();
    },
    isLoggedIn() {
      this.onFirebaseUserLoad();
    },
  },
  computed: {
    isAffiliate(): boolean {
      return this.$store.state.currUser.isAffiliate;
    },
    isDemoAccount(): boolean {
      return ['demo@sitch.cards', 'test@sitch.app'].includes(this.$store.state.currUser.email);
    },
    uploadPercentages(): { id: string; percentage: number }[] {
      return Object.entries(this.$store.state.currentFileUploads as FileUploadsObject).map(([id, percentage]) => {
        return {
          id,
          percentage,
        };
      });
    },
    emailIsVerified(): boolean {
      return emailIsVerified();
    },
    currTheme: {
      get(): eThemes {
        return this.$store.state.appTheme;
      },
      set(appTheme: eThemes) {
        saveSettings({ appTheme });
      },
    },
    isLoading(): boolean {
      return this.$store.state.initialLoadIsHappening || this.$store.state.isLoading;
    },
    initialLoadIsHappening(): boolean {
      return this.$store.state.initialLoadIsHappening;
    },
    customFormModesExist(): boolean {
      return (Object.values(this.$store.state.modes) as AnyMode[]).some((mode: AnyMode) => {
        return mode.type === eModeType.customForm;
      });
    },
    bookingModesExist(): boolean {
      return (Object.values(this.$store.state.modes) as AnyMode[]).some((mode: AnyMode) => {
        return mode.type === eModeType.booking;
      });
    },
    sitchCamEnabled(): boolean {
      return isFeatureEnabled(this.$store.state.featureFlags.sitchCam);
    },
    teamsEnabled(): boolean {
      return isFeatureEnabled(this.$store.state.featureFlags.teams);
    },
    modeGroupsEnabled(): boolean {
      return isFeatureEnabled(this.$store.state.featureFlags.modeGroups);
    },
    isLoggedIn(): boolean {
      return this.$store.state.isLoggedIn;
    },
    userName(): string {
      return this.$store.state.currUser?.displayName || this.$store.state.currUser?.email || '';
    },
    userEmail(): string {
      return this.$store.state.firebaseUser?.email || '';
    },
    isPremiumActivated(): boolean {
      return isPremiumActivated();
    },
    currAlertObject(): AlertObject | null {
      return this.$store.state.currAlertObject;
    },
  },
  methods: {
    onFirebaseUserLoad() {
      const firebaseUser: User | null = this.$store.state.firebaseUser;
      if (!this.emailIsVerified && firebaseUser && !this.verificationSent) {
        const genericId = getParameterByName('g');
        if (genericId) {
          localStoreSet('genericCodeForSignUp', genericId);
        }
        const actionCodeSettings = {
          url: window.location.origin,
          handleCodeInApp: true,
        };
        this.verificationSent = true;
        sendEmailVerification(firebaseUser, actionCodeSettings)
          .then(() => {
            showNotice(t.emailSent);
          })
          .catch((error) => {
            if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
              showError(t.tooManyAttempts);
            } else {
              showError(t.somethingWentWrong);
            }
          });
      }
    },
    checkEmailIsVerfied() {
      location.reload();
    },
    resendVerificationEmail() {
      sendEmailVerification(this.$store.state.firebaseUser)
        .then(() => {
          showNotice(t.emailSent);
        })
        .catch((error) => {
          if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
            showError(t.tooManyAttempts);
          } else {
            showError(t.somethingWentWrong);
          }
        });
    },
    setTheme() {
      if (this.$store.state.appTheme === eThemes.dark) {
        (this as any).$vuetify.theme.dark = true;
      } else {
        (this as any).$vuetify.theme.dark = false;
      }
    },
    updateApp() {
      localStoreSet('currentSitchVersion', String(localStoreGet('newestSitchVersion')));
      this.VUE_APP_VERSION = String(localStoreGet('newestSitchVersion'));
      hardReload();
    },
    showConfirmationDialogFunc(msg: string, onConfirmFunc: any = () => undefined, onRejectFunc: any = () => undefined, confirmationDialogHasACancelOption = true) {
      this.showConfirmationDialog = true;
      this.confirmationDialogMsg = msg;
      this.confirmationDialogHasACancelOption = confirmationDialogHasACancelOption;
      this.currentConfirmationDialogAffirmativeFunc = onConfirmFunc;
      this.currentConfirmationDialogRejectionFunc = onRejectFunc;
    },
    hideConfirmationDialog() {
      this.showConfirmationDialog = false;
      this.currentConfirmationDialogAffirmativeFunc = () => undefined;
      this.currentConfirmationDialogRejectionFunc = () => undefined;
    },
    onConfirmationYesClick() {
      if (this.currentConfirmationDialogAffirmativeFunc) {
        this.currentConfirmationDialogAffirmativeFunc();
      } else {
        showError(`No confirmation function provided for the dialog.`, null, true);
      }
      this.hideConfirmationDialog();
    },
    onConfirmationNoClick() {
      if (this.currentConfirmationDialogRejectionFunc) {
        this.currentConfirmationDialogRejectionFunc();
      } else {
        showError(`No rejection function provided for the dialog.`, null, true);
      }
      this.hideConfirmationDialog();
    },
    isOnPage(path: string) {
      const currPath = this.$router.mode === 'hash' ? window.location.hash : window.location.pathname;
      return currPath === path;
    },
    showSnackbarFunc(msg: string) {
      if (!msg) {
        return;
      }
      if (msg.length > 160) {
        this.showSnackbar = false;
        showNotice(this.snackBarMsg);
      } else {
        this.showSnackbar = true;
        this.snackBarMsg = `<div>${msg}</div>`;
      }
    },
    signOut() {
      signOutAccount();
    },
    onAlertClick() {
      this.$store.commit('currAlertObject', null);
    },
  },
});
