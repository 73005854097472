import { rootComponent } from './initialization-utils';

export const showNotice = (str: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(str);
};

export const showSuccess = (str: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(str);
};

export const showWarning = (str: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(str);
};

export const showConfirmation = (str: string, onConfirmFunc: any = () => undefined, onRejectFunc: any = () => undefined) => {
  if (!rootComponent.isMounted) {
    return;
  }
  rootComponent.showConfirmationDialogFunc(str, onConfirmFunc, onRejectFunc);
};

export const showPrompt = (str: string, onConfirmFunc: any = () => undefined, onRejectFunc: any = () => undefined) => {
  if (!rootComponent.isMounted) {
    return;
  }
  rootComponent.showConfirmationDialogFunc(str, onConfirmFunc, onRejectFunc, false);
};

export const showSnackBar = (msg: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  rootComponent.showSnackbarFunc(msg);
};
