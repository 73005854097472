import { PREMIUM_STORAGE_LIMIT_IN_BYTES, PREMIUM_UPLOAD_LIMIT_IN_BYTES, STANDARD_STORAGE_LIMIT_IN_BYTES, STANDARD_UPLOAD_LIMIT_IN_BYTES } from "@/constants";
import { store } from "@/store";
import { ref, getMetadata, deleteObject, UploadTask } from "firebase/storage";
import { fbStorage } from "./initialization-utils";
import { t } from "./language-utils";
import { showError } from "./misc-firestore-utils";
import { isPremiumActivated } from "./user-utils";

export const deleteFileFromStorage = (storagePath: string | undefined, fileName: string | undefined): Promise<void> => {
  if (!(storagePath && fileName)) {
    // Need both of these values to delete a file successfully.
    return Promise.resolve();
  }

  const fullPath = `${storagePath}/${fileName}`;
  const fileRef = ref(fbStorage, fullPath);

  return new Promise((resolve, reject) => {
    getMetadata(fileRef)
      .then((metadata) => {
        deleteObject(fileRef)
          .then(() => {
            if (metadata.size) {
              store.commit('storageQuota', store.state.storageQuota - metadata.size);
            }
            resolve();
          })
          .catch((error: any) => {
            if (error.code === 'storage/object-not-found') {
              resolve();
            } else {
              showError(`Unable to delete file: ${storagePath}. ${error.message}`, true);
              reject(error);
            }
          });
      })
      .catch((error: any) => {
        if (error.code === 'storage/object-not-found') {
          resolve();
        } else {
          showError(`Unable to get metadata for deleted file ${storagePath}. ${error.message}`, true);
          reject(error);
        }
      });
  });
};

export const trackUploadProgress = (uploadTask: UploadTask, uploadTaskId: string) => {
  uploadTask.on('state_changed', (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    store.commit('currentFileUploads', { ...store.state.currentFileUploads, [uploadTaskId]: progress });
  });
};

export const stopTrackingUploadProgress = (uploadTaskId: string) => {
  const newFileUploads = { ...store.state.currentFileUploads };
  delete newFileUploads[uploadTaskId];
  store.commit('currentFileUploads', newFileUploads);
};

export const isValidUpload = (bytes: number): boolean => {
  const newQuota = store.state.storageQuota + bytes;
  let isValidStorageQuota = false;
  let isValidUploadSize = false;
  if (isPremiumActivated()) {
    if (PREMIUM_STORAGE_LIMIT_IN_BYTES > newQuota) {
      isValidStorageQuota = true;
    } else {
      showError(t?.storageLimitPremium);
    }
    if (PREMIUM_UPLOAD_LIMIT_IN_BYTES > bytes) {
      isValidUploadSize = true;
    } else {
      showError(t?.uploadLimitPremium);
    }
  } else {
    if (STANDARD_STORAGE_LIMIT_IN_BYTES > newQuota) {
      isValidStorageQuota = true;
    } else {
      showError(t?.storageLimitStandard);
    }
    if (STANDARD_UPLOAD_LIMIT_IN_BYTES > bytes) {
      isValidUploadSize = true;
    } else {
      showError(t?.uploadLimitStandard);
    }
  }
  return isValidStorageQuota && isValidUploadSize;
};