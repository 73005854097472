
import { showError } from '@/util-functions/misc-firestore-utils';
import Vue from 'vue';

const zeroDecimalCurrencies = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'];

export default Vue.extend({
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      required: true,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    displayPrice: number;
  } {
    return {
      displayPrice: 0,
    };
  },
  computed: {
    decimalCurrencyMultuplier(): 1 | 100 {
      if (zeroDecimalCurrencies.includes(this.currency)) {
        return 1;
      }
      return 100;
    },
  },
  mounted() {
    this.updateDisplayPrice(this.value);
  },
  watch: {
    value(newPrice: number) {
      this.updateDisplayPrice(newPrice);
    },
    currency() {
      this.onAmountChange();
    },
    displayPrice() {
      this.onAmountChange();
    },
  },
  methods: {
    onAmountChange() {
      const integerPrice = Math.round(this.displayPrice * this.decimalCurrencyMultuplier);
      if (integerPrice % 1 === 0) {
        this.$emit('input', integerPrice);
      } else {
        showError(`Amounts must be saved as integers.`);
      }
    },
    updateDisplayPrice(newPrice: number) {
      this.displayPrice = newPrice / this.decimalCurrencyMultuplier;
    },
    onChange(val: number) {
      this.$emit('change', val);
    },
  },
});
