import { updateUserDoc } from './user-utils';
import { store } from '@/store';
import { GetTokenOptions, getMessaging, getToken, isSupported } from 'firebase/messaging';
import { isProductionEnv } from './initialization-utils';
import { showError } from './misc-firestore-utils';
import { showNotice } from './notice-utils';
// import { t } from './language-utils';
import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';

const testWebPushKeyPair = 'BM4zVMJivjp_pC39qtcOFlQZFsE35AiP4dZm_TecDNR3NjLB3p6J07VsPE3882WSn_cyUoM8R1qPCzcMH5hFL3s';
const prodWebPushKeyPair = 'BBqiyjLhnO-ka7XuQboCLcMwuPRIFSPlwJPts_8_RJxS3dIDAdjr9JuNMBmW2NiDHTPiCHhlz5tRwDev6A4iO2o';

export const addNotificationListeners = async () => {
  if (Capacitor.isNativePlatform()) {
    await FirebaseMessaging.removeAllListeners();

    await FirebaseMessaging.addListener('tokenReceived', (tokenEvent) => {
      pushToken(tokenEvent.token);
    });

    await FirebaseMessaging.addListener('notificationReceived', (notificationEvent) => {
      const notification = notificationEvent.notification;
      const text = `${notification.title}: ${notification.body}`;
      showNotice(text);
    });
  }
};

export const setUpNotifications = () => {
  if (Capacitor.isNativePlatform()) {
    FirebaseMessaging.checkPermissions().then((permStatus) => {
      if (permStatus.receive === 'prompt') {
        FirebaseMessaging.requestPermissions().then((requestPermStatus) => {
          if (requestPermStatus.receive === 'granted') {
            nativeGetPushNotificationToken();
          }
        });
      }
      if (permStatus.receive === 'granted') {
        nativeGetPushNotificationToken();
      }
    });
  } else {
    if (!('Notification' in window)) {
      return; // Push notifications not supported in this case.
    }

    const user = store.state.currUser;

    if (!user.settings.getWebPushNotificationsForBookings && !user.settings.getWebPushNotificationsForBookings && !user.settings.getWebPushNotificationsForCustomForms) {
      return; // User has not opted to receive push notifications.
    }

    if (!isSupported()) {
      console.error('Firebase messaging not supported.');
      return;
    }

    const webGetPushNotificationToken = () => {
      const messaging = getMessaging();

      const getTokenLocal = () => {
        getToken(messaging, { vapidKey: isProductionEnv ? prodWebPushKeyPair : testWebPushKeyPair })
          .then((token) => {
            pushToken(token);
          })
          .catch((error) => {
            showError(`An error occurred while retrieving token: ${error}`);
          });
      };

      Notification.requestPermission()
        .then(() => {
          getTokenLocal();
        })
        .catch((error) => {
          console.error(`No permission given for push notifications: ${error}`);
        });
    };

    navigator.permissions.query({ name: 'notifications' }).then((result) => {
      switch (result.state) {
        case 'granted':
          webGetPushNotificationToken();
          break;
        case 'prompt':
          webGetPushNotificationToken();
          break;
      }
    });
  }
};

function pushToken(token: string) {
  if (token) {
    if (Capacitor.isNativePlatform() && store.state.currUser && !store.state.currUser.nativePushTokens.includes(token)) {
      updateUserDoc({
        nativePushTokens: [...store.state.currUser.nativePushTokens, token],
      });
    } else if (store.state.currUser && !store.state.currUser.webPushTokens.includes(token)) {
      updateUserDoc({
        webPushTokens: [...store.state.currUser.webPushTokens, token],
      });
    }
  }
}

function nativeGetPushNotificationToken() {
  const options: GetTokenOptions = {
    vapidKey: isProductionEnv ? prodWebPushKeyPair : testWebPushKeyPair,
  };
  FirebaseMessaging.getToken(options).then((token) => {
    pushToken(token.token);
    addNotificationListeners();
  });
}
