// Important: These labels are stored in the database verbatim, don't change them!
export enum eInteractionDeviceTypes {
  // Name format is: ${Card Color}${Print Color (optional)}${Device Type}
  // Legacy
  blackSitchCardV2 = 'blackSitchCardV2',
  // Standard
  blackSitchCard = 'blackSitchCard',
  blackSilverSitchCard = 'blackSilverSitchCard',
  blackGoldSitchCard = 'blackGoldSitchCard',
  whiteSitchCard = 'whiteSitchCard',
  whiteSilverSitchCard = 'whiteSilverSitchCard',
  whiteGoldSitchCard = 'whiteGoldSitchCard',
  // Chat
  blackChatSitchCard = 'blackChatSitchCard',
  blackSilverChatSitchCard = 'blackSilverChatSitchCard',
  blackGoldChatSitchCard = 'blackGoldChatSitchCard',
  whiteChatSitchCard = 'whiteChatSitchCard',
  whiteSilverChatSitchCard = 'whiteSilverChatSitchCard',
  whiteGoldChatSitchCard = 'whiteGoldChatSitchCard',
  // Custom Logo Cards
  whiteCustomLogoSitchCard = 'whiteCustomLogoSitchCard',
  whiteSilverCustomLogoSitchCard = 'whiteSilverCustomLogoSitchCard',
  whiteGoldCustomLogoSitchCard = 'whiteGoldCustomLogoSitchCard',
  blackWhiteCustomLogoSitchCard = 'blackWhiteCustomLogoSitchCard',
  blackSilverCustomLogoSitchCard = 'blackSilverCustomLogoSitchCard',
  blackGoldCustomLogoSitchCard = 'blackGoldCustomLogoSitchCard',
  // Non Cards
  blackSitchBand = 'blackSitchBand',
  blackSitchDot = 'blackSitchDot',
  blackSitchBumper = 'blackSitchBumper',
  rectangular11mmx26mmStickerPackSize5 = 'rectangular11mmx26mmStickerPackSize5',
  rectangular11mmx26mmStickerSingle = 'rectangular11mmx26mmStickerSingle',
  square16mmx16mmStickerPackSize5 = 'square16mmx16mmStickerPackSize5',
  // Clothes
  blackUnisexShirt = 'blackUnisexShirt',
  blackWomensShirt = 'blackWomensShirt',
  // Partners
  tapeeo = 'tapeeo',
  gust = 'gust',
  umbCard = 'umbCard',
  zgoda = 'zgoda',
  // Digital
  smartLink = 'smartLink',
  // Generic
  genericNfcCard = 'genericNfcCard',  
}

export enum eModeType {
  profile = 'profile',
  urlRedirect = 'urlRedirect',
  article = 'article',
  files = 'files',
  personalPayment = 'personalPayment',
  businessPayment = 'businessPayment',
  wifi = 'wifi',
  site = 'site',
  linkList = 'linkList',
  gallery = 'gallery',
  group = 'group',
  shop = 'shop',
  html = 'html',
  events = 'events',
  booking = 'booking',
  customForm = 'customForm',
  blog = 'blog',
  accordion = 'accordion',
  returnTo = 'returnTo',
  chat = 'chat',
  chess = 'chess',
  wordle = 'wordle',
  trivia = 'trivia',
  card = 'card',
  broadcast = 'broadcast',
}

export enum eCardStyle {
  flat = 'flat',
  sharp = 'sharp',
  round = 'round',
}

export enum eFeatureFlagTypes {
  off,
  test,
  prod,
}

export enum ePaymentStatus {
  sitchPremiumdPaymentSucceeded = 'sitchPremiumPaymentSucceeded',
  sitchPremiumdPaymentCancelled = 'sitchPremiumPaymentCancelled',
}

export enum eChargeStatuses {
  completed = 'completed',
  cancelled = 'cancelled',
  authorized = 'authorized',
}

export enum eWeekdays {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum eCustomFieldTypes {
  email = 'email',
  phoneNumber = 'phoneNumber',
  url = 'url',
  shortText = 'shortText',
  longText = 'longText',
  number = 'number',
  date = 'date',
  time = 'time',
  dateTime = 'dateTime',
  checkbox = 'checkbox',
  outOf5 = 'outOf5',
  multipleChoice = 'multipleChoice',
  fileUpload = 'fileUpload',
  signature = 'signature',
  address = 'address',
  nonInputTextDescription = 'nonInputTextDescription',
}

export enum eOrderStatuses {
  noStatus = 'noStatus',
  onHold = 'onHold',
  accepted = 'accepted',
  rejected = 'rejected',
  completed = 'completed',
  // Delivery specific
  dispatched = 'dispatched',
  delivered = 'delivered',
  // Take out specific
  readyForPickUp = 'readyForPickUp',
  pickedUp = 'pickedUp',
}

export enum eThemes {
  dark = 'dark',
  light = 'light',
}

export enum eTranslationLocaleCode {
  en = 'en',
  zh = 'zh',
  es = 'es',
  hi = 'hi',
  ar = 'ar',
  bn = 'bn',
  pt = 'pt',
  ru = 'ru',
  ja = 'ja',
  pa = 'pa',
  it = 'it',
  de = 'de',
  fr = 'fr',
  notSet = 'notSet',
}

export enum eMenuIconTypes {
  none = 'none',
  standard = 'standard',
  emoji = 'emoji',
}

export enum eSignInErrors {
  databaseError = 'databaseError',
  alreadyActivated = 'alreadyActivated',
  idNotValid = 'idNotValid',
}

export enum eDeliveryTypes {
  local = 'local',
  shipping = 'shipping',
}

export enum eTaxTypes {
  userSpecified = 'userSpecified',
  stripe = 'stripe',
}

export enum eTriviaDifficulty {
  easy = 'easy',
  medium = 'medium',
  hard = 'hard',
}

export enum eTriviaCategories {
  anyCategory = '',
  generalKnowledge = '9',
  entertainmentBooks = '10',
  entertainmentFilm = '11',
  entertainmentMusic = '12',
  entertainmentMusicalsTheatres = '13',
  entertainmentTelevision = '14',
  entertainmentVideoGames = '15',
  entertainmentBoardGames = '16',
  entertainmentComics = '29',
  entertainmentJapaneseAnimeManga = '31',
  entertainmentCartoonAnimations = '32',
  scienceNature = '17',
  scienceComputers = '18',
  scienceMathematics = '19',
  scienceGadgets = '30',
  mythology = '20',
  sports = '21',
  geography = '22',
  history = '23',
  politics = '24',
  art = '25',
  celebrities = '26',
  animals = '27',
  vehicles = '28',
}

export enum eInteractionMethods {
  inAppQr = 0,
  cardNfc = 1,
  cardQr = 2,
  bandNfc = 3,
  dotNfc = 4,
  stickerNfc = 5,
  link = 6,
  bumper = 7,
}
export enum eMetaTheme {
  light = 'light',
  dark = 'dark',
}