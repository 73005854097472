export const productionFirebaseCredentials = {
  apiKey: process.env.VUE_APP_PROD_API_KEY || '',
  // authDomain: 'sitch-app.firebaseapp.com',
  authDomain: 'auth.mysitch.app',
  databaseURL: 'https://sitch-app.firebaseio.com',
  projectId: 'sitch-app',
  storageBucket: 'sitch-app.appspot.com',
  messagingSenderId: '462308206439',
  appId: '1:462308206439:web:284bad5282fe5258f371c2',
  measurementId: 'G-VQ0WY5R0MG',
};

export const stagingFirebaseCredentials = {
  apiKey: process.env.VUE_APP_TEST_API_KEY || '',
  authDomain: 'sitch-app-test-64014.firebaseapp.com',
  // authDomain: 'auth.test.mysitch.app',
  databaseURL: 'https://sitch-app-test-64014.firebaseio.com',
  projectId: 'sitch-app-test-64014',
  storageBucket: 'sitch-app-test-64014.appspot.com',
  messagingSenderId: '125426563523',
  appId: '1:125426563523:web:2be0f85b452e3aa74d6576',
  measurementId: 'G-E2VSYLLVYV',
};