import { store } from '@/store';
import { arrayUnion, collection, doc, getDocs, setDoc, Timestamp, writeBatch } from 'firebase/firestore';
import { currFirestore, rootComponent } from './initialization-utils';
import { t } from './language-utils';
import { showSnackBar, showSuccess } from './notice-utils';

export const showError = (str: string, error: any = null, report = false) => {
  const formattedStr = `${str} ${error?.errorMessage || error?.message || error || ''}`;
  console.error(formattedStr, error);
  if (report) {
    setDoc(
      doc(currFirestore, 'managerErrorLog', store.state.userId),
      {
        errors: arrayUnion({
          date: Date.now(),
          userEmail: store.state.currUser?.email || '',
          path: window.location.pathname,
          error: str || '',
          errorObject: error ? JSON.stringify(error) : null,
        }),
        dateUpdated: Timestamp.fromMillis(Date.now()),
      },
      { merge: true }
    );
  }
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(formattedStr);
};

export const generateId = () => {
  const newId = doc(collection(currFirestore, 'This is just to generate IDs')).id;
  if (store) {
    store.commit('idsGeneratedSinceLastModeFormPopulation', [...store.state.idsGeneratedSinceLastModeFormPopulation, newId]);
  }
  return newId;
};

// Removes all participants and deletes all messages
export const clearChat = (modeId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const chatRoomDoc = doc(currFirestore, 'chatRooms', modeId);
    const chatSecurityKeysCollection = collection(currFirestore, chatRoomDoc.path, 'securityKeys');
    const chatMessagesCollection = collection(currFirestore, chatRoomDoc.path, 'messages');

    getDocs(chatSecurityKeysCollection)
      .then((keysQuerySnap) => {
        getDocs(chatMessagesCollection)
          .then((messagesQuerySnap) => {
            const batch = writeBatch(currFirestore);

            keysQuerySnap.forEach((docSnap) => {
              // Delete all security keys.
              batch.delete(docSnap.ref);
            });

            messagesQuerySnap.forEach((docSnap) => {
              // Delete all messages.
              batch.delete(docSnap.ref);
            });

            const chatUpdate: Partial<Chat> = {
              lastChatUserToJoinId: '',
              lastChatUserToRejoinId: '',
              lastChatUserToLeaveName: '',
              dateUpdated: Timestamp.fromMillis(Date.now()),
              participants: {},
              webPushTokens: [],
            };

            batch.update(chatRoomDoc, chatUpdate);

            batch
              .commit()
              .then(() => {
                showSuccess(t.chatClearSuccessful);
                resolve();
              })
              .catch((error) => {
                showError(`Could not reset the chat.`, error, true);
                reject();
              });
          })
          .catch((error) => {
            showError(`Could not get chat messages.`, error, true);
            reject();
          });
      })
      .catch((error) => {
        showError(`Could not get security keys.`, error, true);
        reject();
      });
  });
};
