import { endpoints } from '@/constants';
import { store } from '@/store';
import { WriteBatch, doc, writeBatch, deleteField, updateDoc, Timestamp, DocumentReference, DocumentData, collection, getDocs } from 'firebase/firestore';
import { signOutAccount } from './auth-utils';
import { deleteMode } from './firestore-mode-utils';
import { currFirestore, deepCopy, fbAuth, standardApiFetch } from './initialization-utils';
import { t } from './language-utils';
import { hideLoading, showLoading } from './loading-utils';
import { showError } from './misc-firestore-utils';
import { showConfirmation, showSuccess } from './notice-utils';

export const deleteCustomPermalink = (modeId: string, customPermalink: string, givenBatch?: WriteBatch): Promise<void> => {
  return new Promise((resolve, reject) => {
    const currUser = fbAuth.currentUser;

    const onBatchFailArray: { (): void }[] = [];

    if (!currUser || !customPermalink) {
      return;
    }

    const customPermalinkDoc = doc(currFirestore, 'customPermalinks', customPermalink);
    const batch = givenBatch || writeBatch(currFirestore);

    batch.delete(customPermalinkDoc);

    const newPermalinks = { ...store.state.currUser.permalinks };
    delete newPermalinks[modeId];

    batchUpdateUserDoc(
      batch,
      {
        [`permalinks.${modeId}`]: deleteField(),
      },
      {
        permalinks: newPermalinks,
      },
      onBatchFailArray
    );

    if (!givenBatch) {
      batch
        .commit()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          onBatchFailArray.forEach((func: () => void) => func());
          showError(`Could not delete the permalink.`, error, true);
          reject();
        });
    }
  });
};

export const updateUserDoc = (update: Partial<PlatformUser>) => {
  const revertUpdate = { ...store.state.currUser };
  updateUserLocal(update);
  return updateDoc(getUserDoc(), {
    ...update,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  })
    .then(() => {
      showSuccess(t.updateSuccessful);
    })
    .catch((error: any) => {
      updateUserLocal(revertUpdate);
      showError(`Could not update the user.`, error, true);
    });
};

export const saveSettings = (update: Partial<UserSettings>) => {
  if (!store.state.isLoggedIn) {
    return;
  }
  updateUserDoc({
    settings: {
      ...store.state.currUser.settings,
      ...update,
    },
  });
};

export const batchUpdateUserDoc = (batch: WriteBatch, firestoreUpdate: Record<string, unknown>, localStoreUpdate: Partial<PlatformUser>, onBatchFailArray: { (): void }[]) => {
  const revertUpdate = { ...store.state.currUser };
  // Update local store.
  updateUserLocal(localStoreUpdate);
  // Add the update to the batch
  batch.update(getUserDoc(), {
    ...firestoreUpdate,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  });
  // This array of functions will be excuted if the batch fails.
  onBatchFailArray.push(() => updateUserLocal(revertUpdate));
};

export const updateUserLocal = (update: Partial<PlatformUser>) => {
  store.commit('currUser', {
    ...store.state.currUser,
    ...update,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  });
};

export const getUserModeGatewayDoc = () => {
  return doc(currFirestore, 'publicUserModeGateways', store.state.userId);
};

// Cannot be used for deletes.
export const updateUserModeGatewayLocal = (update: Partial<PublicUserModeGateway>) => {
  store.commit('currUserModeGateway', {
    ...store.state.currUserModeGateway,
    ...update,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  });
};

// Cannot be used for deletes.
export const updateUserModeGatewayDoc = (firestoreUpdate: Record<string, unknown>, localStoreUpdate: Partial<PublicUserModeGateway> | null = null): Promise<void> => {
  const revertUpdate = {
    ...store.state.currUserModeGateway,
    stripeAccountsMap: { ...store.state.currUserModeGateway.stripeAccountsMap },
    themes: { ...store.state.currUserModeGateway.themes },
  };

  updateUserModeGatewayLocal(localStoreUpdate || firestoreUpdate);

  return updateDoc(getUserModeGatewayDoc(), {
    ...firestoreUpdate,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  }).catch((error: any) => {
    updateUserModeGatewayLocal(revertUpdate);
    showError(`Could not update gateway.`, error, true);
  });
};

export const batchUpdateUserModeGatewayDoc = (batch: WriteBatch, update: Partial<PublicUserModeGateway>, onBatchFailArray: { (): void }[]) => {
  const revertUpdate = { ...store.state.currUserModeGateway };

  // Update local store.
  updateUserModeGatewayLocal(update);

  batch.update(getUserModeGatewayDoc(), {
    ...update,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  });
  // This array of functions will be excuted if the batch fails.

  onBatchFailArray.push(() => updateUserModeGatewayLocal(revertUpdate));
};

export const getUserDoc = () => {
  return doc(currFirestore, 'users', store.state.userId);
};

export const leaveTeam = (teamToLeaveDocId: string) => {
  const batch = writeBatch(currFirestore);
  const currUser = store.state.currUser;
  const refForTeamToLeave = (currUser.joinedTeamsRefs as DocumentReference<DocumentData>[]).find((ref: DocumentReference<DocumentData>) => ref.path.includes(teamToLeaveDocId));

  if (!refForTeamToLeave) {
    showError(`Cannot find this team in the list of teams you have joined`);
    return;
  }

  const joinedTeamsRefs = currUser.joinedTeamsRefs.filter((ref: DocumentReference<DocumentData>) => !ref.path.includes(teamToLeaveDocId));
  const userDocUpdate: Partial<PlatformUser> = {
    joinedTeamsRefs,
  };

  // Make a deep copy of vuex property since we might edit it and it doesn't have complex objects nested in it.
  const newExternalUseModeGroups: { [entryCode: string]: ExternalUseModeGroupOnUser } = deepCopy(currUser.externalUseModeGroups || {});

  // If this team had modes in external use groups, we have to delete them.
  const joinedTeam = store.state.joinedTeamsObjects[teamToLeaveDocId];
  if (currUser.externalUseModeGroups && joinedTeam) {
    const modesToDelete = joinedTeam.modeIds;
    Object.values(newExternalUseModeGroups).forEach((group) => {
      // Remove the deleted modes from every external use group
      const updatedModes = group.modes.filter((externalUseMode) => {
        return !modesToDelete.includes(externalUseMode.docId);
      });

      if (updatedModes.length !== group.modes.length) {
        // Only include this update if the length of the modes changed.
        batch.update(doc(currFirestore, 'externalUseModeGroups', group.docId), {
          modes: updatedModes,
        });
        newExternalUseModeGroups[group.docId].modes = updatedModes;
      }
    });

    userDocUpdate.externalUseModeGroups = newExternalUseModeGroups;
  }

  batch.update(getUserDoc(), userDocUpdate);

  // Remove self from the team's member collection.
  const teamMemberDoc = doc(currFirestore, refForTeamToLeave.path, 'members', currUser.docId);
  batch.delete(teamMemberDoc);

  batch.commit().then(() => {
    const newJoinedTeamsObjects = { ...store.state.joinedTeamsObjects };
    delete newJoinedTeamsObjects[teamToLeaveDocId];
    store.commit('joinedTeamsObjects', newJoinedTeamsObjects);
    updateUserLocal({
      externalUseModeGroups: newExternalUseModeGroups,
      dateUpdated: Timestamp.fromMillis(Date.now()),
    });
  });
};

export const copyText = (link: string) => {
  navigator.clipboard.writeText(link).then(
    function () {
      showSuccess(t.copiedToClipboard);
    },
    function () {
      showError(`Could not copy text`);
    }
  );
};

export const isPremiumActivated = (): boolean => {
  return store.state.isPremiumActive || store.state.currUserModeGateway?.hasPermanentPremium;
};

export const deactivatePremium = () => {
  const premiumSubscriptionId = store.state.currUserModeGateway.premiumSubscriptionId || '';
  if (premiumSubscriptionId) {
    return standardApiFetch(endpoints.cancelStripeSubscription, {
      premiumSubscriptionId,
      locale: navigator?.languages[0] || navigator.language || '',
    }).then((response) => {
      store.commit('premiumSubData', response.successfulResponse.subscription);
    });
  }
  return Promise.resolve();
};

export const deletePlatformUser = () => {
  showConfirmation(t?.areYouSureYouWantToDeleteYourAccount, () => {
    // List of things we have to do here in order:
    // 0. Cancel subscriptions.
    // 1. Unlink all devices.
    // 2. Delete any userManager record and free managed users.
    // 3. Delete modes from from publicUserModeGateways/modes. This should automatically delete submissions and active chat rooms and games. This should also delete the associated shortlinks and permalinks.
    // 4. Delete public user mode gateway doc from publicUserModeGateways.
    // 5. Delete user subcollections: analytics, stripeBusinessPayments, teams.
    // 6. Delete user doc from Users.
    // 7. Delete the user in the auth table.

    showLoading();

    const batch = writeBatch(currFirestore);
    const promiseArray: Promise<void>[] = [];

    // 0. Cancel subscriptions.
    deactivatePremium()
      .then(async () => {
        // 1. Unlink all devices.

        const newGenericIdsBoundToThisAccount = {
          ...store.state.currUser.genericIdsBoundToThisAccount,
        };

        Object.values(newGenericIdsBoundToThisAccount).forEach((linkedDevice) => {
          const genericIdDataUpdate: any = {
            userId: 'TBD',
            dateActivated: deleteField(),
          };
          if (linkedDevice.isMultiPartDevice) {
            // Remove all asssignments since this device might now go to someone new with all new modes.
            genericIdDataUpdate.multiPartModeAssignments = {};
          }
          batch.update(doc(currFirestore, 'genericIdToUserIdMappings', linkedDevice.genericId), genericIdDataUpdate);
        });

        // 2. Delete any userManager record and disconnect managed users by deleting the managedUser docs.

        // Delete the managedUser docs.
        const userManagerDoc = doc(currFirestore, 'userManagers', store.state.userId);
        const mangedUserPromise = getDocs(collection(currFirestore, userManagerDoc.path, 'managedUsers')).then((querySnapshot) => {
          querySnapshot.forEach((managedUserDoc) => {
            batch.delete(managedUserDoc.ref);
          });
        });

        promiseArray.push(mangedUserPromise);

        // Delete the userMananger.
        batch.delete(userManagerDoc);

        // 3. Delete modes from from publicUserModeGateways/modes. This should automatically delete submissions and active chat rooms and games. This should also delete the associated shortlinks and permalinks.
        const allModes = Object.values(store.state.modes);
        allModes.forEach((mode) => {
          deleteMode({ mode, showMessages: false, shouldUpdateUserDoc: false, currSelectedFolder: null });
        });

        // 4. Delete public user mode gateway doc from publicUserModeGateways.
        batch.delete(getUserModeGatewayDoc());

        // 5. Delete user subcollections: analytics, stripeBusinessPayments, teams.
        const userDoc = getUserDoc();

        // Delete analytics.
        const analyticsPromise = getDocs(collection(currFirestore, userDoc.path, 'analytics'))
          .then((querySnapshot) => {
            querySnapshot.forEach((analyticsDoc) => {
              batch.delete(analyticsDoc.ref);
            });
          })
          .catch((error) => {
            showError(`Could get analytics.`, error, true);
          });

        promiseArray.push(analyticsPromise);

        // Delete stripeBusinessPayments.
        const businessPaymentsPromise = getDocs(collection(currFirestore, userDoc.path, 'stripeBusinessPayments'))
          .then((querySnapshot) => {
            querySnapshot.forEach((businessPaymentDoc) => {
              batch.delete(businessPaymentDoc.ref);
            });
          })
          .catch((error) => {
            showError(`Could get payment records.`, error, true);
          });

        promiseArray.push(businessPaymentsPromise);

        // Delete teams.
        const teamsCollection = collection(currFirestore, userDoc.path, 'teams');
        const teamsPromise = getDocs(teamsCollection)
          .then((querySnapshot) => {
            querySnapshot.forEach((teamDoc) => {
              const membersPromise = getDocs(collection(currFirestore, teamsCollection.path, teamDoc.id, 'members')).then((querySnapshot) => {
                querySnapshot.forEach((memberDoc) => {
                  batch.delete(memberDoc.ref);
                });
              });
              promiseArray.push(membersPromise);
              batch.delete(teamDoc.ref);
            });
          })
          .catch((error) => {
            showError(`Could get teams.`, error, true);
          });

        promiseArray.push(teamsPromise);

        // 6. Delete user doc from Users.
        batch.delete(userDoc);

        Promise.all(promiseArray)
          .then(() => {
            // 7. Delete the user in the auth table.
            const auth = fbAuth;
            const currUser = auth.currentUser;

            if (currUser) {
              batch
                .commit()
                .then(() => {
                  currUser
                    .getIdToken(/* forceRefresh */ true)
                    .then((idToken) => {
                      standardApiFetch(endpoints.deleteUser, {
                        userId: store.state.userId,
                        idToken,
                      }).then(() => {
                        signOutAccount();
                        showSuccess(t.accountDeleted);
                      });
                    })
                    .catch((error) => {
                      showError(`Could not get user token to make request`, error, true);
                    });
                })
                .catch((error) => {
                  showError(`Batch commit for user deletetion failed.`, error, true);
                  hideLoading();
                });
            } else {
              hideLoading();
            }
          })
          .catch((error) => {
            showError(`Could not delete something before deleting the auth user.`, error, true);
            hideLoading();
          });
      })
      .catch(() => {
        hideLoading();
      });
  });
};
