
import Vue from 'vue';
import validationRules from '@/validation-rules';
import SitchShopItemPage from '@/components/custom-ui-components/SitchShopItemPage.vue';
import SitchOrderItemBreakdown from '@/components/custom-ui-components/SitchOrderItemBreakdown.vue';
import { eModeType } from '@/enums';
import { draggableOptions, endpoints } from '@/constants';
import { collection, onSnapshot, Timestamp } from 'firebase/firestore';
import { deleteMode, saveMode } from '@/util-functions/firestore-mode-utils';
import { sitchClientUrl, deepCopy, currFirestore, standardApiFetch, fbAuth } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError, generateId, clearChat } from '@/util-functions/misc-firestore-utils';
import { isAtMaxModes, getModeIcon } from '@/util-functions/misc-utils';
import { showConfirmation, showNotice, showPrompt } from '@/util-functions/notice-utils';
import { getUserModeGatewayDoc, updateUserDoc, updateUserModeGatewayDoc, copyText, leaveTeam, updateUserLocal, updateUserModeGatewayLocal, getUserDoc } from '@/util-functions/user-utils';
import { store } from '@/store';
import { format } from 'date-fns';
import { Capacitor } from '@capacitor/core';

interface PopulatedCategory {
  id: string;
  name: string;
  shopItems: ShopItem[];
  isHidden: boolean;
}

export default Vue.extend({
  components: {
    SitchShopItemPage,
    SitchOrderItemBreakdown,
  },
  data(): {
    leaderboardDataHeaders: TableHeaders;
    primaryFocuses: TextValue[];
    primaryFocusText: string;
    draggableOptions: Record<string, unknown>;
    showPaymentDialog: boolean;
    showExplainerDialog: boolean;
    showAutoSitchDialog: boolean;
    aCheckoutChangeWasMade: boolean;
    showCheckoutCustomerDialog: boolean;
    showLeaderboardDialog: boolean;
    shopItemListForCheckout: ShopItem[];
    recipientPaysAmount: number;
    recipientPaysCurrency: string;
    currSelectedFolder: Folder | null;
    rules: typeof validationRules;
    showNewFolderDialog: boolean;
    showSelectedFolderDialog: boolean;
    modeForFolderDialog: AnyMode | null;
    showAddModeToFolderFolderDialog: boolean;
    newFolderName: string;
    modeDragActive: boolean;
    folderDragActive: boolean;
    currentCheckoutShopItem: ShopItem | null;
    quantity: number;
    orderBreakdown: OrderItem[];
    pendingMode: AnyMode | null;
    draggedMode: AnyMode | null;
    dragTargetFolder: Folder | null;
    dragLeaveFolderInterval: number;
    showModeMenu: Record<string, boolean>;
    eModeType: typeof eModeType;
    sitchClientUrl: string;
    inDemoMode: boolean;
    watchedGatewayDoc: (() => void) | null;
    watchedUserDoc: (() => void) | null;
    watchedModesCollection: (() => void) | null;
    isNativePlatform: boolean;
    leaderboardData: {
      displayName: string;
      uniqueViewsThisMonth: number;
      uniqueDeviceViewsThisMonth: number;
    }[];
  } {
    return {
      leaderboardDataHeaders: [
        {
          text: t.name,
          value: 'displayName',
          sortable: true,
        },
        {
          text: t.uniqueTapScanViews,
          value: 'uniqueDeviceViewsThisMonth',
          sortable: true,
        },
      ],
      primaryFocuses: [
        {
          text: t.freelancer,
          value: t.freelancerInfo,
        },
        {
          text: t.influencer,
          value: t.influencerInfo,
        },
        {
          text: t.salesperson,
          value: t.salespersonInfo,
        },
        {
          text: t.eventhost,
          value: t.eventhostInfo,
        },
        {
          text: t.retailOrTakeout,
          value: t.retailOrTakeoutInfo,
        },
        {
          text: t.nothingInParticular,
          value: t.nothingInParticularInfo,
        },
      ],
      primaryFocusText: '',
      draggableOptions: draggableOptions,
      showPaymentDialog: false,
      showExplainerDialog: false,
      showAutoSitchDialog: false,
      aCheckoutChangeWasMade: false,
      showCheckoutCustomerDialog: false,
      shopItemListForCheckout: [],
      recipientPaysAmount: 100,
      recipientPaysCurrency: '',
      currSelectedFolder: null,
      rules: validationRules,
      showNewFolderDialog: false,
      showSelectedFolderDialog: false,
      showAddModeToFolderFolderDialog: false,
      showLeaderboardDialog: false,
      modeForFolderDialog: null,
      newFolderName: '',
      modeDragActive: false,
      folderDragActive: false,
      currentCheckoutShopItem: null,
      quantity: 1,
      orderBreakdown: [],
      pendingMode: null,
      draggedMode: null,
      dragTargetFolder: null,
      dragLeaveFolderInterval: 0,
      showModeMenu: {},
      eModeType,
      sitchClientUrl,
      inDemoMode: false,
      watchedGatewayDoc: null,
      watchedUserDoc: null,
      watchedModesCollection: null,
      leaderboardData: [],
      isNativePlatform: Capacitor.isNativePlatform(),
    };
  },
  mounted() {
    if (this.$store.state.userId) {
      this.watchedGatewayDoc = onSnapshot(getUserModeGatewayDoc(), (watchedDocSnap) => {
        if (watchedDocSnap.exists()) {
          updateUserModeGatewayLocal(watchedDocSnap.data() as PublicUserModeGateway);
        }
      });

      this.watchedUserDoc = onSnapshot(getUserDoc(), (watchedDocSnap) => {
        if (watchedDocSnap.exists()) {
          updateUserLocal(watchedDocSnap.data() as PlatformUser);
        }
      });

      const modeCollection = collection(currFirestore, getUserModeGatewayDoc().path, 'modes');
      this.watchedModesCollection = onSnapshot(modeCollection, (querySnapshot) => {
        const newModes: ModesMap = {};
        querySnapshot.forEach((modeDocSnap) => {
          const mode: AnyMode = modeDocSnap.data() as AnyMode;
          newModes[mode.docId] = mode;
        });
        store.commit('modes', newModes);
      });
    }
  },
  beforeDestroy() {
    if (this.watchedGatewayDoc) {
      this.watchedGatewayDoc(); // Detach old listenter if it exists
    }
    if (this.watchedUserDoc) {
      this.watchedUserDoc(); // Detach old listenter if it exists
    }
    if (this.watchedModesCollection) {
      this.watchedModesCollection(); // Detach old listenter if it exists
    }
  },
  computed: {
    currUserModeGateway(): PublicUserModeGateway {
      return this.$store.state.currUserModeGateway;
    },
    currMonth(): string {
      const date = new Date();
      return format(date, 'yyyy-MM');
    },
    nothingToShow(): boolean {
      return !this.modes.length && !this.folders.length && !this.joinedTeams.length;
    },
    isDemoAccount(): boolean {
      return ['demo@sitch.cards', 'test@sitch.app'].includes(this.$store.state.currUser.email);
    },
    joinedTeams(): Team[] {
      return Object.values(this.$store.state.joinedTeamsObjects);
    },
    isSitchLinkActivated(): boolean {
      return Boolean(this.$store.state.currUserModeGateway.isSitchLinkActivated);
    },
    canShare(): boolean {
      return Boolean(navigator.share);
    },
    lowModeCount(): boolean {
      return this.modes.length < 3 && this.$store.getters.sortedFolders.length <= 0;
    },
    singleModeCount(): boolean {
      return this.modes.length === 1 && this.$store.getters.sortedFolders.length <= 0;
    },
    twoModeCount(): boolean {
      return this.modes.length === 2 && this.$store.getters.sortedFolders.length <= 0;
    },
    folders: {
      get(): Folder[] {
        return this.$store.getters.sortedFolders || [];
      },
      set(newSortedFolders: Folder[]) {
        const newFolderOrder = newSortedFolders.map((folder: Folder) => {
          return folder.id;
        });
        updateUserDoc({
          folderOrder: newFolderOrder,
        });
      },
    },
    modes: {
      get(): AnyMode[] {
        return this.$store.getters.sortedModes;
      },
      set(newSortedModes: AnyMode[]) {
        const newModeOrder = newSortedModes.map((mode: AnyMode) => {
          return mode.docId;
        });
        updateUserDoc({
          modeOrder: newModeOrder,
        });
      },
    },
    modesForCurrentFolder: {
      get(): AnyMode[] {
        if (!this.currSelectedFolder) {
          return [];
        }
        const modeOrder: string[] = [...this.currSelectedFolder.modeOrder];
        const modesArray: Mode[] = Object.values(this.$store.state.modes) || [];

        const ret: AnyMode[] = [];
        if (modesArray.length) {
          modeOrder.forEach((modeId: string) => {
            const mode: AnyMode = this.$store.state.modes[modeId] as AnyMode;
            if (mode) {
              ret.push({ ...mode });
            }
          });
        }
        return ret;
      },
      set(newSortedModes: Mode[]) {
        const currUser: PlatformUser = this.$store.state.currUser;
        const currFolder: Folder = this.currSelectedFolder as Folder;
        const newModeOrder = newSortedModes.map((mode: Mode) => {
          return mode.docId;
        });
        const updatedFolder: Folder = {
          ...currFolder,
          modeOrder: newModeOrder,
        };
        updateUserDoc({
          folders: {
            ...currUser.folders,
            [currFolder.id]: updatedFolder,
          },
        }).then(() => {
          this.currSelectedFolder = updatedFolder;
        });
      },
    },
    enableLoadingNewActiveModesAutomatically: {
      get(): AnyMode[] {
        return this.$store.state.currUserModeGateway.loadNewActiveModesAutomatically;
      },
      set(value: boolean) {
        updateUserModeGatewayDoc(
          {
            loadNewActiveModesAutomatically: value,
          },
          {
            loadNewActiveModesAutomatically: value,
          }
        );
      },
    },
    autoSitchTimer: {
      get(): AnyMode[] {
        return this.$store.state.currUserModeGateway.autoSitchTimer;
      },
      set(value: number) {
        updateUserModeGatewayDoc(
          {
            autoSitchTimer: value,
          },
          {
            autoSitchTimer: value,
          }
        );
      },
    },
    folderOrder(): string[] {
      return this.$store.state.currUser.folderOrder;
    },
    modeOrder(): string[] {
      return this.$store.getters.modeOrder;
    },
    currMode(): AnyMode {
      return this.$store.getters.currMode;
    },
    nonCategorizedShopItemList(): ShopItem[] {
      if (this.pendingMode && this.pendingMode.type === eModeType.shop) {
        const shopMode = this.pendingMode as ShopMode;
        const allCategorizedShopItems: ShopItem[] = this.shopItemListForCheckout.filter((shopItem) => {
          return !shopMode.categories.flatMap((category) => category.shopItemIds).includes(shopItem.id);
        });
        return allCategorizedShopItems;
      }
      return [];
    },
    pendingShopModeCategories(): PopulatedCategory[] {
      if (this.pendingMode && this.pendingMode.type === eModeType.shop) {
        const shopMode = this.pendingMode as ShopMode;
        const allShopItemsMap: { [shopItemId: string]: ShopItem } = {};
        shopMode.shopItemList.forEach((shopItem) => {
          allShopItemsMap[shopItem.id] = shopItem;
        });
        return shopMode.categories.map((category) => {
          return {
            ...category,
            shopItems: category.shopItemIds.map((shopItemId) => allShopItemsMap[shopItemId]),
          };
        });
      }
      return [];
    },
  },
  methods: {
    modeButtonInnerClassFunc(mode: AnyMode): object {
      const modeUrl = `${sitchClientUrl}/s/${mode.linkId}`;
      const wrapperActive = this.currUserModeGateway.wrapperModeId === modeUrl || this.currUserModeGateway.wrapperModeId === mode.docId;
      const wrapperModeIsGroup = wrapperActive && [eModeType.group].includes(mode.type as eModeType);
      const activeModeIsSite = [eModeType.site].includes(this.$store.getters.currMode?.type);
      const activeModeIsGroup = [eModeType.group, eModeType.urlRedirect].includes(this.$store.getters.currMode?.type);
      return {
        active: this.currUserModeGateway.activeModeId === modeUrl || this.currUserModeGateway.activeModeId === mode.docId,
        'wrapper-active': wrapperActive,
        'wrapper-active-disabled': wrapperActive && (activeModeIsSite || (wrapperModeIsGroup && activeModeIsGroup)),
      };
    },
    showLeaderboard(team: Team) {
      this.showLeaderboardDialog = true;
      const currUser = fbAuth.currentUser;
      if (!currUser) {
        showError(`No logged in user`, null, true);
        return;
      }
      currUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        standardApiFetch(endpoints.getLeaderboardData, {
          idToken,
          userId: this.$store.state.userId,
          teamId: team.docId,
          teamOwnerId: team.teamOwnerId,
        })
          .then((response) => {
            this.leaderboardData = response.successfulResponse?.leaderboardData;
          })
          .catch(() => {
            showError('Could not get the leaderboard data.');
          });
      });
    },
    onLeaveTeam(teamToLeaveDocId: string) {
      showConfirmation(t?.leaveTeamConfirmation, () => {
        leaveTeam(teamToLeaveDocId);
      });
    },
    isSoldOut(shopItem: ShopItem) {
      return shopItem.isSoldOut || (shopItem.hasStock && !shopItem.stock);
    },
    onModeDragStart(value: any) {
      this.modeDragActive = true;
      const draggedMode = this.modes[value.oldIndex];
      if (draggedMode) {
        this.draggedMode = draggedMode;
      }
    },
    onModeDragEnd() {
      if (this.dragTargetFolder) {
        this.moveToFolder(this.dragTargetFolder, this.draggedMode);
        this.dragTargetFolder = null;
      }
      this.modeDragActive = false;
      this.draggedMode = null;
    },
    onDragEnterFolder(folder: Folder) {
      clearInterval(this.dragLeaveFolderInterval);
      this.dragTargetFolder = folder;
    },
    onDragLeaveFolder() {
      this.dragLeaveFolderInterval = window.setTimeout(() => {
        this.dragTargetFolder = null;
      }, 100);
    },
    onOpenMode(mode: AnyMode) {
      const modeLink = mode.linkId ? `${sitchClientUrl}/s/${mode.linkId}` : `${sitchClientUrl}?u=${this.$store.state.userId}&am=${mode.docId}`;
      window.open(modeLink, '_blank');
    },
    oncopyText(mode: AnyMode) {
      const permalink = this.$store.state.currUser.permalinks[mode.docId] || '';
      const modeLink = permalink ? `${sitchClientUrl}/${permalink}` : mode.linkId ? `${sitchClientUrl}/s/${mode.linkId}` : `${sitchClientUrl}?u=${this.$store.state.userId}&am=${mode.docId}`;
      copyText(modeLink);
    },
    onShareLink(mode: AnyMode) {
      if (navigator.share) {
        const permalink = this.$store.state.currUser.permalinks[mode.docId] || '';
        const modeLink = permalink ? `${sitchClientUrl}/${permalink}` : mode.linkId ? `${sitchClientUrl}/s/${mode.linkId}` : `${sitchClientUrl}?u=${this.$store.state.userId}&am=${mode.docId}`;
        navigator.share({
          title: 'Sitch',
          text: mode.displayName || mode.name,
          url: modeLink,
        });
      }
    },
    folderContains(folder: Folder, selectedModeId: string): boolean {
      return folder.modeOrder.some((modeId) => modeId === selectedModeId);
    },
    onBreakdownChange(newOrderBreakdown: OrderItem[]) {
      this.currentCheckoutShopItem = null;
      this.orderBreakdown = newOrderBreakdown;
      this.aCheckoutChangeWasMade = true;
    },
    onOpenShopItemModal(shopItem: ShopItem) {
      this.currentCheckoutShopItem = shopItem;
    },
    createNewModeInFolder() {
      const currFolder: Folder = this.currSelectedFolder as Folder;
      if (!isAtMaxModes()) {
        this.$router.push({ path: `/SitchForm?folderId=${currFolder.id}` });
      }
    },
    deleteFolder() {
      showConfirmation(t?.deleteFolderConfirmationMessage, () => {
        const currUser: PlatformUser = this.$store.state.currUser;
        const currFolder: Folder = this.currSelectedFolder as Folder;
        const modeOrder = [...this.modeOrder];
        const folderOrder = this.folderOrder.filter((folderId: string) => folderId !== currFolder.id);
        currFolder.modeOrder.forEach((modeId: string) => {
          modeOrder.push(modeId);
        });
        const folders = { ...currUser.folders };
        delete folders[currFolder.id];
        updateUserDoc({
          modeOrder,
          folderOrder,
          folders,
        }).then(() => {
          this.showSelectedFolderDialog = false;
        });
      });
    },
    openFolder(folder: Folder) {
      this.currSelectedFolder = folder;
      this.showSelectedFolderDialog = true;
    },
    openFolderSelectionDialog(mode: AnyMode) {
      this.modeForFolderDialog = mode;
      this.showAddModeToFolderFolderDialog = true;
    },
    onCloseModeForFolderDialog() {
      this.modeForFolderDialog = null;
      this.showAddModeToFolderFolderDialog = false;
    },
    openNewFolderDialog() {
      this.newFolderName = '';
      this.showNewFolderDialog = true;
    },
    moveToFolder(destinationFolder: Folder, draggedMode: AnyMode | null = null) {
      const currUser: PlatformUser = this.$store.state.currUser;
      const currMode: AnyMode | null = draggedMode || this.modeForFolderDialog;

      if (!currMode) {
        return;
      }

      // If the mode is in the top level mode order then it is not in a folder.
      const isNotInFolderAlready = this.modeOrder.includes(currMode.docId);
      let update: any;
      const newDestinationFolder: Folder = {
        ...destinationFolder,
        modeOrder: [...destinationFolder.modeOrder, currMode.docId],
      };

      if (isNotInFolderAlready) {
        // In this case just move the mode out of the top level mode order.
        update = {
          modeOrder: this.modeOrder.filter((modeId) => modeId !== currMode.docId),
          folders: {
            ...this.$store.state.currUser.folders,
            [destinationFolder.id]: newDestinationFolder,
          },
        };
      } else {
        // In this case cycle through folders to find the mode
        const folderTheModeIsCurrentlyIn: Folder | undefined = Object.values(currUser.folders).find((folder) => {
          return folder.modeOrder.includes(currMode.docId);
        });

        if (folderTheModeIsCurrentlyIn) {
          const newModeOrderForOldFolder = folderTheModeIsCurrentlyIn.modeOrder.filter((modeId) => modeId !== currMode.docId);
          const updatedOldFolder: Folder = {
            ...folderTheModeIsCurrentlyIn,
            modeOrder: newModeOrderForOldFolder,
          };
          update = {
            folders: {
              ...this.$store.state.currUser.folders,
              [folderTheModeIsCurrentlyIn.id]: updatedOldFolder,
              [destinationFolder.id]: newDestinationFolder,
            },
          };
        }
      }

      updateUserDoc(update).then(() => {
        this.onCloseModeForFolderDialog();
      });
    },
    removeFromFolder(mode: AnyMode) {
      if (!mode) {
        return;
      }

      // In this case cycle through folders to find the mode
      const folderTheModeIsCurrentlyIn: Folder = this.currSelectedFolder as Folder;
      const newModeOrderForFolder = folderTheModeIsCurrentlyIn.modeOrder.filter((modeId) => modeId !== mode.docId);
      const updatedFolder: Folder = {
        ...folderTheModeIsCurrentlyIn,
        modeOrder: newModeOrderForFolder,
      };

      const update = {
        modeOrder: [...this.modeOrder, mode.docId],
        folders: {
          ...this.$store.state.currUser.folders,
          [folderTheModeIsCurrentlyIn.id]: updatedFolder,
        },
      };
      updateUserDoc(update).then(() => {
        this.currSelectedFolder = updatedFolder;
        if (!updatedFolder.modeOrder.length) {
          this.showSelectedFolderDialog = false;
        }
      });
      this.$forceUpdate();
    },
    createNewFolder() {
      const currUser: PlatformUser = this.$store.state.currUser;
      if (!(this.$refs.newFolderForm as any).validate()) {
        showError(t?.formErrors);
        return;
      }

      if (currUser.folderOrder.includes(this.newFolderName)) {
        showError(t?.formErrors);
      }

      const newFolder: Folder = {
        id: generateId(),
        name: this.newFolderName,
        modeOrder: [],
        dateCreated: Date.now(),
      };

      updateUserDoc({
        folders: {
          ...currUser.folders,
          [newFolder.id]: newFolder,
        },
        folderOrder: [...currUser.folderOrder, newFolder.id],
      }).then(() => {
        this.showNewFolderDialog = false;
      });
    },
    resetOrderBreakdownForCustomerCheckout(givenMode: AnyMode) {
      if (givenMode) {
        this.pendingMode = givenMode;
      }
      const pendingMode = this.pendingMode as AnyMode;
      saveMode({
        ...this.pendingMode,
        preCheckoutOrderBreakdown: [],
        isForUpdate: true,
      }).then(() => {
        this.setActiveMode(pendingMode);
        this.pendingMode = null;
      });
      this.orderBreakdown = [];
      this.showCheckoutCustomerDialog = false;
      this.$forceUpdate();
    },
    onUpdateCheckout() {
      const pendingMode = this.pendingMode as AnyMode;
      if (this.aCheckoutChangeWasMade) {
        this.aCheckoutChangeWasMade = false;
        saveMode({
          ...this.pendingMode,
          preCheckoutOrderBreakdown: this.orderBreakdown,
          isForUpdate: true,
        }).then(() => {
          this.setActiveMode(pendingMode);
          this.pendingMode = null;
        });
      } else {
        this.setActiveMode(pendingMode);
        this.pendingMode = null;
      }
      this.showCheckoutCustomerDialog = false;
    },
    onCancelUpdatePayAmount() {
      const pendingMode = this.pendingMode as AnyMode;
      this.setActiveMode(pendingMode);
      this.showPaymentDialog = false;
      this.pendingMode = null;
    },
    onUpdatePayAmount() {
      const pendingMode = this.pendingMode as AnyMode;
      saveMode({
        ...this.pendingMode,
        amount: this.recipientPaysAmount,
        isForUpdate: true,
      }).then(() => {
        this.setActiveMode(pendingMode);
        this.pendingMode = null;
      });
      this.showPaymentDialog = false;
    },
    deactivateActiveMode() {
      return updateUserModeGatewayDoc({
        activeModeId: '',
        activeModeLinkId: '',
        activeModeOwnerId: '',
      });
    },
    deactivateWrapperMode() {
      return updateUserModeGatewayDoc({
        wrapperModeId: '',
        wrapperModeLinkId: '',
        wrapperModeOwnerId: '',
      });
    },
    selectMode(mode: AnyMode, team: Team | null = null) {
      let modeToSet = mode;
      this.setActiveMode(modeToSet, team);
      switch (mode.type) {
        case eModeType.wifi: {
          showConfirmation(t.thisModeWorksBestWithQRCodes, () => {
            this.$router.push({ path: '/QR' });
          });
          break;
        }
      }
    },
    setActiveMode(mode: AnyMode, team: Team | null = null) {
      const activeModeOwnerId = team?.teamOwnerId || '';
      const wrapperMode = this.$store.getters.currWrapperMode;
      const wrapperModeIsGroup = [eModeType.group].includes(wrapperMode?.type);

      if (mode.linkId !== this.$store.getters.currWrapperMode?.linkId) {
        if (wrapperMode && mode.type === eModeType.site) {
          showNotice(t.cannotWrapSites);
        } else if (wrapperModeIsGroup && mode.type === eModeType.group) {
          showNotice(t.cannotWrapGroupsInGroups);
        }
      }

      const activeModeId = mode.docId;
      const activeModeLinkId = mode.linkId;
      const update: Partial<PublicUserModeGateway> = {
        activeModeId,
        activeModeLinkId,
        activeModeOwnerId,
      };
      // Mode can't be active and wrapper at the same time.
      if (this.currUserModeGateway.wrapperModeLinkId === mode.linkId) {
        update.wrapperModeId = '';
        update.wrapperModeLinkId = '';
        update.wrapperModeOwnerId = '';
      }
      return updateUserModeGatewayDoc(update);
    },
    setWrapperMode(mode: AnyMode, team: Team | null = null) {
      const wrapperModeOwnerId = team?.teamOwnerId || '';
      const activeModeIsSite = [eModeType.site].includes(this.$store.getters.currMode?.type);
      const activeModeIsGroup = [eModeType.group].includes(this.$store.getters.currMode?.type);

      if (mode.linkId !== this.$store.getters.currMode?.linkId) {
        if (activeModeIsSite) {
          showNotice(t.cannotWrapSites);
        } else if (activeModeIsGroup && mode.type === eModeType.group) {
          showNotice(t.cannotWrapGroupsInGroups);
        }
      }

      const wrapperModeId = mode.docId;
      const wrapperModeLinkId = mode.linkId;
      const update: Partial<PublicUserModeGateway> = {
        wrapperModeId,
        wrapperModeLinkId,
        wrapperModeOwnerId,
      };
      // Mode can't be active and wrapper at the same time.
      if (this.currUserModeGateway.activeModeLinkId === mode.linkId) {
        update.activeModeId = '';
        update.activeModeLinkId = '';
        update.activeModeOwnerId = '';
      }
      return updateUserModeGatewayDoc(update);
    },
    updateCurrSelectedFolderName(newName: string) {
      const currUser: PlatformUser = this.$store.state.currUser;
      const currFolder: Folder = this.currSelectedFolder as Folder;
      updateUserDoc({
        folders: {
          ...currUser.folders,
          [currFolder.id]: {
            ...currFolder,
            name: newName,
          },
        },
      });
    },
    showPaymentDialogFunc(paymentMode: PersonalPaymentsMode | BusinessPaymentsMode) {
      this.showPaymentDialog = true;
      this.pendingMode = paymentMode;
      this.recipientPaysAmount = paymentMode.amount || 100;
      if (paymentMode.type === eModeType.businessPayment) {
        this.recipientPaysCurrency = (paymentMode as BusinessPaymentsMode).currency || '';
      }
    },
    showCheckoutCustomerDialogFunc(shopMode: ShopMode) {
      this.showCheckoutCustomerDialog = true;
      this.pendingMode = shopMode;
      this.shopItemListForCheckout = shopMode.shopItemList || [];
      if (shopMode.preCheckoutOrderBreakdown?.length) {
        this.orderBreakdown = shopMode.preCheckoutOrderBreakdown;
      }
    },
    getModeIcon(type: eModeType) {
      return getModeIcon(type);
    },
    createNewMode() {
      if (!isAtMaxModes()) {
        this.$router.push({ path: '/SitchForm' });
      }
    },
    editMode(mode: AnyMode) {
      this.$router.push({ path: `/SitchForm?id=${mode.docId}` });
    },
    setModeAndShowQrCode(mode: AnyMode, team: Team | null = null) {
      this.setActiveMode(mode, team);
      this.$nextTick(() => {
        this.$router.push({ path: '/QR' });
      });
    },
    clearChat(mode: AnyMode) {
      showConfirmation(t?.confirmChatClear, () => {
        clearChat(mode.docId);
      });
    },
    cloneMode(mode: AnyMode) {
      const newMode: any = {
        ...deepCopy(mode), // Use a deep copy so that manipulating objects in the new mode won't affect the old mode.
        linkId: '',
        docId: '',
        dateCreated: Timestamp.fromMillis(Date.now()),
        dateUpdated: Timestamp.fromMillis(Date.now()),
      };
      if (newMode.images) {
        newMode.images = [];
      }
      if (newMode.secondaryImages) {
        newMode.secondaryImages = [];
      }
      // Interate through all arrays and remove anything with the images property
      for (const prop in newMode) {
        const currVal = (newMode as any)[prop];
        if (Array.isArray(currVal)) {
          // We only have to do these checks one level deep since no arrays with images will be any deeper.
          newMode[prop] = currVal;
          newMode[prop].forEach((item: any) => {
            if (item.images) {
              item.images = [];
            }
            if (item.secondaryImages) {
              item.secondaryImages = [];
            }
          });
        }
      }
      saveMode(newMode);
    },
    toggleAutoSitch() {
      if (!this.isSitchLinkActivated) {
        showPrompt(t.sitchLinkNeeded);
        this.enableLoadingNewActiveModesAutomatically = false;
        return;
      }
      this.enableLoadingNewActiveModesAutomatically = !this.enableLoadingNewActiveModesAutomatically;
    },
    deleteMode(mode: AnyMode) {
      showConfirmation(t?.confirmDelete, () => {
        deleteMode({ mode, showMessages: true, shouldUpdateUserDoc: true, currSelectedFolder: this.currSelectedFolder });
      });
    },
    canBeSetToActive(mode: Mode): boolean {
      const modeUrl = `${sitchClientUrl}/s/${mode.linkId}`;
      return !(this.currUserModeGateway.activeModeId === modeUrl || this.currUserModeGateway.activeModeId === mode.docId);
    },
    canBeSetAsWrapper(mode: Mode): boolean {
      const modeTypesThatCanBeWrappers: eModeType[] = [eModeType.group, eModeType.site];
      if (mode.type) {
        const modeUrl = `${sitchClientUrl}/s/${mode.linkId}`;
        return !(this.currUserModeGateway.wrapperModeId === modeUrl || this.currUserModeGateway.wrapperModeId === mode.docId) && modeTypesThatCanBeWrappers.includes(mode.type);
      }
      return false;
    },
    isWrapperMode(mode: Mode): boolean {
      const modeUrl = `${sitchClientUrl}/s/${mode.linkId}`;
      return this.currUserModeGateway.wrapperModeId === modeUrl || this.currUserModeGateway.wrapperModeId === mode.docId;
    },
  },
});
