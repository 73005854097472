import { endpoints } from "@/constants";
import { store } from "@/store";
import { isProductionEnv, standardApiFetch } from "./initialization-utils";
import { showLoading, hideLoading } from "./loading-utils";
import { showError } from "./misc-firestore-utils";
import { isFeatureEnabled } from "./misc-utils";
import { showNotice } from "./notice-utils";
import { getStripePublicKey } from "./stripe-utils";

export const getSubscriptionData = () => {
  const currUserModeGateway = store.state.currUserModeGateway;
  if (currUserModeGateway?.hasPermanentPremium) {
    return;
  }
  const premiumSubscriptionId = currUserModeGateway.premiumSubscriptionId;
  if (premiumSubscriptionId) {
    standardApiFetch(endpoints.getStripeSubscription, {
      premiumSubscriptionId,
    }).then((response) => {
      store.commit('premiumSubData', response.successfulResponse.subscription);
    });
  }
};

export const onActivatePremiumClick = () => {
  if (!isFeatureEnabled(store.state.featureFlags.sitchPremium)) {
    showNotice("We'll be enabling this soon!");
    return;
  }

  const stripe = (window as any).Stripe(getStripePublicKey());
  const domain = window.location.origin;
  showLoading();
  stripe
    .redirectToCheckout({
      lineItems: [{ price: isProductionEnv ? 'price_1IrpL6KYg58pePnfVGBTvA8K' : 'price_1IrpMOKYg58pePnfbrSKsmsU', quantity: 1 }],
      mode: 'subscription',
      clientReferenceId: `${store.state.userId}:sitchPremium`,
      customerEmail: store.state.currUser.email,
      // Do not rely on the redirect to the successUrl for fulfilling
      // purchases, customers may not always reach the success_url after
      // a successful payment.
      // Instead use one of the strategies described in
      // https://stripe.com/docs/payments/checkout/fulfillment
      successUrl: `${domain}/Account/sitchPremiumPaymentSucceeded`,
      cancelUrl: `${domain}/Account/sitchPremiumPaymentCancelled`,
      submitType: 'pay',
    })
    .then((result: any) => {
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.
        showError(result.error.message);
      }
    })
    .catch((err: any) => {
      showError(`Error opening the checkout page: ${err?.message}`, err, true);
    })
    .finally(() => {
      hideLoading();
    });
};
