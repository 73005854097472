import { MAX_SHOP_ITEM_QUANTITY, URL_REGEX } from '@/constants';
import { t } from './util-functions/language-utils';
import { formatCurrency } from './util-functions/misc-utils';

const firebasePasswordMinChars = 5;
const maxPasswordCharacterLength = 100;
const maxNameLength = 50;
const maxShortStringLength = 128;
const maxLongStringLength = 5000;
const phoneRegEx = /^[0-9()+-\s]+$/;
const hasUrlSchemeRegEx = /(^\w+:|^)\/\/.+/;

const validationRules = {
  requiredRules: [(v: any) => !!v || v === false || v === 0 || t.isRequired],
  phoneRules: [(v: string) => (v ? phoneRegEx.test(v) || t?.mustBeValidPhoneNumber : true)],
  requiredPhoneRules: [(v: string) => !!v || t?.isRequired, (v: string) => (v ? phoneRegEx.test(v) || t?.mustBeValidPhoneNumber : true)],
  emailRules: [(v: string) => (v ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/.test(v) || t?.mustBeValidEmail : true)],  
  requiredEmailRules: [(v: string) => !!v || t?.isRequired, (v: string) => (v ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,64}$/.test(v) || t?.mustBeValidEmail : true)],
  requiredEmailDomainRules: [(v: string) => !!v || t?.isRequired, (v: string) => (v ? /^@\w+([.-]?\w+)*(\.\w{2,64})+$/.test(v) || t?.mustBeValidEmailDomain : true)],
  urlRules: [(v: string) => (v ? URL_REGEX.test(v) || hasUrlSchemeRegEx.test(v) || t?.mustBeValidUrl : true)], // Is valid if it has a tld or a scheme.
  requiredUrlRules: [(v: string) => !!v || t?.isRequired, (v: string) => (v ? URL_REGEX.test(v) || hasUrlSchemeRegEx.test(v) || t?.mustBeValidUrl : true)], // Is valid if it has a tld or a scheme.
  requiredPaypalUrlRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v && v.includes('paypal.me')) || t.mustBeValidUrlLike.supplant(['paypal.me']),
    (v: string) => (v ? URL_REGEX.test(v) || t?.mustBeValidUrl : true),
  ],
  requiredVenmoUrlRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v && v.includes('venmo.com')) || t.mustBeValidUrlLike.supplant(['venmo.com']),
    (v: string) => (v ? URL_REGEX.test(v) || t?.mustBeValidUrl : true),
  ],
  requiredCashAppUrlRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v && v.includes('cash.app')) || t.mustBeValidUrlLike.supplant(['cash.app']),
    (v: string) => (v ? URL_REGEX.test(v) || t?.mustBeValidUrl : true),
  ],
  requiredBitcoinAddress: [(v: string) => !!v || t?.isRequired, (v: string) => /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(v) || t?.mustBeValidBitcoinAddress],
  requiredEthereumAddress: [(v: string) => !!v || t?.isRequired, (v: string) => /^0x[a-fA-F0-9]{40}$/.test(v) || t?.mustBeValidEthereumAddress],
  requiredLitecoinAddress: [(v: string) => !!v || t?.isRequired, (v: string) => /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/.test(v) || t?.mustBeValidLitecoinAddress],
  customLinkRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v && v.toLowerCase() === v) || t?.mustBeAllLowercase,
    (v: string) => /^[a-zA-Z0-9_-]*$/.test(v) || t?.urlSafeChars,
    (v: string) => (v ? v.length <= 30 || t?.mustBeLessThanOrEqualTo.supplant(['30']) : true),
  ],
  nameRules: [(v: string) => (v ? v.length < maxNameLength || t?.mustBeLessThanOrEqualTo.supplant([maxNameLength.toString()]) : true)],
  requiredNameRules: [(v: string) => !!v || t?.isRequired, (v: string) => (v && v.length < maxNameLength) || t?.mustBeLessThanOrEqualTo.supplant([maxNameLength.toString()])],
  quantityRules: [
    (v: string) => !!v || t?.isRequired,
    (v: number) => v > 0 || t?.mustBeGreaterThanNumber.supplant([0]),
    (v: number) => v <= MAX_SHOP_ITEM_QUANTITY || t?.mustBeLessThanOrEqualToNumber.supplant([MAX_SHOP_ITEM_QUANTITY]),
  ],
  promoCodeRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => /^[a-zA-Z0-9]*$/.test(v) || t?.lettersAndNumbersOnly,
    (v: string) => (v && v.length <= 20) || t?.mustBeLessThanOrEqualTo.supplant([(20).toString()]),
  ],
  cssDimensionRules: [
    (v: string) => v === '' || /^[0-9]{1,5}[A-Za-z]{1,4}$/.test(v) || t?.invalidValue,
    (v: string) => v === '' || (v && v.length <= 20) || t?.mustBeLessThanOrEqualTo.supplant([(20).toString()]),
  ],
  shortStringRules: [(v: string) => (v ? v.length < maxShortStringLength || t?.mustBeLessThanOrEqualTo.supplant([maxShortStringLength.toString()]) : true)],
  requiredShortStringRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v ? v.length < maxShortStringLength || t?.mustBeLessThanOrEqualTo.supplant([maxShortStringLength.toString()]) : true),
  ],
  genericStringRules: [(v: string) => (v ? v.length < maxLongStringLength || t?.mustBeLessThanOrEqualTo.supplant([maxLongStringLength.toString()]) : true)],
  requiredGenericStringRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v ? v.length < maxLongStringLength || t?.mustBeLessThanOrEqualTo.supplant([maxLongStringLength.toString()]) : true),
  ],
  requiredSocialMediaHandleRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v ? v.length < maxLongStringLength || t?.mustBeLessThanOrEqualTo.supplant([maxLongStringLength.toString()]) : true),
    (v: string) => (v ? !(URL_REGEX.test(v) && v.includes('http://')) || t?.inputAHandleInsteadOfUrl : true),
  ],
  passwordRules: [
    (v: string) => v === '' || (v && v.length > firebasePasswordMinChars) || t?.mustBeGreaterThanCharacters.supplant([firebasePasswordMinChars.toString()]),
    (v: string) => v === '' || (v && v.length < maxPasswordCharacterLength) || t?.mustBeLessThanOrEqualTo.supplant([maxPasswordCharacterLength.toString()]),
  ],
  requiredPasswordRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => v === '' || (v && v.length > firebasePasswordMinChars) || t?.mustBeGreaterThanCharacters.supplant([firebasePasswordMinChars.toString()]),
    (v: string) => v === '' || (v && v.length < maxPasswordCharacterLength) || t?.mustBeLessThanOrEqualTo.supplant([maxPasswordCharacterLength.toString()]),
  ],
  percentageRules: [
    (v: string) => v !== undefined || t?.isRequired,
    (v: number) => v >= 0 || t?.mustBeGreaterThanOrEqualToNumber.supplant([0]),
    (v: number) => v <= 100 || t?.mustBeLessThanOrEqualToNumber.supplant([100]),
  ],
  requiredDistanceRules: [
    (v: string) => v !== undefined || t?.isRequired,
    (v: number) => v >= 0 || t?.mustBeGreaterThanOrEqualToNumber.supplant([0]),
    (v: number) => v <= 100 || t?.mustBeLessThanOrEqualToNumber.supplant([10000]),
  ],
  promoCodePercentageRules: [
    (v: string) => !!v || t?.isRequired,
    (v: number) => v >= 1 || t?.mustBeGreaterThanOrEqualToNumber.supplant([1]),
    (v: number) => v <= 100 || t?.mustBeLessThanOrEqualToNumber.supplant([100]),
  ],
  stockRules: [
    (v: string) => (v !== null && v !== undefined) || t?.isRequired,
    (v: number) => v >= 0 || t?.mustBeGreaterThanOrEqualToNumber.supplant([0]),
    (v: number) => v <= 1_000_000 || t?.mustBeLessThanOrEqualToNumber.supplant([1_000_000]),
  ],
  refundAmountRules(currency: string) {
    return [(v: number) => (v !== null && v !== undefined) || t?.isRequired, (v: number) => v === 0 || v >= 1 || t?.mustBeZeorOrGreaterThanOrEqualToNumber.supplant([formatCurrency(100, currency)])];
  },
  amountRules(currency: string) {
    return [
      (v: number) => (v !== null && v !== undefined) || t?.isRequired,
      (v: number) => v === 0 || v >= 1 || t?.mustBeZeorOrGreaterThanOrEqualToNumber.supplant([formatCurrency(100, currency)]),
      (v: number) => v <= 10_000 || t?.mustBeLessThanOrEqualToNumber.supplant([formatCurrency(10_000_00, currency)]),
    ];
  },
  amountRulesNegativePossibleNotRequired(currency: string) {
    return [
      (v: number) => v > -10_000 || t?.mustBeGreaterThanNumber.supplant([formatCurrency(-10_000_00, currency)]),
      (v: number) => v < 10_000 || t?.mustBeLessThanNumber.supplant([formatCurrency(10_000_00, currency)]),
    ];
  },
  eTransferPasswordRules: [(v: string) => (v ? /^[a-zA-Z0-9]{3,25}$/.test(v) || t?.mustBeValidETransferPassword : true)],
  timeslotStartOfDayRules: [(v: string) => !!v || t?.isRequired, (v: string) => v !== '23:59' || t?.invalidStartOfDay],
  timeslotEndOfDayRules: [(v: string) => !!v || t?.isRequired, (v: string) => v !== '00:00' || t?.invalidEndOfDay],
  minMaxRules(min: number, max: number) {
    return [
      (v: number) => (v !== null && v !== undefined) || t?.isRequired,
      (v: number) => v >= min || t?.mustBeGreaterThanOrEqualToNumber.supplant([min]),
      (v: number) => v <= max || t?.mustBeLessThanOrEqualToNumber.supplant([max]),
    ];
  },
  requiredEmbeddableUrlRules: [
    (v: string) => !!v || t?.isRequired,
    (v: string) => (v ? URL_REGEX.test(v) || t?.mustBeValidUrl : true),
    (v: string) => {
      const val = v.toLowerCase();
      if (val.includes('youtube.com') || val.includes('youtu.be')) {
        return true;
      } else if (val.includes('vimeo.com')) {
        return true;
      } else if (val.includes('open.spotify.com')) {
        return true;
      } else if (val.includes('.mp4?') || val.endsWith('.mp4')) {
        return true;
      } else if (val.includes('.webm?') || val.endsWith('.webm')) {
        return true;
      } else if (val.includes('.ogg?') || val.endsWith('.ogg')) {
        return true;
      } else if (val.includes('.mp3?') || val.endsWith('.mp3')) {
        return true;
      } else if (val.includes('.wav?') || val.endsWith('.wav')) {
        return true;
      } else if (val.includes('.gif?') || val.endsWith('.gif')) {
        return true;
      } else if (val.includes('.png?') || val.endsWith('.png')) {
        return true;
      } else if (val.includes('.jpg?') || val.endsWith('.jpg')) {
        return true;
      } else if (val.includes('.jpeg?') || val.endsWith('.jpeg')) {
        return true;
      } else if (val.includes('.svg?') || val.endsWith('.svg')) {
        return true;
      }
      return t.invalidEmbedLink;
    },
  ],
};

export default validationRules;
