import { eTranslationLocaleCode } from '@/enums';
import { store } from '@/store';
import { getDoc, doc } from 'firebase/firestore';
import { currFirestore, i18n } from './initialization-utils';
import { showLoading, hideLoading } from './loading-utils';
import { showError } from './misc-firestore-utils';
import { saveSettings } from './user-utils';
import messages from '@/translations';

export let t: any = messages.en || '';

export const changeLanguage = async () => {
  const localeCode = store.state.locale;

  if (!localeCode) {
    return;
  }

  const setLocale = () => {
    i18n.locale = localeCode;
    t = (messages as any)[localeCode];
  };

  const alreadyFetchedLanguage = Boolean(Object.keys(i18n.messages[localeCode]).length);
  if (!alreadyFetchedLanguage) {
    showLoading();
    getDoc(doc(currFirestore, 'managerTranslations', localeCode))
      .then((docSnap) => {
        if (docSnap.exists()) {
          const translationsObject: any = {
            ...messages.en, // Merge to fill in any missing translations.
            ...docSnap.data(),
          };
          i18n.setLocaleMessage(localeCode, translationsObject);
        } else {
          showError(`No data in the database for that language.`, null, true);
        }
        setLocale();
      })
      .catch((error) => {
        showError(`Fetching translations unsuccessful.`, error, true);
      })
      .finally(() => {
        hideLoading();
      });
  } else {
    setLocale();
  }
};

export const setDefaultLanguage = () => {
  function getLang() {
    if (navigator.languages !== undefined) {
      return navigator.languages[0];
    }
    return navigator.language;
  }

  const language = getLang() || eTranslationLocaleCode.en;
  let localeCode: eTranslationLocaleCode = eTranslationLocaleCode.en;

  if (language.includes(eTranslationLocaleCode.zh)) {
    localeCode = eTranslationLocaleCode.zh;
  } else if (language.includes(eTranslationLocaleCode.es)) {
    localeCode = eTranslationLocaleCode.es;
  } else if (language.includes(eTranslationLocaleCode.hi)) {
    localeCode = eTranslationLocaleCode.hi;
  } else if (language.includes(eTranslationLocaleCode.ar)) {
    localeCode = eTranslationLocaleCode.ar;
  } else if (language.includes(eTranslationLocaleCode.bn)) {
    localeCode = eTranslationLocaleCode.bn;
  } else if (language.includes(eTranslationLocaleCode.pt)) {
    localeCode = eTranslationLocaleCode.pt;
  } else if (language.includes(eTranslationLocaleCode.ru)) {
    localeCode = eTranslationLocaleCode.ru;
  } else if (language.includes(eTranslationLocaleCode.ja)) {
    localeCode = eTranslationLocaleCode.ja;
  } else if (language.includes(eTranslationLocaleCode.pa)) {
    localeCode = eTranslationLocaleCode.pa;
  } else if (language.includes(eTranslationLocaleCode.it)) {
    localeCode = eTranslationLocaleCode.it;
  } else if (language.includes(eTranslationLocaleCode.de)) {
    localeCode = eTranslationLocaleCode.de;
  } else if (language.includes(eTranslationLocaleCode.fr)) {
    localeCode = eTranslationLocaleCode.fr;
  }

  if (store.state.currUser && store.state.currUser.settings.locale === eTranslationLocaleCode.notSet) {
    saveSettings({
      locale: localeCode,
    });
  } else {
    store.commit('locale', localeCode);
  }
};
