export default {
  zh: {},
  es: {},
  hi: {},
  ar: {},
  bn: {},
  pt: {},
  ru: {},
  ja: {},
  pa: {},
  it: {},
  de: {},
  fr: {},
  en: {
    signIn: `Sign In`,
    signOut: `Sign Out`,
    newSitch: `New Sitch`,
    modeSaved: `Sitch successfully saved.`,
    nameAlreadyInUse: `Name already in use.`,
    genericDeleteSuccessful: `Delete successful.`,
    modeDeleted: `Sitch successfully deleted.`,
    modeDeletedAndDeviceInactive: `Sitch successfully deleted. If you have a Sitch Device, it will be inactive until a new Sitch is created.`,
    modeCopied: `Sitch successfully copied.`,
    website: `Website`,
    brandUrl: `{0} URL`,
    brandHandle: `{0} Handle`,
    selectSocials: `Select what socials to show`,
    sitchName: `Sitch Name`,
    sitchType: `Sitch Type`,
    email: `Email`,
    phone: `Phone`,
    size: 'Size',
    workPhone: `Work Phone`,
    termsOfService: `Terms of Service`,
    privacyPolicy: `Privacy Policy`,
    loginDisclaimer: `By continuing, you are indicating that you accept our Terms of Service and Privacy Policy.`,
    sitchDevices: `Sitch Devices`,
    dashboard: `Dashboard`,
    modeDisclaimer: `Select a Sitch to make it your current`,
    qrCode: `QR Code`,
    contact: `Contact Us`,
    location: 'Location',
    moveLeft: `Move Left`,
    edit: `Edit`,
    copy: `Copy`,
    delete: `Delete`,
    cardStyle: 'Card Style',
    addressLine2ForForm: `Unit/Apartment/Suite/Other`,
    moveRight: `Move Right`,
    confirmDelete: `Are you sure want to delete this Sitch? It cannot be recovered once deleted. All data associated to this Sitch will be deleted as well.`,
    confirmDeleteGeneric: `Are you sure want to delete this {0}? It cannot be recovered once deleted.`,
    confirmation: `Confirmation`,
    isRequired: `Is required`,
    cannotUpload: `Cannot upload that file`,
    mustUploadAValidFile: `Must upload a valid file`,
    fileTooLarge: `File too large`,
    yourDeviceHasBeenLinked: `Your device has been linked to your account.`,
    cannotLinkAccountDatabaseError: `Cannot link the Sitch Device. There was an error on our end. Try again later.`,
    cannotLinkAccountAlreadyActivated: `Cannot link the Sitch Device. This code was already linked to an account.`,
    cannotLinkAccountIdNotValid: `Cannot link the Sitch Device. The code is invalid.`,
    optional: `{0} - Optional`,
    deleteTheme: `Delete Theme`,
    message: `Message`,
    submit: `Submit`,
    havingTroubleWithThisStep: 'Having trouble with this step?',
    contactUs: `Contact Us`,
    profilePicture: `Profile Picture`,
    uploadImage: `Upload Image`,
    replace: `Replace`,
    name: `Name`,
    formErrors: `There are errors in the form.`,
    updateNow: `Update Now`,
    update: `Update`,
    updateDialogTitle: `Update Ready`,
    updateDialogText: `A new version of the app is available. A momentary page refresh will bring you latest features and bug fixes! We strongly encourage you to update.`,
    menu: `Menu`,
    back: `Back`,
    save: `Save`,
    uploadFiles: `Upload Files`,
    profile: `Profile`,
    urlRedirect: `Redirect`,
    article: `Article`,
    text: `Text`,
    files: `Files`,
    deleteSelected: `Delete Selected`,
    personalPayment: `Personal Payment`,
    businessPayment: `Business Payment`,
    personalPaymentForMenu: `Personal`,
    businessPaymentForMenu: `Business`,
    redirectUrl: `Redirect URL`,
    amountInfo: `It is optional to set this now. Everytime you select this Sitch from the dashboard you will be able to specify the amount.`,
    paymentAmountInfo: `How much should the recipent pay?`,
    subheading: `Sub Heading`,
    okay: `Okay`,
    cancel: `Cancel`,
    cancelBooking: 'Cancel Booking',
    notice: 'Notice',
    noticeTime: 'Notice Time',
    timeStepsHint: 'The intervals people can book at. For example, 15 minute intervals for 30 minute long bookings might look like: 4:15, 4:45: 5:15 etc.',
    buyCard: `Buy Sitch Card`,
    payWithCard: `Pay with card`,
    payWithDigitalWalletOption: `Press here to pay with a digital wallet (Google Pay, Apple Pay etc).`,
    payWithCardOption: `Or press here to enter your credit card information.`,
    modeLink: `Smart Link`,
    modeLinkActive: `Your Smart Link is active. You can now either use your Sitch Device or other NFC/QR products to deliver your active Sitch. To do this, program an NFC tag or QR code with the url below.`,
    modeLinkInactive: `Your Smart Link is not yet active. When active you can use a Sitch Device or other NFC/QR products to deliver your active Sitch. To activate it, purchase a Sitch Device and activate it with your phone.`,
    purchaseSitchDevice: `Purchase Sitch Device`,
    wifiLabel: `SSID (WiFi name)`,
    wifiPassword: `WiFi Password`,
    wifi: `WiFi`,
    wifiEncryption: `Wifi Encryption Type`,
    wifiEncryptionInfo: `The type of WiFi security your router uses. You can usually leave this as WPA`,
    permalink: `Custom link`,
    createPermalink: `Create custom link`,
    permalinkCreated: `Custom link successfully created.`,
    deletePermalink: `Delete custom link`,
    urlSafeChars: `Must use URL safe characters`,
    mustBeValidEmail: `Must be a valid email`,
    mustBeValidUrl: `Must be a valid URL`,
    mustBeValidPhoneNumber: `Must be a valid phone number`,
    mustBeLessThanOrEqualTo: `Maximum {0} characters`,
    mustBeGreaterThan: `Must be greater than {0}`,
    mustBeGreaterThanCharacters: `Must be greater than {0} characters`,
    permalinkDeleteConfirmation: `Are you sure you want to delete this custom link? Once it's deleted all {0} links will stop working!`,
    permalinkDeleted: `Custom link succesfully deleted.`,
    yourCurrentPermalink: `The custom link for this Sitch is:`,
    landingPage: `Landing Page`,
    landingPageInfo: `Select the Sitch that vistors will see as the first page of your site. Cannot be a redirect Sitch.`,
    pages: `Pages`,
    yourLinkForThisSitch: `The link for this Sitch is:`,
    permalinkHint: `You can create a custom link to this Sitch by entering a name here. For example, if you entered "mysitch" then you could use sitch.app/mysitch to link people to this Sitch.`,
    site: `Site`,
    mustSelectLandingPageModeId: `Must select a landing page.`,
    help: `Help`,
    account: `Account`,
    accountSettings: `Account Settings`,
    contactUsInfo: `Send us your questions, requests, or feedback`,
    addCallToActionButton: `Add a call to action button`,
    callToActionInfo: `Want to prompt the viewer to book an appointment, make a donation, or something else? Turn this on and provide a button label and url to redirect the viewers to.`,
    callToActionButtonLabel: `Button Label`,
    callToActionButtonUrl: `Redirect URL`,
    callToActionButtonLabelHint: `An example of a call to action button label might be: 'Donate Now'`,
    callToActionButtonUrlHint: `URL to send viewers to. If it were a Patreon it would be something like: 'patreon.com/your-cause'.`,
    linkList: `Link List`,
    orUseAMode: 'Or Use a Sitch',
    linkTitle: `Title`,
    linkDescription: `Description`,
    linkUrl: `URL`,
    addLink: `Add Link`,
    addSection: `Add Section`,
    removeLink: `Remove Link`,
    shop: `Shop`,
    description: `Description`,
    url: `URL`,
    invoiceUrl: `Invoice URL`,
    invoicePdf: `Invoice PDF`,
    phoneNumber: 'Phone Number',
    addItem: `Add Item`,
    removeItem: `Remove Item`,
    group: `Group`,
    price: `Price`,
    title: `Title`,
    other: 'Other',
    sections: 'Sections',
    shouldNotificationSoundRepeat: 'Repeat notification sound on data pages',
    enablePaypal: `Enable Paypal`,
    enablePaypalInfo: `If you want to take payments via PayPal from other PayPal accounts.`,
    paypalMeUrl: `PayPal Me URL`,
    paypalMeInfo: `For accepting payments via a PayPal Me link. Sign up at <a target="_blank" href="https://www.paypal.me/">www.paypal.me</a> and put the provided paypal.me link in the field below. An example paypal.me link would look like this: https://www.paypal.me/myuniqueid. For fee information see <a href="https://www.paypal.com/ca/for-you/fees">here</a>.`,
    mustEnableAtLeastOnePaymentMethod: `Must enable at least one payment method`,
    contactMessageDefault: `For any inquiries, feel free to reach out.`,
    mustBeAllLowercase: `Custom links must be all lowercase.`,
    mustSelectAnEncryptionType: `Must select an encryption type.`,
    articleDescription: `A Sitch for sharing a text article with optional images.`,
    businessPaymentDescription: `For taking business payments via Stripe. Has taxation and tipping options.`,
    filesDescription: `For sharing downloadable files.`, // Can be locked with a password.
    wifiDescription: `For sharing WiFi. Select the Sitch and let people scan your in app QR code to share WiFi.`,
    groupDescription: `For stacking other Sitches on top of each other in a single page.`,
    shopDescription: `For selling products or services in a retail manner.`,
    siteDescription: `Combine two or more Sitches into a website with a menu to switch between them.`,
    standardModesDescription: `These are for showing your own content, like a business card (Profile Sitch).`,
    combiningModesDescription: `These Sitches combine many into one. They can even be used in combination with each other.`,
    qrModesDescription: `These Sitches work best when you use your in app QR code on the "QR Code" page.`,
    qrCodePageInfo: `Use this QR code or your Sitch Device to share your active Sitch. You can change your active Sitch on the Sitches page.`,
    seeHowItWorks: `See how it works`,
    sitchPremiumdPaymentSucceeded: `Payment Successful. Sitch Premium has been activated.`,
    sitchPremiumdPaymentCancelled: `Payment Unsuccessful. If you still wish to subscribe for Sitch Premium, try purchasing again.`,
    quantity: `Quantity`,
    messageInfo: 'An optional message to display at the top of the Sitch.',
    sitchPremium: `Sitch Premium`,
    wantACustomLink: `Want a custom link?`,
    displayName: `Display Name`,
    html: `HTML`,
    htmlDescription: `For people that know HTML/CSS/JavaScript! Write some code and display it as a Sitch.`,
    customModesDescription: `For building very custom Sitches from scratch.`,
    dragAndDrop: `Editor`,
    dragAndDropDescription: `A drag and drop webpage builder. Use on desktop for a much more comfortable experience.`,
    sitchCam: `Sitch Cam`,
    pressHereToOpenYourCamera: `Press here to open your camera`,
    sitchCamDefaultSitchName: `Sitch Cam`,
    standard: `Standard`,
    maxModes: `Maximum number of Sitches`,
    maxCustomLinks: `Maximum number of custom links`,
    maxFileUploadSize: `Max file upload size`,
    maxStorage: `Maximum storage`,
    purchase: `Purchase`,
    activatePremium: `Activate Premium`,
    reactivatePremium: `Reactivate Premium`,
    deactivatePremium: `Deactivate Premium`,
    sitchPremiumActiveUntil: `Sitch Premium has been cancelled, but it will remain active until {0}. You can resubscribe for just US$5 a month. No tax.`,
    sitchPremiumActive: `Sitch Premium is active. Next billing period starts on {0}.`,
    sitchPremiumInactive: `Sitch Premium is not active.`,
    settings: `Settings`,
    stripeAccountConnected: `Stripe account connected.`,
    connectNewStripeAccount: `Connect New Stripe Account`,
    standardModes: `Content Sitches`,
    combiningModes: `Combining Sitches`,
    qrModes: `QR Sitches`,
    customModes: `Custom Sitches`,
    paymentModes: `Payment Sitches`,
    time: 'Time',
    getPaid: `Get Paid`,
    whatIsStripe: 'What is Stripe?',
    whatIsStripeInfo: `Stripe is a service that enables credit card payments online. All you need to do to create a Stripe account is enter some information about your business and where you want your money deposited. Stripe are amongst the largest online payment processors. You can learn more about Stripe at <a target="_blank" href="https://stripe.com/">stripe.com</a>. Press the "Connect New Stripe Account" button to begin the process so you can start getting paid with Stripe enabled Sitches.`,
    showTipOptions: `Show tip options`,
    showTipOptionsInfo: `Enable this if you want to allow customers to tip you.`,
    mustBeGreaterThanNumber: `Must be greater than {0}`,
    mustBeGreaterThanOrEqualToNumber: `Must be greater than or equal to {0}`,
    mustBeZeorOrGreaterThanOrEqualToNumber: `Must be 0 or greater than or equal to {0}`,
    mustBeLessThanOrEqualToNumber: `Maximum {0}`,
    chargeTax: `Charge tax?`,
    chargeTaxInfo: `You must charge tax if you will be using this Sitch for business purposes.`,
    taxRate: `Sales Tax Rate %`,
    taxRateInfo: `How much sales tax you want to charge for purchases with this Sitch. For example, if you have a 10 percent sales tax in your area you would enter "10".`,
    defaultTaxRate: `Default Sales Tax Rate %`,
    defaultTaxRateInfo: `The percentage default sales tax rate for purchased goods or services in your area. For example if you have a 10 percent sales tax in your area you would enter "10". If certain items in your shop have different tax rates then you can specify tax rates per item below. Otherwise this value will be used. Can be zero.`,
    connectedStripeAccounts: `Connected Stripe Accounts`,
    currency: `Currency`,
    stripeAccountSelectionInfo: `Select the Stripe account you want transactions on this Sitch to be paid to.`,
    stripeAccount: `Stripe Account`,
    testInIframe: 'Test as Site Page',
    putInIframe: `Make redirect appear as a webpage on Site Sitches`,
    putInIframeInfo: `When added to a Site Sitch this will make the redirect appear as a page in your Site Sitch, but it does not work with all websites. After checking this, press the "Test as Site Page" button that appears to see if it works. If it does, the website will load in the box that appears. If nothing loads, untick this box.`,
    updateAmount: `Update Amount`,
    shopItemMax: `Cannot have more than {0} shop items in a single Shop Sitch.`,
    filesMax: `Cannot have more than {0} files in a single Files Sitch.`,
    modifierMax: `Cannot have more than {0} modifiers for a single shop item.`,
    galleryItemMax: `Cannot have more than {0} images in a single Gallery Sitch.`,
    linkItemMax: `Cannot have more than {0} links in a single Link List Sitch.`,
    sectionItemMax: `Cannot have more than {0} sections in a single Accordion Sitch.`,
    blogItemMax: `Cannot have more than {0} posts in a single Blog Sitch.`,
    eventItemMax: `Cannot have more than {0} events in a single Event Sitch.`,
    timeSlotMax: `Cannot have more than {0} availability time slots in a single Booking Sitch.`,
    genericItemMax: `Cannot have more than {0}`,
    updateAccountInfo: `Update Account Info`,
    updatePassword: `Update Password`,
    password: `Password`,
    confirmPassword: `Confirm Password`,
    updateSuccessful: `Update Successful`,
    accountInfo: `Account Info`,
    payments: `Payments`,
    noPaymentRecords: `No payment records`,
    refundHint: `You can refund a minimum of {0} and a maximum of {1} on this payment.`,
    refundedAlreadyHint: `You have already refunded the maximum amount for this transaction.`,
    totalForToday: `Total For Today`,
    totalTipsForToday: `Total Tips For Today`,
    more: `More`,
    stripeId: `Stripe ID`,
    receivedAt: `Received`,
    tips: `Tips`,
    numberOfPayments: `Number Of Payments`,
    paymentDetails: `Payment Details`,
    tip: `Tip`,
    actions: 'Actions',
    chargeStatus: `Charge Status`,
    succeeded: `Succeeded`,
    issueRefund: `Issue Refund`,
    viewInStripeInfo: `You can view this payment in Stripe to see additional details. You must be signed in with the connected Stripe account on Stripe to do so.`,
    viewInStripe: `View in Stripe`,
    refundAmount: `Refund Amount`,
    refundSuccessful: `The refund was successful. The customer will be notified via email.`,
    refundUnsuccessful: `The refund was not successful, use the Stripe dashboard to issue the refund instead.`,
    refundConfirmationMessage: `Are you sure you want to issue a refund on this transaction for {0}?`,
    orderBreakdown: `Order Breakdown`,
    stripeAccountSelectionInfoForPaymentsPage: `Select the Stripe account that you want to see stats for.`,
    paymentsForCurrencyChart: `{0} Payments`,
    tipsForCurrencyChart: `{0} Tips`,
    storageLimitStandard: `Could not upload a file. Storage limit exceeded for this tier. Upgrade to Stich Premium for more storage`,
    storageLimitPremium: `Could not upload a file. Storage limit exceeded.`,
    uploadLimitStandard: `Could not upload a file. Upload size limit exceeded for this tier. Upgrade to Stich Premium for a larger upload maximum`,
    uploadLimitPremium: `Could not upload a file. Upload size limit exceeded.`,
    customTheming: `Advanced theming options`,
    customMenuLogo: `Custom menu logo for Site Sitches`,
    customLinkLimitPremium: `Cannot create anymore custom links.`,
    customLinkLimitStandard: `Cannot create anymore custom links at this tier. Upgrade to Stich Premium to be able to create more.`,
    preview: `Preview`,
    checkIfThisWorksInAnIframe: `Check if this works as a page on my site`,
    removalOfPoweredByMessage: `Remove "Powered By Sitch" message and site menu logo`,
    doYouWantToLinkThisDevice: `Do you want to link this device to your account?`,
    requiresDeliveryAddress: `Require delivery address`,
    requiresDeliveryAddressInfo: `Enable this if you need to get the customers delivery address. If you need a shop for in-person sales and one for online sales, we recommend making two different Shop Sitches.`,
    additionalAddressDetails: `Additional Address Details (buzzer number, location oddities, etc.)`,
    customerEmail: `Customer Email`,
    notApplicable: `N/A`,
    thisPasswordWillBeRevealed: `This password will be revealed to people who access this Stich unless you use the in-app QR code to connect guests.`,
    gallery: `Gallery`,
    galleryDescription: `For sharing a collection of photos with optional hyperlinking.`,
    enableGalleryItemNames: `Show item titles underneath images.`,
    dateRange: `Date Range`,
    paymentsDateRangeHint: `If no date range is specified. You will see the last 100 payments.`,
    totalRevenueBeforeTaxChargedForPeriod: `Total pretax revenue`,
    totalRevenueAfterTaxChargedForPeriod: `Total revenue`,
    totalTaxChargedForPeriod: `Total tax charged`,
    totalTipsForPeriod: `Total tips`,
    clear: `Clear`,
    moveToFolder: `Move to Folder`,
    folderName: `Folder Name`,
    newFolder: `New Folder`,
    folders: `Folders`,
    removeFromFolder: `Remove from Folder`,
    noModesInFolder: `To add a Sitch to this folder. Select it and press the "Move to Folder" button.`,
    selectFolder: `Select Folder`,
    analytics: `Analytics`,
    deleteFolder: `Delete Folder`,
    modeOptions: `Sitch Options`,
    folderOptions: `Folder Options`,
    viewCounts: `View Counts`,
    viewCountsByDayOfWeek: `Day of Week View Counts`,
    viewCountsByHourOfDay: `Hour of Day View Counts`,
    highestViewCounts: `Hightest View Counts (of top 10 Sitches)`,
    modes: `Sitches`,
    viewCount: `View Count`,
    sunday: `Sunday`,
    monday: `Monday`,
    tuesday: `Tuesday`,
    wednesday: `Wednesday`,
    thursday: `Thursday`,
    friday: `Friday`,
    saturday: `Saturday`,
    month: 'Month',
    week: 'Week',
    day: 'Day',
    stack: 'Stack',
    column: 'Column',
    fourDay: '4 day',
    noAnalytics: `No analytics yet`,
    analyticsByMode: `Analytics by Sitch`,
    analyticsFor: `{0} Analytics`,
    hourViewCounts: `By Hour View Counts`,
    dayViewCounts: `By Day View Counts`,
    monthViewCounts: `By Month View Counts`,
    interactions: `Interactions`,
    viewPaymentDataHere: `You can view payment data from Business Payment and Shop Sitches here.`,
    lettersAndNumbersOnly: `Letters and numbers only.`,
    discountPercentage: `Discount %`,
    addPromoCode: `Add Promo Code`,
    code: `Code`,
    primaryValue: 'Primary Value',
    promoCodeMax: `Cannot have more than {0} promo codes`,
    enableInterac: `Enable Interac e-Transfer`,
    enableInteracInfo: `If you want to take payments via Interac e-Transfer.`,
    interacInfo: `For accepting payments via Interac e-Transfer. Enter your email address for e-Transfer, and a preferred password (if applicable).`,
    interacEmail: `Email address for e-Transfer`,
    interacPhoneNumber: `Phone number for e-Transfer`,
    enterAnInteracPhoneNumberOrEmail: 'Enter an Interac phone number or email.',
    interacPassword: `e-Transfer Password`,
    mustBeValidETransferPassword: `Password must be 3-25 characters, with no spaces or special characters.`,
    data: `Data`,
    promoCodeUsed: `Promo Code Used`,
    discountAmount: `Discount Amount`,
    deliveryAddress: `Delivery Address`,
    addModifier: `Add Option`,
    amountChange: `Price Change`,
    amountChangeHint: `How much this option adds to or subtracts from the base price per quantity. Can be positive or negative.`,
    hasStock: `Limited Stock`,
    hasStockInfo: `Enable this if you want this item to be marked as "Sold Out" when you run out of stock`,
    shopItems: `Shop Items`,
    promoCodes: `Promo Codes`,
    stock: `Stock Count`,
    shopItem: `Shop Item`,
    checkoutCustomer: `Checkout Customer`,
    checkoutCustomerInfo: `You can preselect what the customer is buying before giving them the Sitch to pay with. Just select from the items below to add them to the checkout order. To skip this option press "Continue".`,
    clearCheckoutInfo: `To clear the checkout order breakdown and allow the customer to make their own selections press the "Clear Checkout" button. Otherwise press the "Continue" button if you would like to check them out with the order breakdown shown below.`,
    removeModifierGroup: `Remove Option Group`,
    addModifierGroup: `Add Option Group`,
    modifierGroup: `Option Group`,
    modifiersHint: `An Option Group is a list of options that a user can choose from to satisfy a choice requirement that this item may have. For example, an option might be named "Size" and contain options with labels like "Small", "Medium", "Large", etc. Options can be anything! Beyond an option's label, you can also specify if an option adds to a item's price, and you can also specify whether a selection from an option is required before the item can be purchased.`,
    canPickDistinct: `Based on the above parameters. The user can pick up to {0} option(s), and each option must be distinct.`,
    mustPickDistinct: `Based on the above parameters. The user must pick exactly {0} option(s), and each option must be distinct.`,
    canPickHoweverManyDistict: `Based on the above parameters. The user can pick as many options as they would like, and each option must be distinct.`,
    canPickNotDistinct: `Based on the above parameters. The user can pick up to {0} option(s), and unique options can be picked multiple times.`,
    mustPickNotDistinct: `Based on the above parameters. The user must pick exactly {0} option(s), and unique options can be picked multiple times.`,
    canPickHoweverManyNotDistict: `Based on the above parameters. The user can pick as many options as they would like, and unique options can be picked multiple times.`,
    modifierGroupName: `Option Group Name`,
    groupNameHint: `For example, if you picked "Size" as a group name, then when the user chooses this menu item, they will be prompted to "Choose a Size" from this list`,
    isSoldOut: `Sold Out`,
    canSelectMultipleOfTheSameModifier: `Can select multiple of the same option.`,
    mustPickExactAmount: `Must pick the specified number of selections exactly.`,
    canPickAsManyAsTheyWant: `Can pick as many as they want.`,
    modifierLabel: `Option Label`,
    numberOfSelections: `Number of Selections`,
    uploadImages: `Upload Images`,
    editImages: `Edit Images`,
    imageAdded: `Image Added`,
    add: `Add`,
    done: `Done`,
    deletedImage: `Will be deleted on save`,
    undelete: `Undo Delete`,
    clearCheckout: `Clear Checkout`,
    choose: `Choose {0}.`,
    mustChoose: `Must choose {0} from the "{1}" section.`,
    mustChooseOrOptOut: `Must choose {0} from the "{1}" section or select the {2} option.`,
    chooseUpTo: `Choose up to {0}.`,
    asManyAsYoudLike: `Choose as many as you'd like.`,
    multipleOfSingleModifier: `Repeatedly select an item to increase the quantity.`,
    modifierSoldOut: `This option is sold out.`,
    canOnlySelectMaxModifiers: `Can only select {0}. Unselect other options to pick this one.`,
    addToCart: `Add to Cart`,
    enterSpecialRequestHere: 'Enter special request here.',
    addSpecialRequest: `Add Special Request`,
    ageRequirement: `Age Requirement`,
    areYouOverYearsOld: `Are you over {0} years old? You must be at least {0} years old to buy this.`,
    tooYoungToOrder: `You are too young to buy this item`,
    IDontWantAny: `None`,
    IJustWant: `I just want {0}`,
    itemOptions: `Item Options`,
    addedToYourOrder: `{0}x {1} added to checkout order.`,
    removeAll: `Remove All`,
    removeOne: `Remove One`,
    continue: `Continue`,
    addEvent: `Add Event`,
    scheduleModes: `Schedule Sitches`,
    scheduleModesDescription: `Sitches for upcoming events, appointments, and reservations.`,
    booking: `Booking`,
    bookingDescription: `For letting people book appointments or reservations.`,
    events: `Events`,
    eventsDescription: `For showing your upcoming events and letting people easily add them to their calendars.`,
    startTime: `Start Time`,
    hours: `Hours`,
    minutes: `Minutes`,
    eventDate: `Event Date`,
    enterTheAddress: `Start typing`,
    addressForTheEvent: `Event Address - Optional`,
    makeSureToSelectAnOptionFromTheDropdown: `Make sure to select an option from the dropdown once you begin typing an address.`,
    dragToRedorder: `Hold and drag to reorder items`,
    availabilityHint: `Add your weekly availability time slots here. Availability is when people can book for. For each availability pick the hours of the day and the weekdays those hours apply. Your overall availability will be all of these availabilities merged together.`,
    maxBookingsPerDay: `Max Bookings per Day`,
    bufferTimeBeforeInMinutes: `Buffer Time Before Booking (Minutes)`,
    bufferTimeBeforeInMinutesHint: `Time in minutes needed before a booking to prepare.`,
    bufferTimeAfterInMinutes: `Buffer Time After Booking (Minutes)`,
    bufferTimeAfterInMinutesHint: `Time in minutes needed after a booking to relax or clean up.`,
    minimumSchedulingNoticeInMinutes: `Minimum Scheduling Notice (Minutes)`,
    minimumSchedulingNoticeInMinutesHint: `Minimum time in minutes that a customer can book in advance. 1440 minutes is 24 hours.`,
    maximumSchedulingNoticeInDays: `Maximum Scheduling Notice. (Days)`,
    maximumSchedulingNoticeInDaysHint: `Maximum time in days that a customer can book in advance.`,
    maxSimultaneousBookingsForSameSlot: `Maximum Simultaneous Bookings`,
    maxSimultaneousBookingsForSameSlotHint: `Maximum number of bookings that can occupy the same time slot. For example, if you can service at most two bookings at the same you can enter "2".`,
    durationPerBooking: 'Duration per Booking',
    durationPerBookingHoursHint: `How long a booked session typically takes. This determines the length of the event when this booking is added to a calander.`,
    durationPerBookingMinutesHint: `How long a booked session typically takes. This determines the length of the event when this booking is added to a calander.`,
    timeSteps: `Allowed Booking Intervals in Minutes`,
    availabilities: `Availabilities`,
    addAvailability: `Add Availability`,
    timeOfDayStart: `Time of Day Start`,
    timeOfDayEnd: `Time of Day End`,
    weekdays: `Weekdays`,
    startOfDayCannotBeLaterThanEndOfDay: `Start of the day cannot be later than the end of the day`,
    fullAvailability: `Availability Calendar`,
    deposits: `Get Paid`,
    closureDates: `Closure Dates`,
    addClosureDate: 'Add Closure Date',
    organizationName: `Organization Name`,
    organizationNameHint: `This is the name of the organization handling the bookings. The booking confirmation will say "This is to confirm your booking with [Organization Name]".`,
    customLinkUnavailable: `That custom link is unavailable.`,
    formModes: `Form Sitches`,
    formModesDescription: `Sitches for people to input and submit information or messages via forms.`,
    requireName: `Require name`,
    requireNameInfo: `If you need to collect a name on the form.`,
    requireEmail: `Require email`,
    requireEmailInfo: `If you need to collect email on the form.`,
    requirePhoneNumber: `Require phone number`,
    requirePhoneNumberInfo: `If you need to collect a phone number on the form.`,
    requireId: `Require ID`,
    requireIdInfo: `If the people filling out the form are employees, students, or some other internal group, then enable this if you want them to put in some kind of ID or pass code. It can be letters or numbers and correspond to student IDs, badge IDs or anything else.`,
    requireScreeningQuestionnaire: `Require screening questionnaire`,
    requireScreeningQuestionnaireInfo: `Enable this if you want the people filling out this form to pass a COVID-19 symptom questionnaire.`,
    id: `ID`,
    questionnairePassed: `Questionnaire Passed`,
    noSubmissions: `No Submissions`,
    submissionDateRangeHint: `If no date range is specified. You will see the last 100 submissions.`,
    submittedAt: `Submitted At`,
    submissionDetails: `Submission Details`,
    status: `Status`,
    orderStatus: `Order Status`,
    customForms: `Forms`,
    customForm: 'Form',
    forms: 'Forms',
    youCanAddSubmissionFormsNameEmailNumber:
      'Need to collect additional information beyond just name, email, and phone number? You can add submission forms by creating Custom Form Sitches and choosing them here.',
    youCanAddSubmissionFormsEmail: 'Need to collect additional information beyond just an email? You can add submission forms by creating Custom Form Sitches and adding them here.',
    preSubmissionForm: 'Pre-Submission Form',
    preSubmissionFormInfo: 'You can add a Custom Form Sitch as a pre-submission form to collect additional essential information.',
    postSubmissionForm: 'Post-Submission Form',
    postSubmissionFormInfo: 'You can add a Custom Form Sitch as a post-submission form to collect optional feedback.',
    customFormDescription: `A Sitch to that allows people to send you relevant info via custom forms.`,
    confirmDeleteSubmission: `Are you sure you wish to delete this submission? This cannot be undone.`,
    chooseAnCustomFormMode: `Choose a Form Sitch`,
    customFormSubmissions: `Form Submissions`,
    noModesOfThisTypeExistYet: `No Sitches of this type exist yet.`,
    messages: `Messages`,
    bookings: `Bookings`,
    chooseABookingMode: `Choose a Booking Sitch`,
    appointmentBooked: `Appointment Booked`,
    slot: `Slot`,
    bookingNumber: `Booking {0}`,
    cancellationUnsuccessful: `Could not cancel the appointment. It may have already been cancelled. Try refreshing and trying again.`,
    cancellationSuccessful: 'Cancellation successful.',
    confirmBookingDeletion: `Are you sure you want to cancel this booking? If you cancel it the person that made the booking will be notified of the cancellation.`,
    addToACalendar: `Add to a Calendar`,
    validStripeAccountRequired: `Valid Stripe account required. You can create a new one by pressing the "Connect New Stripe Account button".`,
    refreshList: 'Refresh List',
    stripeAccountDialogInfo: `Update your support contact information here. This is what people will see in their receipts after making payments to you using a Business Payment Sitch or Shop Sitch.`,
    deleteFolderConfirmationMessage: `Are you sure you want to delete this folder? All Sitches in the folder will be removed from the folder.`,
    thisModeWorksBestWithQRCodes: `WiFi is most easily shared with the in app QR code. Show the QR code now?`,
    showLocationWarning: `Show Location Warning`,
    showLocationWarningHint: `If this is turned on then staff will be notified when a users location has not been verified when making a tableside order.`,
    copiedToClipboard: `Copied to clipboard`,
    passwordUpdated: `Password updated successfully.`,
    areYouSureYouWantToDeleteThisStripeAccount:
      'Are you sure you want to delete this Stripe account? You will only be able to access your payments information for this account on Stripe unless the account is reconnected here.',
    noAnalyticsYet: `Analytics will be available once others interact with your Sitches.`,
    removeAccount: 'Remove Account',
    lowModeCountCombiningOptionsMsg: `Create at least two Sitches to enable these options.`,
    shortcuts: `Shortcuts`,
    stripeAccountRemoved: 'Stripe account was removed successfully.',
    noModesExistYetForShortcuts: `You need to create a Sitch before you can set one as a shortcut.`,
    selectQuickMode: `Select Quick Sitch {0}`,
    shortcutsInfo: `After Sitch is installed to your desktop on Android, Windows, or MacOS, you can make shortcuts for commonly used Sitches. You can then pick from these shortcuts by long pressing the Sitch icon on Android or right-clicking on Windows/MacOS.`,
    currentMode: `Current Sitch`,
    youHaveNotSetThisShortcut: `You have not assigned a Sitch to this shortcut. To do so, go to the <span class="highlight">Shortcuts</span> page under <span class="highlight">Settings</span>. Want to go there now?`,
    learnMore: `Learn More`,
    affiliateProgram: `Affliate Program`,
    applyForAffiliateProgram: `Apply for Affiliate Program`,
    shortText: `Short Text`,
    longText: `Long Text`,
    number: `Number`,
    cloneSitch: 'Clone Sitch',
    date: `Date`,
    checkbox: `Checkbox`,
    noStripeAccountSelected: 'No Stripe account selected.',
    notProvided: `Not provided`,
    themes: `Themes`,
    newTheme: `New Theme`,
    editTheme: `Edit Theme`,
    backgroundColor: `Background Color`,
    accentColor: `Accent Color`,
    checkOutAvailableFonts: `Check out the available fonts here:`,
    primaryFontVariant: `Primary Font Variant`,
    primaryFontScaling: `Primary Font Scaling`,
    headerFontVariant: `Header Font Variant`,
    headerFontScaling: `Header Font Scaling`,
    primaryFont: `Primary Font`,
    primaryFontHint: `Used for paragraphs, descriptions, and text links.`,
    headerFont: `Header Font`,
    showPreview: `Show Preview`,
    mustHaveAtleastOneItem: `You have to add at least one item.`,
    cannotHaveAnEmptyModifierGroup: `The option group {0} in item {1} must have at least one option.`,
    allGalleryItemsMustHaveAnImage: `You must upload an image for {0}.`,
    cannotEditDefaultThemes: `Cannot edit default themes`,
    assignThemeToSelected: `Assign Theme to Selected`,
    selectAll: `Select All`,
    notEnoughStock: 'Not enough stock',
    deselectAll: `Deselect All`,
    themesPageInfo: `To assign a theme to your Sitches, Pick a theme from the dropdown and then select the Sitches you want to assign it to. Then press the "Assign Theme to Selected" button.`,
    modesUpdatedSuccessfully: 'Sitches updated successfully',
    checkoutSuccessMessage: 'Checkout Success Message',
    checkoutSuccessMessageHint: "If you want to display a custom message when a user completes their checkout, enter it here. An example could be 'Thank you for your purchase!'",
    fontVariant100: `100`,
    fontVariant100italic: `100 Italic`,
    fontVariant200: `200`,
    fontVariant200italic: `200 Italic`,
    fontVariant300: `300`,
    fontVariant300italic: `300 Italic`,
    fontVariantregular: `Regular`,
    fontVariantitalic: `Italic`,
    fontVariant500: `500`,
    fontVariant500italic: `500 Italic`,
    fontVariant600: `600`,
    fontVariant600italic: `600 Italic`,
    fontVariant700: `700`,
    fontVariant700italic: `700 Italic`,
    fontVariant800: `800`,
    fontVariant800italic: `800 Italic`,
    fontVariant900: `900`,
    fontVariant900italic: `900 Italic`,
    fontVariantbold: `Bold`,
    referenceId: 'Order ID',
    useModeThemePerPage: `Use Sitch's theme per page`,
    contrastColors: 'Your accent color and background color should contrast.',
    baseTheme: 'Base Preset Theme',
    themePageInfo:
      'Create themes here to assign to Sitches on the Themes page. Select a theme from the "Base Preset Theme" dropdown if you want to work off of another theme to start with. Doing this will clear the current configuration.',
    noStatus: 'No Status',
    onHold: 'On Hold',
    completed: 'Completed',
    accepted: 'Accepted',
    rejected: 'Rejected',
    dispatched: 'Dispatched',
    delivered: 'Delivered',
    readyForPickUp: 'Ready for pick-up',
    pickedUp: 'Picked up',
    paymentRecordUpdated: 'Payment record successfully updated.',
    acceptedStatusEmailInfo: 'Compose an email to notify the customer that the order has been received and can be fufilled?',
    rejectedStatusEmailInfo: 'Compose an email to notify the customer that the order has been received but CANNOT be fufilled?',
    onHoldStatusEmailInfo: 'Compose an email to notify the customer that the order has been placed on hold?',
    dispatchedStatusEmailInfo: 'Compose an email to notify the customer that the order is on the way?',
    deliveredStatusEmailInfo: 'Compose an email to notify the customer that the order has been delivered?',
    readyForPickUpStatusEmailInfo: 'Compose an email to notify the customer that the order is ready for pick-up?',
    editEmailDefaultInfo: 'You can edit the default information fed into this email by editing your linked Stripe account on the Menu > Settings > Get Paid page.',
    orderStatusInfo:
      'You only have to worry about changing order status if it works with your customer experience or logistics. For some status changes a prompt to send a tailored notification to the customer will be offered. For delivery or pick-up orders we suggest sending these emails to inform the customer of certain milestones. Every purchase and refund already results in the customer getting an email receipt.',
    composeEmail: 'Compose Email',
    customerFacingBusinessName: 'Customer Facing Name',
    language: 'Language',
    showNewPaymentAlert: 'Show new payment alert',
    alertSoundType: 'Alert Sound Type',
    repeatAlertSoundUntilHandled: 'Repeat alert sound until handled',
    newAlert: 'New {0}',
    pressAnywhereToDismiss: 'Press anywhere to dismiss this alert.',
    mustBeLessThanNumber: 'Must be less than {0}.',
    light: 'Light',
    dark: 'Dark',
    paymentSubmissionsAlert: 'Show an alert for new payments when on this page',
    submissionsAlert: 'Show an alert for new submissions when on this page',
    messageSubmissionsAlert: 'Show an alert for new messages when on this page',
    bookingSubmissionsAlert: 'Show an alert for new bookings when on this page',
    downloadImage: 'Download Image',
    cannotDragIntoFolders: `Sitches cannot be dragged into folders on mobile or MacOS. Use the "Move to Folder" button instead`,
    menuIconType: 'Menu Icon Type',
    none: 'None',
    emoji: 'Emoji',
    addEMojiBy:
      'Type in the fields below to enter your own emoji. To open the emoji keyboard on Windows press <span class="highlight">Windows key + ;</span> or on MacOS press <span class="highlight">CTRL + CMD + Space</span>.',
    willBeDeleted: 'Will be deleted on save.',
    print: 'Print',
    selectModeType: 'Select Sitch Type',
    addImage: `Add Image`,
    images: `Images`,
    links: 'Links',
    posts: 'Posts',
    compressedUploadedFilesListItemLabel: 'File {0}',
    galleryItemsListItemLabel: 'Image {0}',
    postReferencesListItemLabel: 'Post {0}',
    availabilityTimeSlotsListItemLabel: `availability {0}`,
    eventsListItemLabel: `Event {0}`,
    promoCodesListItemLabel: `Promo Code {0}`,
    linksListItemLabel: `Link {0}`,
    sectionsListItemLabel: `Section {0}`,
    shopItemListListItemLabel: `Item {0}`,
    categoriesListItemLabel: `Category {0}`,
    modifierGroupSupplant: `Option Group {0}`,
    modifier: `Option {0}`,
    type: 'Type',
    duration: 'Duration',
    shopItemsInfo: `You can add the items you want to sell in your shop here.`,
    promoCodesInfo: `Promo codes can be used to give promo code holders a discount across your entire shop. Only one can be used per checkout.`,
    postsInfo: 'Create new blog posts here.',
    imagesInfo: 'Add images for your gallery here.',
    sectionsInfo: 'Add the accordion sections here',
    pressAndDrag: 'Hold and drag to reorder',
    blog: 'Blog',
    blogDescription: 'A Sitch to drop your latest thoughts and updates.',
    subTitle: 'Sub Title',
    author: 'Author',
    editorSize: 'Editor Size',
    goLiveDate: 'Go Live Date',
    goLiveTime: 'Go Live Time',
    goLiveHint: 'Time and date this post should become viewable. Save this post as a draft by setting a distant <span class="highlight">Go Live Date.</span>',
    editBody: 'Edit Body',
    body: 'Body',
    contentTooLarge: 'Your content size is too large. This is likely due big images. Try removing a few images.',
    setAsActive: 'Set as Active',
    yourFormMustHaveAtleastOneRequiredField: 'Your form must have at least one required field.',
    htmlOrJsMustBePopulated: 'Must have HTML or JavaScript',
    mustContainAtLeastOneFile: 'Must contain at least one file.',
    mustContainAtLeastOneFileForEdit: 'Must contain at least one file. If you wish to delete this Sitch, you can do so from the "Sitches" page.',
    somethingWentWrongWhenDeletingAnItem: 'Something went wrong with trying to delete an item. Try again later.',
    uploadedFiles: 'Uploaded Files',
    filesToUploadOnSave: 'Files to Upload on Save',
    promoCodesUsed: 'Promo Codes Used',
    order: 'Order',
    orderLink: 'Order Link',
    export: 'Export',
    valuesToInclude: 'Select which values to include as columns.',
    tax: 'Tax',
    exportCsvRecordsTitle: 'Records for {0}',
    bookers: 'Bookers',
    new: 'New',
    notifications: 'Notifications',
    hasUniqueTaxRate: 'Has Unique Tax Rate',
    uniqueTaxRateInfo: 'Select this if this item has a unique tax rate that is different from your default specified above.',
    passwordsDoNotMatch: 'Passwords do not match',
    aboutSitch: 'About Sitch',
    checkOutOurWebsite: 'Check out our website to learn more about how Sitch can help you',
    emailNotifications: 'Email Notifications',
    notificationsForPayments: 'New payments',
    notificationsForBookings: 'New and cancelled bookings',
    notificationsForCustomForms: 'New form submissions',
    notificationsMarketing: 'News and updates',
    webPushNotifications: 'Web Push Notifications',
    resendVerificationEmail: 'Resend Verification Email',
    wantToChangeAccount: 'Want to change accounts?',
    invalidRefundAmount: 'Invalid refund amount. Must be greater than {0} and less than {1}',
    requiresStripeAccount: 'Requires Stripe. Press here to connect an account.',
    yes: 'Yes',
    no: 'No',
    invalidValue: 'Invalid value.',
    review: 'Review',
    multipleChoice: 'Multiple Choice',
    menuLogo: 'Upload an optional menu logo.',
    upcomingBookings: 'Upcoming Bookings',
    allowSpecialRequests: 'Allow special requests',
    allowSpecialRequestsInfo: 'Enable this if you want to allow customers to write in special requests when ordering something.',
    requiresCustomerInput: 'Requires Customer Input',
    requiresCustomerInputInfo:
      'Enable this if you need the customer to add some info when they pick this option. For example, if this option were to add a message on top of a birthday cake you could enable this and put "Birthday cake message" as the label',
    customerInputLabel: 'Customer Input Label',
    newPayment: 'New payment received',
    newFormSubmission: 'New Form Submission',
    newMessage: 'New Message',
    newBooking: 'New Booking',
    addChoice: 'Add Choice',
    emailForReceivingNotifications: 'Email to notify for new submissions',
    doNotNotify: 'Do not notify',
    customersName: "Customer's Name",
    checksumWarning: 'Our analysis indicates that something might be wrong with this payment. Double check that the amounts make sense for what was purchased.',
    faq: `FAQ`,
    faqInfo: `Answers to common questions`,
    faqQ1: `Do I have to pay any fees for payment Sitches?`,
    faqA1: `There are no fees associated with collecting payments via a Personal Payments Sitch. All other payment Sitches that use Stripe to collect payments have a fee of 55 cents + 2.9% per transaction.`,
    faqQ2: `Where can I see information regarding payments that were made through a payment Sitch?`,
    faqA2: `To view all info regarding payments made through a payment Sitch, go to the Payments page. You'll be able to download spreadsheet friendly payment records if desired.`,
    faqQ3: `Can I use Sitch to handle payments in place of a card reader?`,
    faqA3: `Absolutely. For fast service businesses like a food truck, create a Shop Sitch, press the QR icon on the edit page for the Sitch, download and print the QR code, and affix the QR code somewhere near your checkout area. Customers who scan the code can now select what they want to purchase and orders will show up on the "Data > Payments" page after selecting the associated Stripe account. You can also check the alert option so that new orders flash on the screen and make an audible noise. Alternatively, you can checkout customers yourself by using the "Checkout Customer" option when you select the Shop Sitch and have the customer tap or scan your Sitch Card, or scan your in app QR code.`,
    faqQ4: `What kinds of analytics are captured by Sitch?`,
    faqA4: `Learn more about your engagements through Sitch. On the analytics page, you'll be able to see how many times each Sitch was viewed, how many times your links within those Sitches were pressed.`,
    faqQ5: `Do I need a Sitch Device to use Sitch?`,
    faqA5: `Nope. You can still create Sitches and share them via a link or the in app QR code. Though we do recommend getting a Sitch Device for smoother in person interactions and NFC capability.`,
    faqQ6: `Are my Sitches private?`,
    faqA6: `Until they are shared, yes. Keep in mind that Sitches can be reshared once you have shared them with someone. Also, it should be noted that there are two types of links in Sitch. Your general purpose Smart Link which will share your active Sitch. And links which are unique to specific Sitches. Any Sitch that has been set to active may potentionally be seen with anyone who has your general purpose Smart Link. Sitches can be safely considered private only if they have never been set to active, or if your general purpose link has only only been shared with trusted parties.`,
    faqQ7: `How does Sitch protect my data?`,
    faqA7: `All your personal data is encrypted, and protected by Google's security infrastucture. We do not sell personal information.`,
    faqQ8: 'Can I use multiple devices with one account?',
    faqA8: 'Yes, you can link as many Sitch Devices as you would like to a single account. You can also replace a lost device with a new one without any additional work.',
    stillNeedHelp: `Still can't find the answer to your question?`,
    onceTheEmailIsVerifiedPressButton: 'Once you have verified the email. Press the button below to start using it or save this Sitch as is and refresh the page.',
    emailIsVerified: 'I have verified the email',
    seePayments: 'See Payments',
    addNewEmail: 'Add new email',
    verifyEmail: 'Verify Email',
    emailNotYetVerified: 'Email is not yet verified',
    alreadyVerified: 'This email is already verified and can be used',
    emailToAdd: 'Email to add',
    emailForReceivingNotificationsInfo:
      'This email will receive notifications for new submissions and any other relevant notices pertaining to this Sitch, but only if the feature is enabled in notification settings.',
    verifyEmailHint: 'You will have to press the "Verify Email" button after typing the new email to use it',
    contactForm: 'Contact Form',
    contactFormDescription: 'A prebuilt Form Sitch tailored for receiving simple messages',
    reviewForm: 'Review Form',
    reviewFormDescription: 'A prebuilt Form Sitch tailored for receiving reviews on your service. ',
    howDidWeDo: 'How did we do?',
    generalFeedback: 'General feedback',
    view: 'View',
    download: 'Download',
    categories: 'Categories',
    categoriesInfo: 'If you have a lot of shop items, you can split them up into categories.',
    addCategory: 'Add Category',
    noneSelected: 'None selected',
    minValue: 'Must be at least {0}.',
    maxValue: 'Cannot be more than {0}.',
    chargeAmount: 'Current Charge Amount',
    chargeAmountInfo: 'How much you are currently charging people that open this Sitch. To adjust this on the fly, select this Sitch on the Sitches page and press "Set Amount"',
    iframeMinHeight: 'iFrame Min Height',
    iframeMinHeightInfo: 'The minimum height for this Sitch when used in a Group Sitch. Must be a CSS value. Examples would be "100px" "50vh" etc.',
    iframeMaxWidth: 'iFrame Max Width',
    iframeMaxWidthInfo: 'The maximum width for this Sitch when used in a Group Sitch. Must be a CSS value. Examples would be "100px" "50vw" etc.',
    requireNameInfoPayments:
      'Enable this if you would like the customer to provide their name for your records. Only enable this if you need it. Customers can be apprehensive about providing personal data.',
    thingRequired: '{0} required.',
    clearPreviousCheckout: 'Clear Previous Checkout',
    soldOut: 'Sold Out',
    hideIcons: 'Hide icons',
    guestForm: 'Guestbook Form',
    hideIconsInfo: 'Enable this if you want to hide the file type icons and preview images.',
    scrollRightForActions: 'Scroll right on the table below to see everything',
    includeFixedAmountOfForQr: 'Has permanent charge amount.',
    includeFixedAmountInfoForQr: 'Enable this if the amount you charge using this QR code will not change. The current set amount will be fixed and not change if you edit the Sitch.',
    includeFixedAmountOfForUrl: 'Include fixed charge amount of {0} in link',
    includeFixedAmountInfoForUrl:
      'Enable this if the amount you charge using this link will not change. If you use the fixed charge link, the current set amount will not change if you update the Sitch.',
    primaryFocus: 'What is your primary focus?',
    freelancerInfo: `<li>Make a <span class="highlight">Profile Sitch</span> to describe yourself or your business.</li><li>Make a <span class="highlight">Business Payment or Shop Sitch</span> to charge clients.</li><li>Make a <span class="highlight">Gallery Sitch</span> or a <span class="highlight">Files Sitch</span> if you want to show off your work.</li><li>Make a <span class="highlight">Booking Sitch</span> to let people book appointments with you.</li><li>Make a <span class="highlight">Contact Form Sitch</span> so that potential clients can quickly send you a message.</li><li>Make a <span class="highlight">Group Sitch</span> or a <span class="highlight">Site Sitch</span> to tie it all together in a single Sitch so that clients get all of the above in a single tap, click, or scan.</li>`,
    salesperson: 'Salesperson',
    salespersonInfo: `<li>Make a <span class="highlight">Profile Sitch</span> to describe yourself or your business.</li><li>Make a <span class="highlight">Business Payment Sitch</span> to charge clients.</li><li>Make a <span class="highlight">Gallery Sitch</span> or a <span class="highlight">Files Sitch</span> if you want to show off the product you’re selling.</li><li>Make a <span class="highlight">Booking Sitch</span> to let people book appointments with you.</li><li>Make a <span class="highlight">Contact Form Sitch</span> so that potential clients can quickly send you a message.</li><li>Make a <span class="highlight">Group Sitch</span> or a <span class="highlight">Site Sitch</span> to tie it all together in a single Sitch so that clients get all of the above in a single tap, click, or scan.</li>`,
    eventhost: 'Event Host',
    fixErrors: 'Fix Errors',
    eventhostInfo: `<li>Make an <span class="highlight">Events Sitch</span> to present upcoming events and allow people to add them to their calendars.</li>For each event you can make a <span class="highlight">Site Sitch</span> that contains the following:<li>A <span class="highlight">Files Sitch</span> to give out any relevant materials pertaining to the event like a map or an itinerary.</li><li>A <span class="highlight">Guestbook Form Sitch</span> that attendees can add their names and emails to.</li><li>A <span class="highlight">WiFi Sitch</span> so that guests can get on the event’s wifi easily.</li>`,
    influencer: 'Influencer',
    influencerInfo: `<li>Make a <span class="highlight">Profile Sitch</span> to let your viewers know more about you and share all your social media links.</li><li>Make a <span class="highlight">Link List Sitch</span> to show your affiliate links, or other links you want to share.</li><li>Make a <span class="highlight">Shop Sitch</span> to sell your merchandise.</li><li>Make a <span class="highlight">Blog Sitch</span> to share your latest thoughts and updates.</li><li>Make an <span class="highlight">Events Sitch</span> to let your viewers know about your streaming schedule or any other upcoming events.</li>`,
    retailOrTakeout: 'Retail or Takeout',
    retailOrTakeoutInfo: `<li>Make a <span class="highlight">Profile Sitch</span> to describe your business.</li><li>Make a <span class="highlight">Shop Sitch</span> to sell your products or checkout customers.</li><li>Make a <span class="highlight">Review Form Sitch</span> so that customers can leave you anonymous feedback.</li><li>Make a <span class="highlight">Contact Tracing Sitch</span> if your establishment requires it.</li>`,
    nothingInParticular: 'Nothing in particular',
    nothingInParticularInfo: `<li>Make a <span class="highlight">Profile Sitch</span> to easily share contact info. You can make seperate profiles for business or social events.</li><li>Make a <span class="highlight">Personal Payments Sitch</span> so that you can easily collect money from friends.</li><li>Make a <span class="highlight">Redirect Sitch</span> to send people to your personal website, or a <span class="highlight">Site Sitch</span> to make a personal website in Sitch.</li>`,
    includeMessage: 'Include message for printing',
    includeMessageInfo: 'Enable this if you want to add a message when printing your QR Code like "Scan Here".',
    scanHere: 'SCAN HERE',
    copyLink: 'Copy Link',
    shareLink: 'Share Link',
    showLabelsForSocials: 'Show labels for socials',
    showLabelsForSocialsInfo: 'If not checked, only the icon will be shown for each social.',
    sitchDeviceReason1: `Want something more versatile than a business card? Create a Profile Sitch and share it with a Sitch Device at gatherings.`,
    sitchDeviceReason2: `Want to make sure everyone at a meeting or seminar has the relevant links, documents, and overview? Create a Site Sitch and have attendees tap your device when they take a seat or on the way out.`,
    sitchDeviceReason3: `Freelancer who needs to collect payment in person after the jobs done? Use the Payment Sitch to set an amount and take payments.`,
    sitchDeviceReason4: `Took a picture at the party and need to share it? Create a file Sitch and share it with strangers using your device. Or use the Sitch Cam.`,
    howMightIUseIt: 'How might I use it?',
    accordion: 'Accordion',
    mustEnterAChoice: 'Must enter a choice',
    choicesMustBeUnique: 'Choices must be unique',
    choice: 'Choice',
    choices: 'Choices',
    baseAmount: 'Pre Total',
    deliveryFee: 'Delivery Fee',
    discount: 'Discount',
    total: 'Total',
    refunded: 'Refunded',
    paymentAmount: `Payment Amount`,
    setAmount: `Set Amount`,
    amount: `Amount`,
    totalAmount: 'Total Amount',
    premiumOptions: 'Premium Options',
    maximumDeliveryDistance: 'Maximum Delivery Distance',
    countriesYouDeliverToInfo:
      'This will limit the addresses that people can order from to just addresses from the specified countries. If you do not specify any countries, people with addresses from any country can place an order.',
    hasMaxOrderDistance: 'Restrict maximum order distance',
    hasMaxOrderDistanceInfo: 'Enable this if you cannot delivery beyond a certain local distance.',
    maxOrderDistance: 'Maxiumum Order Distance from Address (km)',
    addressRequired: 'Address required. You must select a value from the dropdown after typing in your address.',
    address: 'Address',
    shopAddressInfo: 'The address will not be displayed to customers. You must select a value from the dropdown after typing in your address.',
    round: 'Round',
    sharp: 'Sharp',
    flat: 'Flat',
    enableVenmo: 'Enable Venmo',
    enableVenmoInfo: 'For accepting payments from people who have Venmo.',
    venmoInfo: 'A valid Venmo URL should look something like this: www.venmo.com/your-user-id.',
    venmoUrl: 'Venmo URL',
    enableCashApp: 'Enable Cash App',
    enableCashAppInfo: 'For accepting payments from people who have the Cash App.',
    cashAppInfo: 'A valid Cash App URL should look something like this: https://cash.app/$your_cash_tag.',
    cashAppUrl: 'Cash App URL',
    communicationModes: 'Communication Sitches',
    communicationModesDescription: 'Sitches for talking to others',
    returnTo: 'Return To',
    returnToDescription: 'A way to tag pets or valued things with your info, so if you lose them, someone who finds them can get in touch.',
    chat: 'Quick Chat',
    chatDescription: 'For chatting with new people easily and temporarily without needing to exchange contact info. Can also be used as a translator.',
    hasAutoTranslate: 'Translate messages automatically',
    hasAutoTranslateInfo: 'Enable this if this chat will be used to quickly chat with strangers who speak another language. It will translate messages in real-time based on their browsers language.',
    maxParticipants: 'Max Participants',
    joinChat: 'Join Chat',
    welcomeMessage: 'Welcome Message',
    welcomeMessageInfo: 'A message to welcome newcomers to the chat',
    removeParticipant: 'Remove Participant',
    participantWasRemoved: 'Participant was removed',
    yourAddress: 'Your Address',
    transferPermalink: 'Transfer Custom Link',
    transfer: 'Transfer',
    modeToTransferTo: 'Sitch to Transfer to',
    transferSuccessful: 'Transfer successful.',
    // To translate
    useSitchLinkQr: 'Use Smart QR code',
    useSitchLinkQrInfo:
      'This is only relevant if you are copying or printing the QR code. Enable this if you want the QR code to always redirect to any active Sitch. If this is not checked, the QR code will only ever redirect to the active Sitch.',
    qrMessage: 'In-app QR code message',
    qrMessageInfo:
      'Add this if you want to display a message above the in app QR code. This is useful if you are unable to communicate with the intended recipients yourself and you want to encourage them to scan the QR code so that you can talk to each other. The message might explain why you are unable to talk to them. Be it language or verbalization issues',
    clearChat: 'Clear Chat',
    chatClearSuccessful: 'All messages and participants were removed.',
    chatClearInfo:
      'Clear the chat whenever you are finished using it for your current needs. This will delete all the messages and remove the current participants so that you can start fresh for the next use. People with the link will still be able to rejoin. If you wish to prevent that, reset the chat instead.',
    confirmChatClear:
      'Are you sure you want to clear this chat? This will remove all messages and participants, but participants with the link can still rejoin. To change the link, edit this chat and press the "Reset Chat" button instead.',
    setSalesTaxRate: 'Set sales tax rate based on location',
    setSalesTaxRateDialogInfo:
      'The rates shown here are final. Sitch does not factor in product categories, exemptions, county location, or other tax altering considerations beyond state or province level location. For more granular tax control you can make different stores for different requirements.',
    mustSpecifyTaxRateForAtLeastOneLocation: 'Because you selected "Set sales tax rate based on location", You must specify a sales tax rate for at least one location.',
    deliveryStrategy: 'Delivery strategy',
    localDelivery: 'Local delivery of products or services (food, plumbing, painting etc.)',
    shippingDelivery: 'Shipping products either locally, nationally, or internationally.',
    deliveryInfo: `Enable this if your products or services require the customer's address.`,
    canadianLocations: 'Canadian Locations',
    usLocations: 'US Locations',
    selectLocations: 'Select Locations ({0} Selected)',
    selected: '{0} Selected',
    flatDeliveryFee: `Flat Delivery Fee`,
    flatDeliveryFeeInfo: `You can include a flat delivery fee to be added to the order total. This fee remains the same regardless of how many items are ordered.`,
    delivery: 'Delivery',
    rotateLeft: 'Left',
    rotateRight: 'Right',
    editImage: `Edit Image`,
    tryItOut: `This is the Sitch Card. To see how it works tap one of your Sitches below. Sitch Cards and other Sitch Devices can be purchased below.`,
    freelancer: 'Freelancer / Business Owner',
    teams: 'Teams',
    mustBeValidEmailDomain: 'Must be valid email domain',
    addTeam: 'Add Team',
    team: 'Team {0}',
    teamName: 'Team Name',
    modesForThisTeam: 'Sitches for this Team',
    whitelistEmails: 'Whitelisted Emails',
    whiteListedEmailToAdd: 'Type an email for a Sitch account that should have access here and press enter.',
    whitelistEmailDomain: 'Whitelisted Email Domain',
    whitelistEmailDomainInfo: 'All Sitch users with emails that end in this domain will be able to use your invite link. Only one whitelisted domain is currently allowed.',
    whiteListedEmailDomainToAdd: 'Example: "@yourbusiness.com".',
    inviteLink: 'Invite Link',
    doYouWantToJoinThisTeam: 'Do you want to join {0} and get their Sitches?',
    cannotJoinOwnTeam: 'You can not join a team you created.',
    youHaveAlreadyJoinedThisTeam: 'You have already joined this team.',
    teamsInfoNoPremium: `With Sitch Premium you can create teams so that other Sitch users can use your Sitches. You just select which Sitches you want to share, send other users an invite link, and you're done! To begin, subscribe to Sitch Premium and return to this page.`,
    joinedTeams: 'Joined Teams',
    undo: 'Undo',
    leaveTeamConfirmation: 'Are you sure you want to leave this team?',
    youWereRemovedFromATeam: 'You were removed from a team.',
    linkedDevices: 'Linked Devices',
    deviceType: 'Device Type',
    activated: 'Activated',
    unlink: 'unlink',
    unlinkDeviceInfo: 'If you wish to link this device to a different account, you must first unlink it from this account.',
    shareSitchesWithTeams: 'Share Sitches with teams',
    guestbookFormDescription: 'A prebuilt From Sitch for collecting names and emails',
    personalPaymentDescription: `For taking personal payments via payment apps.`,
    paymentModesDescription: `For collecting payment via a connected Stripe account or through payment apps.`,
    setSalesTaxRateInfo:
      'Check the option below if you want to charge sales tax based on the customers location in the US or Canada. For international or interstate shops, this may be a necessity. If checked, all purchases made in the selected locations will have the specified tax rate applied to all their purchases (your default tax rate, shipping tax rate, and unique item tax rates are ignored). For all other locations the default tax rate and unique item tax rates will be used. For more granular tax control you can make different stores for different tax requirements.',
    exportData: 'Export Data',
    statusFilter: 'Status Filter',
    trends: 'Trends',
    gameModesDescription: 'Sitches to start multiplayer games instantly.',
    gameModes: 'Game Sitches',
    chess: 'Chess',
    allowShowThreatsOption: 'Allow Show Threats Option',
    allowShowThreatsOptionInfo: 'Allows players to see which pieces can be attacked.',
    createAccount: 'Create Account',
    addAccount: 'Add Account',
    accountSuccessfullyCreated: 'Account successfully created.',
    dateCreated: `Date Created`,
    saveTeams: 'Save Teams',
    manageTeams: 'Manage Teams',
    manageAccounts: 'Manage Accounts',
    teamsInfo:
      'Invite team members to use your Sitches by creating teams, assigning these teams Sitches, and inviting others to join a team. You can also create accounts for new teammates which you can manage, and assign those accounts to teams without using an invite link.',
    disconnectAccount: 'Disconnect Account',
    disconnectAccountConfirm: 'Are you sure you want to disconnect this account? The account will not be deleted but you will no be able to see it or log into it from this page.',
    accountSuccessfullyDisconnected: 'The account was successfully disconnected.',
    signInWithAccount: 'Sign in with Account',
    addToTeam: 'Add to Team',
    thereAreErrorsInATeamForm: 'There are errors in a team form.',
    emailAlreadyInUse: `That email is already in use.`,
    cannotJoinYourOwnTeam: 'You cannot join a team that you created.',
    teamDeleted: 'The team you were invited to has been deleted',
    notWhitelisted: 'You have not been whitelisted for this team',
    sitchDevicesInfo:
      'Sitch Devices are how you quickly share your Sitches without having to use the in-app QR code. Buying and activating a Sitch Device unlocks your Smart Link which will show your active Sitch.',
    trackingLink: 'Tracking Link',
    trackingLinkInfo: 'If you have shipped this order with a tracking link you can log it here to be used when composing emails.',
    refreshPageToGetLatestMode: 'This Sitch was updated elsewhere after you started editing it. Refresh to get the latest version before making changes.',
    activeMode: 'Active Sitch',
    assignedMode: 'Assigned Sitch',
    assignedModeInfo:
      'By default, a Sitch Device will display the active Sitch, but alternatively, you can assign a Sitch so that the device will always display the assigned Sitch instead of the active Sitch.',
    closureDatesHint: `Yearly dates that you do not want to allow bookings for.`,
    fileUpload: 'File Upload',
    uploadedFilesAreDeletedAfter1Year: 'Uploaded files will be deleted after 1 year.',
    fieldCopiedToTheEndOfTheList: 'Field copied to the end of the list.',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    assignToAll: 'Assign to all Devices',
    modeToAssign: 'Sitch to Assign',
    wearables: 'Wearables',
    createNewGroup: 'Create New Group',
    modeGroups: 'Sitch Groups',
    entryCode: 'Entry Code',
    selectAtLeastOneMode: 'Select at least one Sitch from the dropdown.',
    whatIsASitchDevice: 'What is a Sitch Device?',
    areYouSureYouWantToUnlink: 'Are you sure you want to unlink this device? If you unlink all of your devices you will lose access to your Smart Link as well.',
    noModesExistYetForWearables: `You need to create a Sitch before you can add one to a group.`,
    exportCsv: 'Export for Spreadsheet',
    exportFor: 'Export for {0}',
    all: 'All',
    sitcker: 'Sticker {0}',
    assignModes: 'Assign Stiches',
    hide: 'Hide',
    getAppChrome: `Tap the three dot menu button on the top right of the browser. Then tap "Install App" or "Add to Home screen" if you don't see the first option.`,
    getAppEdge: `Tap the three dot menu icon at the bottom of the browser. Then tap "Add to phone".`,
    getAppSafari: `Open mysitch.app in the Safari browser, tap the share button, then tap "Add to Home Screen". Safari is the only iPhone browser with this functionality.`,
    getApp3DotBottomAndInstall: `Tap the three dot menu icon at the bottom of the browser. Then tap "Install".`,
    getAppUnknownBrowser: `Open the Chrome browser and navigate to mysitch.app. Tap the three dot menu button on the top right of the browser. Then tap "Install App" or "Add to Home screen" if you don't see the first option.`,
    installApp: 'Install App',
    countriesYouDeliverTo: 'Countries you deliver to',
    countriesYouOfferFreeShippingTo: 'Countries you offer free shipping to',
    countriesYouOfferFreeShippingToInfo:
      'If you offer free shipping to some specific countries, list them here. When addresses from these countries are selected by a customer, any delivery fees will be removed.',
    modeToTransferToInfo: 'You can only transfer to Sitches that do not have their own custom links.',
    orderStatusIsOnlyApplicable: '* Order Status is only applicable to payments with orders attached to them.',
    wearablesInfo: 'Create groups of Sitches here so that they can be used with our Wear OS and watchOS apps for quick active Sitch switching.',
    signOutAndIn: 'Sign out, and sign in with the new email to complete the email transfer.',
    videos: 'Videos',
    videoInfo: 'Learn more about what you can do with Sitch.',
    whatIsATheSitchDevice: `Currently, you can purchase Sitch Cards, Sitch Bands, Sitch Bumpers, or Sitch Stickers. With any of these you can tap a phone to make your active Sitch appear. If the phone doesn't have NFC capability, the Sitch Card has a QR code on the rear that can be scanned with their camera app.`,
    whatIsSitch: 'What is Sitch?',
    views: 'Views',
    getWearOsApp: 'Wear OS App',
    getWatchOsApp: 'WatchOS App',
    companionWatchApps: 'Also check out our companion watch apps for faster Sitch switching.',
    deactivate: 'Deactivate',
    whyInstall: `The Sitch phone app is not on the App Store or Play Store, but it can be installed as a lightweight app on your home screen for quick access and more features.`,
    reset: 'Reset',
    stripeConntectInfo: `Connect a Stripe account if you want to get paid when selling items via a Shop Sitch or when using the Business Payment Sitch. Fees are just 55 cents + 2.9% per transaction. Charge $100, see $96.55 in your bank account.`,
    sitchPremiumDescription: `Want to get more out of Sitch? Upgrade to premium for access to more features. Cancel anytime, no strings attached. It's just US$5 a month.`,
    sitchLinkNeeded: 'Link a Sitch Device to your account to enable this feature. Devices can be purchased on the Sitch Devices page.',
    minimumQuantity: 'Minimum Quantity',
    maximumQuantity: 'Maximum Quantity',
    minimumQuantityInfo: 'Enter a value greater than 1 and less than 1000 if you a require a minium order size for this item.',
    maximumQuantityInfo: 'Enter a value greater than 1 and less than 1000 if you a require a maximum order size for this item.',
    quantityMismatch: '{0} has a higher minimum quantity than maximum quantity',
    enableBitcoin: 'Enable Bitcoin',
    enableEthereum: 'Enable Ethereum',
    enableLitecoin: 'Enable Litecoin',
    cryptoAddress: 'Receiving Address',
    mustBeValidBitcoinAddress: 'Must be valid Bitcoin address',
    mustBeValidEthereumAddress: 'Must be valid Ethereum address',
    mustBeValidLitecoinAddress: 'Must be valid Litecoin address',
    cryptoOptions: 'Crypto Options',
    linksInfo:
      'Add your links here. The following links can be optionally embedded: Youtube links, Vimeo links, open.spotify.com links, and links that end in .mp4, .webm, .mp3, .ogg, .wav, .gif, .png, .jpg, jpeg, or .svg.',
    invalidEmbedLink: 'This link is not embeddable on Sitch.',
    linkListDescription: `For sharing a collection of links as buttons. Certain media links can be embedded.`,
    isHidden: `Hide This`,
    removeTemporarily: `Remove Temporarily`,
    isHiddenInfo: `Enable this if you want to temporarily hide this item.`,
    embedThis: 'Embed This',
    embedThisInfo: 'Enable this if you prefer this link to be playable on the page rather than shown as a link that opens in a new tab. Only certain links can be embedded.',
    thisLinkCanBeEmbedded: 'This link can be embedded!',
    socialMedia: 'Social Media',
    chatApps: 'Chat Apps',
    video: 'Video',
    audio: 'Audio',
    gaming: 'Gaming',
    commerce: 'Commerce',
    payment: 'Payment',
    forDevelopers: 'For Developers',
    misc: 'Misc',
    emailSent: 'Email sent.',
    youMustVerifyYourEmailToProceed:
      'Just one more step to go. We just sent an email verification link to {0}. This ensures we have your correct email. Once you have verified you can close and reopen this app to begin. If you do not see this email, check your junk/spam folders.',
    addTextField: 'Add Descriptive Text',
    addCustomField: `Add Input Field`,
    customFieldLabel: `Input Field Label`,
    customFields: 'Input Fields',
    customFieldsListItemLabel: `Input Field {0}`,
    descriptiveText: 'Descriptive Text',
    signature: 'Signature',
    signatureStatement: 'Signature Agreement',
    signatureStatementInfo: 'This statement should summarise what the signee is agreeing to.',
    whatsNew: `What's New?`,
    allowPeopleToSendTheirContactDetails: 'Allow people to send you their contact details',
    allowPeopleToSendTheirContactDetailsInfo: 'Enable this if you would like to use Custom Form Sitch to give viewers a way to send you their contact details.',
    autoGenerateContactDetailsForm: 'Auto Generate Contact Details Form',
    contactDetailsForm: 'Contact Details Form',
    contactDetailsFormInfo: `Select "Auto Generate Contact Details Form" if you would like to us to generate a Custom Form Sitch that collects name, email, and phone details. Select one of your own Custom Form Sitches if you would prefer to use those. If you choose the auto generation option you will still be able to edit the form we generate for you on the "Sitches" page. Alternatively, you can combine a Profile Sitch and a Custom Form Sitch with a Site Sitch or Group Sitch. Finally, you can also cause the "Share Your Info" button to redirect to a URL by selecting the "Redirect to URL" option.`,
    contactDetailsFormTitle: 'Contact Details ({0})',
    contactDetailsFormDisplayName: 'Contact Details',
    cannotMakeContactDetailsForm:
      'Cannot make the contact details form since you are at the maximum number of Sitches for your tier. The contact details form option has been unchecked. You can now save this Sitch without it.',
    displayNameHint: `The Sitch name is what is used on the Sitches page. If you want to display a different name for viewers, type it in here. Otherwise the Sitch name will be used instead.`,
    areYouSureYouWantToDeleteThisGroup: `Are you sure want to delete this group?`,
    affiliateSales: 'Affiliate Sales',
    affiliateSalesInfo: 'See how many sales you have captured from the usage of your discount code.',
    base: 'Base',
    affiliateCut: 'Affiliate Cut',
    sales: 'Sales',
    noSales: 'No sales with your promo code to report yet.',
    details: 'Details',
    promoCode: 'Promo Code',
    areYouSureYouWantToResetAnalytics: `Are you sure you want to reset the analytics for this Sitch? You won't be able to recover them.`,
    basic: `Basic`,
    premium: `Premium`,
    sitchPremiumPurchaserInfo: '*Up to {0} Sitches can be made over the standard {1} by purchasing and activating a Sitch Device.',
    cannotCreateNewModeFree: `You can only make up to {0} Sitches. Either delete one or upgrade to premium to make up to {1} and access more features.`,
    cannotCreateNewModePremium: `You can only make up to {0} Sitches. You must delete one before creating a new one. We love the enthusiasm though.`,
    headerFontHint: `Used for headings, buttons, form fields, and prices.`,
    shareYourInfo: 'Share Your Info',
    mixedGradients: 'Mixed Gradients',
    monochrome: 'Monochrome',
    monochromeGradients: 'Monochrome Gradients',
    doesThisContrast: 'Does this contrast?',
    noPreview: 'No Preview',
    pressEnvelopesToInvite: 'You can press the envelopes to send an invite email.',
    themesHint: 'Press here to change the colors and fonts for a Sitch by assigning a theme to it. You can create themes in Menu > Settings > Themes.',
    fixFerrorsInfo: "Encoutering errors? There's a good chance pressing here will fix them.",
    accordionDescription: 'A list of buttons that reveal text when pressed. Ideal for FAQs, multi-part instructions, and dense itemized information.',
    selectTheme: 'Select Theme',
    theme: 'Theme',
    redirectToUrl: 'Redirect to a URL instead',
    removeParticipantInfo:
      'You can remove the selected participant from your chat room if you want to be sure they are not lingering and reading messages. They will still be able to rejoin if they have the chat link but this will be shown in the chat log if they do. To change the chat link press the "Reset Chat" button below. However, this will delete the current chat and you will have to give any current participants the new link for them to rejoin the chat.',
    enableGooglePay: `Enable Google Pay`,
    enableGooglePayInfo: `If you want to take payments via Google Pay.`,
    googlePayUrl: `Google Pay URL`,
    googlePayInfo: `For accepting payments via a Google Pay link.`,
    enableApplePay: `Enable Apple Pay`,
    enableApplePayInfo: `If you want to take payments via Apple Pay.`,
    applePayUrl: `Apple Pay URL`,
    applePayInfo: `For accepting payments via a Apple Pay link.`,
    chooseLocations: 'Choose Locations',
    selectLocationsToComputeSalesTaxFor: 'Choose locations to specify a sales tax rate for.',
    uppercaseHeaderFont: 'Uppercase headings',
    uppercaseHeaderFontInfo: 'Enable this if you like the aesthetic of headings being uppercased. Normally, only button labels are uppercased.',
    resetLink: 'Reset Link',
    resetLinkInfo: 'Reset the link to prevent people with access to the current link from rejoining.',
    confirmLinkReset:
      'Are you sure you want to reset the link to this Sitch? Anyone with the old link will be unable to join until you give them the new link, unless you gave them a custom link which will still remain the same. If the Sitch can be accessed through a Site Sitch or Group Sitch though, people with access to that Site Sitch or Group Sitch will still be able to rejoin the Sitch.',
    bookTime: 'Book Time',
    pay: 'Pay',
    portfolio: 'Portfolio',
    resume: 'Resume',
    generic: 'Generic',
    embeddedFormDataDisclaimer: 'Data from presubmission forms embedded in other Sitches will show in the data pages for those Sitches.',
    teamAtMax: '{0} has reached its maximum size of {1} members and cannot add new members until some are removed.',
    removeFromTeam: 'Remove From Team',
    joinedOn: 'Joined this team on {0}',
    removalSuccessful: 'Removal successful',
    cannotCreateMoreThanTeams: 'Cannot create more than {0} teams.',
    cannotCreateMoreThanManagedAccounts: 'Cannot create more than {0} managed accounts.',
    chessDescription: 'Just tap two or more phones to start a game of chess.',
    wordleDescription: 'Just tap two or more phones to start a game of competitive Wordle.',
    triviaDescription: 'Just tap two or more phones to start a game of competitive Trivia.',
    cardDescription: 'Make a simple greeting card for any occasion.',
    pointGoal: 'Points needed to win a round?',
    anyCategory: 'Any Category',
    generalKnowledge: 'General Knowledge',
    entertainmentBooks: 'Entertainment: Books',
    entertainmentFilm: 'Entertainment: Film',
    entertainmentMusic: 'Entertainment: Music',
    entertainmentMusicalsTheatres: 'Entertainment: Musicals & Theatres',
    entertainmentTelevision: 'Entertainment: Television',
    entertainmentVideoGames: 'Entertainment: Video Games',
    entertainmentBoardGames: 'Entertainment: Board Games',
    scienceNature: 'Science & Nature',
    scienceComputers: 'Science: Computers',
    scienceMathematics: 'Science: Mathematics',
    mythology: 'Mythology',
    sports: 'Sports',
    geography: 'Geography',
    history: 'History',
    politics: 'Politics',
    art: 'Art',
    celebrities: 'Celebrities',
    animals: 'Animals',
    vehicles: 'Vehicles',
    entertainmentComics: 'Entertainment: Comics',
    scienceGadgets: 'Science: Gadgets',
    entertainmentJapaneseAnimeManga: 'Entertainment: Japanese Anime & Manga',
    entertainmentCartoonAnimations: 'Entertainment: Cartoon & Animations',
    easy: 'Easy',
    medium: 'Medium',
    hard: 'Hard',
    numberOfQuestions: 'Number of Questions',
    category: 'Category',
    difficulty: 'Difficulty',
    frontCardText: 'Front of Card Text',
    insideOfCardImages: 'Inside of Card Images',
    insideCardText: 'Inside of Card Text',
    dateTime: 'Date and Time',
    mustBeValidUrlLike: `Must be a valid url which contains "{0}"`,
    inputAHandleInsteadOfUrl: 'Put your handle here instead of a URL',
    areYouSureYouWantToDeleteYourAccount:
      'Are you sure you want to completely delete your account? This will permanently delete all of your Sitches and related data as well. It will also disband and delete any teams made under this account and cancel any subscriptions.',
    qrCodePageInfoNoModes: `Once you have an active Sitch, you can use the QR code that will be shown here to share it.`,
    deleteAccount: 'Delete Account',
    accountDeleted: `Account successfully deleted.`,
    shopAddress: 'Select from dropdown after typing',
    pairingModeMessageP1: 'Your {0} is ready to pair!',
    pairingModeMessageP2: `Sign in below to link it to your account and create your first Sitch. After that, your device will share that Sitch when it's tapped or scanned.`,
    pairingModeMessageP2B: `Sign in below to link it to your account and create your first Sitch. After that, your link will share that Sitch.`,
    pairingModeMessageP3: 'If you have any questions, reach out to us at <a target="_blank" href="mailto:contact@sitch.app">contact@sitch.app</a>.',
    welcomeToSitchP1:
      'Welcome to Sitch! Now you can create Sitches, which are small webpages that you build by completing simple forms. Sitches can be business cards, forms, payment pages, link lists, or anything else. After you create your first Sitch, you can use your Sitch Device to share it.',
    welcomeToSitchP2:
      'If you need ideas on what kind of Sitches you should use, select a focus from the dropdown. Otherwise, you can create your first Sitch by pressing the <span class="highlight">New Sitch</span> button below.',
    iVerfiedMyEmail: 'I Verified my Email',
    showAddAsContactPrompt: 'Immediately show a prompt to add this profile as a contact when viewed',
    showAddAsContactPromptInfo:
      'Enable this if you want to show an instantaneous pop up that prompts people to add you as a contact when viewing your profile. An "Add to Contacts" button will be visible on your profile regardless.',
    showContactDetailsPrompt: 'Immediately show a prompt for others to share their info when viewed',
    showContactDetailsPromptInfo:
      'Enable this if you want to show an instantaneous pop up that prompts people to share their contact info when viewing your profile. A "Share your Info" button will be visible on your profile regardless.',
    customFieldsInfo: `If you need to collect more information you can add input fields below. Some examples might be fields like birthday, age, gender etc. You can also add descriptive text in between input fields as well to provide comments, instructions, or additional descriptions.`,
    profileDescription: `Digital business cards for a person or organization.`,
    urlRedirectDescription: `Send folks to a website of your choosing.`,
    caption: 'Caption',
    backgroundImage: 'Background Image',
    backgroundImageDescription: `This will appear as a slightly blurred background image that merges with your chosen background color. Note: freshly uploaded background images will not show in previews, you will have to save first.`,
    uniqueViews: 'Unique Views',
    uniqueTapScanViews: 'Unique Tap/Scan Views',
    deviceViews: 'Unique Views by Type',
    inAppQr: 'In App QR Code Scan',
    cardNfc: 'Card NFC Tap',
    cardQr: 'Card QR Scan',
    bandNfc: 'Band NFC Tap',
    dotNfc: 'Dot NFC Tap',
    stickerNfc: 'Sticker NFC Tap',
    linkView: 'Link View',
    unknownType: 'Uknown Type',
    canAnyoneJoin: 'Can anyone join?',
    canAnyoneJoinInfo: 'Allow anyone to join your team by merely using the invite link. Otherwise, you can whitelist the emails that you want to have access to this team.',
    hasLeaderboard: 'Has leaderboard?',
    hasLeaderboardInfo: 'Allow team members to see their ranking in terms of the number of unique views they get per month via taps or scans.',
    allTeamModesDeleted: 'There are no Sitches for this team.',
    youHaveJoinedATeam: `You have been auto enrolled in the {0} team.`,
    monthlyLeaderboard: 'Monthly Leaderboard for {0}',
    members: 'Members',
    workPhoneExtension: `Work Phone Extension`,
    addAccountsFromCsv: `Add Accounts From CSV`,
    uploadCsvFile: `Upload CSV File`,
    accountsSuccessfullyCreated: 'Accounts successfully created.',
    accountsSuccessfullyCreatedButSomeFailed: 'Accounts successfully created. However, account creation was not successfull for the following emails: {0}. They may already be in use.',
    csvIsMalformed: `CSV is malformed`,
    someEntriesWereSkipped: `Some entries were skipped due to incomplete data`,
    options: 'Options',
    assignAccountsFromCsv: 'Assign Accounts From CSV',
    accountAssignmentsComplete: `Account assignments have been sucessfully completed.`,
    websiteLabel: 'Website Label',
    organization: 'Organization',
    setAsWrapper: 'Set as Wrapper',
    deactivateWrapper: 'Unset as Wrapper',
    dashboardInfo1: `You can see all of your created Sitches on this page. If you have a Sitch Device, you can set the Sitch that is shown on that device by pressing the "Set as Active" button. You can also double tap a Sitch to quickly set it as active.`,
    dashboardInfo2: `If you have created a Group Sitch or Site Sitch you can use them as wrappers. This means that when you select a Sitch to be your active Sitch it will be wrapped with the wrapper Sitch so that you present the active Sitch initially but other Sitches will also be available to the person who tapped your device. To set a wrapper click a Site or Group Sitch and then select the "Set as Wrapper" option.`,
    enableLoadingNewActiveModesAutomaticallyHint: `When AutoSitch is enabled, anyone who tapped/scanned your Sitch Device or scanned your in-app QR code will automatically see whatever Sitch you set as active without having to refresh the page. However, AutoSitch updating will end if the tapper/scanner is redirected to another website or if they refresh the page. Fixed links like sitch.app/s/1234 or sitch.app/mylink will not be effected by AutoSitch.`,
    autoSitchTimerInfo: 'How long after the initial tap or scan do you want AutoSitch to automatically update pages for?',
    autoSitchTimer: 'AutoSitch Timer',
    disable: 'Disable {0}',
    enable: 'Enable {0}',
    mins: '{0} Mins',
    assignWrapperToEveryTeamMember: 'Assign a Wrapper Sitch',
    assignWrapperToEveryTeamMemberInfo: `Use this option to assign the selected Sitch as a wrapper for every team member in this team whose account is managed by you. Managed accounts will show up in the table below. Wrappers are Site or Group Sitches that wrap around a user's active Sitch to provide common theming and options regardless of what their active Sitch is. Wrappers can be changed or deactivated anytime by the team member, so this is not neccesarily permanent.`,
    assign: 'Assign',
    wrappersUpdated: 'Wrappers updated successfully for managed accounts on this team.',
    cannotWrapSites: 'Site Sitches cannot be wrapped. Only the wrapper will be shown',
    cannotWrapGroupsInGroups: 'Group Sitches cannot wrap other Group Sitches. Only the wrapper will be shown',
    signInWith: 'Sign in with {0}',
    orSignUp: `Or sign Up`,
    orSignIn: `Or Sign In`,
    signUp: `Sign Up`,
    resetPassword: 'Reset Password',
    resetPasswordNotice: 'A password reset link has been sent to {0}',
    passwordResetInfo: `Provide your email and we'll send you a password reset link.`,
    sitchTutorial1: `<span class="highlight">{0}</span> is your active Sitch. You can now share it with your <a href="/SitchDevices" class="highlight">Sitch Device</a>. Alternatively, press on the Sitch to open its menu and then press the <span class="highlight">QR Code</span> button to share it with your phone. To share this Sitch as a link, open the Sitch's menu and then press <span class="highlight">Copy Link</span>.`,
    sitchTutorial2: `Now that you have multiple Sitches, you can switch between them by tapping a Sitch and selecting "Set as Active" from its menu. By default, any linked Sitch devices will share your active Sitch. You can also set a Sitch as active by double tapping it.`,
    nativePushNotifications: 'Mobile Push Notifications',
    unsavedChanges: `Leave this page? Changes that you made may not be saved.`,
    trvia: 'Trivia',
    wordle: 'Wordle',
    reviewUs: 'Review Us',
    paidBy: 'Paid By',
    searchCurrent: 'Search loaded records',
    logoImage: 'Logo Image',
    logoImageDescription: `This will appear as additional image at the top the page. It's a great option for displaying a company logo on all Sitches that use this theme.`,
    deliveryUniqueTaxRate: 'Unique tax rate for delivery',
    deliveryTaxRateInfo: 'Enable this if delivery services are taxed differently in your area. Caution, location based tax rates will override this setting.',
    bottomOfPageText: 'Bottom of the Page Text',
    bottomOfPageTextInfo: 'This text will be placed at the bottom of all Sitches with this theme. It can be used to show a legal disclaimer, a quote, or anything else you can think of.',
    premiumOptionsDescription: 'You can play with the following options but they will only apply if you have a <a target="_blank" href="/SitchPremium">premium membership</a>.',
    metaTheme: 'Meta Theme',
    metaThemeDescription: 'Would you like to use a light or dark theme?',
    themeForThisTeam: 'Assign a Theme for this Team',
    themeForThisTeamInfo: 'This will make the selected theme the default theme for all managed users on this team.',
    whenTheyJoined: '{0} (When They Joined)',
    cannotChangeTheme: 'Your account creator has assigned a team theme to all of your Sitches. You must opt out of this theme to pick your own themes.',
    optOut: 'Opt Out',
    // To translate.
    organizationUrl: `Organization URL`,
    hideFields: 'Hide fields',
    hideFieldsDisclaimer: 'Hidden fields will still show on a downloaded contact card.',
    hidePhone: 'Hide phone',
    hidePhoneWork: 'Hide work phone',
    hideEmail: 'Hide email',    
    hideWebsite: 'Hide website',
    hideOrganization: 'Hide organization',
    hideLocationAddress: 'Hide address',    
    hideSaveForLaterButton: 'Hide "Save for Later" button',
    showAdvancedOptions: 'Show Advanced Options',
    hideAdvancedOptions: 'Hide Advanced Options',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    tooManyAttempts: 'Too many attempts, try again later.',
    somethingWentWrong: 'Something went wrong.',
    addContact: 'Add to Contacts',
  },
};

// To delete:
// designTheme,
// createNewTheme,
// turnAutoSitchOn,
// turnAutoSitchOff,
// hideFolders,
// resetChat,
// signInOrSignUp,
