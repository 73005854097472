
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { formatCurrency } from '@/util-functions/misc-utils';
import { showNotice } from '@/util-functions/notice-utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<ModifierGroup[]>,
      required: true,
    },
    currency: {
      type: String,
      default: '',
    },
    shopItemPurchaseQuantity: {
      type: Number,
      required: true,
    },
  },
  data(): {
    currSelectionCountForEachModifierGroup: Record<string, number>;
    modifierGroups: ModifierGroup[];
  } {
    return {
      currSelectionCountForEachModifierGroup: {},
      modifierGroups: [],
    };
  },
  watch: {
    value() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    modifiersWithQuantityAndRequiredInput(modifiers: Modifier[]): Modifier[] {
      return modifiers.filter((modifier) => {
        return modifier.quantitySelected > 0 && modifier.requiresCustomerInput;
      });
    },
    formatCurrency(amount = 0, showSign = false, addHtml = false) {
      return formatCurrency(amount, this.currency, showSign, addHtml);
    },
    onLoad() {
      this.modifierGroups = [...this.value];
    },
    optOutLabel(og: ModifierGroup) {
      return this.currSelectionCountForEachModifierGroup[og.name] ? t?.IJustWant.supplant([this.currSelectionCountForEachModifierGroup[og.name]]) : t?.IDontWantAny;
    },
    chooseText(modifierGroup: ModifierGroup): string {
      let ret = '';
      if (modifierGroup.numberOfSelections > 1) {
        const numberOfSelections: string = modifierGroup.numberOfSelections.toString().toLowerCase();
        ret += modifierGroup.mustPickExactAmount ? t.choose.supplant([numberOfSelections]) : numberOfSelections ? t.chooseUpTo.supplant([numberOfSelections]) : t.asManyAsYoudLike;
        ret += modifierGroup.canSelectMultipleOfSameModifier ? ' ' + t.multipleOfSingleModifier : '';
      }
      return ret;
    },
    isSoldOut(modifier: Modifier) {
      return modifier.isSoldOut || (modifier.hasStock && !modifier.stock);
    },
    selectModifier(event: any, modifier: Modifier, modifierGroup: ModifierGroup) {
      if (this.isSoldOut(modifier)) {
        showError(t.modifierSoldOut);
        return;
      }

      modifierGroup.userOptedToSelectLessThanMax = false;
      const remainingStockForModifier = modifier.hasStock ? modifier.stock : Number.MAX_VALUE;

      // Make the quanitity for this modifier a number value.
      modifier.quantitySelected = modifier.quantitySelected || 0;

      // Create this to track the total number of modifier selected for this modifier group.
      let currCountSelectedForGroup = 0;

      modifierGroup.modifiers.forEach((el: any) => {
        currCountSelectedForGroup += el.quantitySelected || 0;
      });

      if (modifierGroup.numberOfSelections === 0 || currCountSelectedForGroup < modifierGroup.numberOfSelections) {
        // We can select more modifiers in this case.
        if (modifierGroup.canSelectMultipleOfSameModifier) {
          const newQuantity = modifier.quantitySelected + 1;
          if (newQuantity * this.shopItemPurchaseQuantity <= remainingStockForModifier) {
            modifier.quantitySelected = newQuantity;
            currCountSelectedForGroup++;
          } else {
            showError(t.notEnoughStock);
          }
        } else {
          // We cannot select multiple of the same modifier in this case, so if it's been selected set it right back to zero.
          if (modifier.quantitySelected) {
            modifier.quantitySelected = 0;
            currCountSelectedForGroup -= 1;
          } else {
            if (this.shopItemPurchaseQuantity <= remainingStockForModifier) {
              modifier.quantitySelected = 1;
              currCountSelectedForGroup += 1;
            } else {
              showError(t.notEnoughStock);
            }
          }
        }
      } else {
        // We cannot select more modifiers in this case.
        if (!modifier.quantitySelected) {
          // In this case the modifier we've selected hasn't been picked even once.
          if (modifierGroup.numberOfSelections === 1) {
            if (1 <= remainingStockForModifier) {
              // If you can only select one thing then unselect everything else and select this one.
              modifierGroup.modifiers.forEach((modifier) => {
                modifier.quantitySelected = 0;
              });
              modifier.quantitySelected = 1;
              currCountSelectedForGroup = 1;
            } else {
              showError(t.notEnoughStock);
            }
          } else {
            // Otherwise if user tries to select a new modifier after hitting the max number of selections show this message.
            showNotice(t.canOnlySelectMaxModifiers.supplant([modifierGroup.numberOfSelections]));
          }
        } else {
          currCountSelectedForGroup -= modifier.quantitySelected;
          modifier.quantitySelected = 0;
        }
      }

      if (modifier.quantitySelected) {
        event.target.classList.add('selected');
      } else {
        event.target.classList.remove('selected');
      }

      this.currSelectionCountForEachModifierGroup[modifierGroup.name] = currCountSelectedForGroup;
      this.$emit('input', this.modifierGroups);
      this.$forceUpdate();
    },
    selectLessThanMax(modifierGroup: ModifierGroup) {
      modifierGroup.userOptedToSelectLessThanMax = !modifierGroup.userOptedToSelectLessThanMax;
      this.$forceUpdate();
    },
    showChoiceOptOutTag(modifierGroup: ModifierGroup): boolean {
      const isUnbounded = modifierGroup.canSelectMultipleOfSameModifier && modifierGroup.numberOfSelections === 0;
      return Boolean(!isUnbounded && modifierGroup.numberOfSelections !== 0 && !modifierGroup.mustPickExactAmount && this.currSelectionCountForEachModifierGroup[modifierGroup.name] !== modifierGroup.numberOfSelections);
    },
    resetModifierGroup(modifierGroup: ModifierGroup) {
      modifierGroup.modifiers?.forEach((modifier: Modifier) => {
        modifier.quantitySelected = 0;
      });
      this.currSelectionCountForEachModifierGroup[modifierGroup.name] = 0;
      this.$forceUpdate();
    },
    showResetModifierGroupTag(modifierGroup: ModifierGroup): boolean {
      const atLeaseOneThingHasBeenPicked = Boolean(
        modifierGroup.modifiers?.some((modifier: Modifier) => {
          return modifier.quantitySelected > 0;
        })
      );
      return modifierGroup.canSelectMultipleOfSameModifier && modifierGroup.numberOfSelections !== 1 && atLeaseOneThingHasBeenPicked;
    },
  },
});
