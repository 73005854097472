import { endpoints } from "@/constants";
import { store } from "@/store";
import { standardApiFetch, isProductionEnv } from "./initialization-utils";

export const getStripeAccountData = (accountId: string): Promise<StripeAccount | null> => {
  return new Promise((resolve, reject) => {
    standardApiFetch(endpoints.getStripeAccount, {
      accountId,
    })
      .then((response) => {
        resolve(response.successfulResponse.account);
      })
      .catch(() => {
        reject(null);
      });
  });
};

export const getStripePublicKey = (): string => {
  // thU6a3OaF8VvUUEmf67xhM7YPVX2 Is the demo account.
  if (isProductionEnv && store.state.userId !== 'thU6a3OaF8VvUUEmf67xhM7YPVX2') {
    return 'pk_live_0LQsfW6fOZ3SoXXSWppc2pfc008MdjqRHy';
  }
  return 'pk_test_0QF6AtxxbfkdYAMWSE527WJW00kM5SxAsn';
};

export const onConnectStripeAccount = () => {
  const testClientId = 'ca_Hf8WxA6y2F3L4VORrPZdhwyKrVtDHNS3';
  const prodClientId = 'ca_Hf8WxL3ONXKtPp0W703m9Gayrs2zAGYp';
  const clientId = isProductionEnv ? prodClientId : testClientId;
  const responseType = 'code';
  const email = store.state.currUser.email;
  const nameFirst = store.state.currUser.displayName.split(' ')?.[0];
  const nameLast = store.state.currUser.displayName.split(' ')?.[1];
  const redirectUri = `https://${window.location.hostname}/Deposits`;
  window.open(`https://connect.stripe.com/oauth/authorize?redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}&scope=read_write&stripe_user[email]=${email}&stripe_user[first_name]=${nameFirst}&stripe_user[last_name]=${nameLast}`, '_blank');
};
