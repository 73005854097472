
import Vue from 'vue';

export default Vue.extend({
  props: {
    h: {
      type: Number,
      default: 0,
    },
    w: {
      type: Number,
      default: 0,
    },
  },
});
