import imageCompression from 'browser-image-compression';
import { getDownloadURL, ref, uploadBytesResumable, UploadMetadata } from 'firebase/storage';
import { store } from '../store';
import { nonDebugLog, fbStorage } from './initialization-utils';
import { generateId, showError } from './misc-firestore-utils';
import { addImageFileExtentionToFileName } from './misc-utils';
import { deleteFileFromStorage, isValidUpload, stopTrackingUploadProgress, trackUploadProgress } from './storage-utils';

export const compressImage = (file: File, maxDimension: number): Promise<CompressedFileObject> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // If image then compress the image.
    imageCompression(file, {
      maxSizeMB: 5, // (default: Number.POSITIVE_INFINITY).
      maxWidthOrHeight: maxDimension, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined).
      maxIteration: 10, // optional, max number of iteration to compress the image (default: 10).
    })
      .then((compressedFile: any) => {
        // We need a bas64 url for preview purposes.
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve({
            compressedFile,
            base64data: base64data as string,
          });
        };
      })
      .catch((error: any) => {
        showError(error);
        reject(error);
      });
  });
};

export const deleteCurrentUploadedSmallAndLargeImagesForUploadedImage = (image: UploadedImage): Promise<any> => {
  const smallImagePromise = new Promise((resolve, reject) => {
    if (image.smallImageStoragePath && image.smallImageFileName) {
      deleteFileFromStorage(image.smallImageStoragePath, image.smallImageFileName)
        .then(() => {
          image.smallImageUrl = '';
          image.smallImageStoragePath = '';
          image.smallImageFileName = '';
          resolve(null);
        })
        .catch((error: any) => {
          reject(error);
        });
    } else {
      resolve(null);
    }
  });

  const largeImagePromise = new Promise((resolve, reject) => {
    if (image.largeImageStoragePath && image.largeImageFileName) {
      deleteFileFromStorage(image.largeImageStoragePath, image.largeImageFileName)
        .then(() => {
          image.largeImageUrl = '';
          image.largeImageStoragePath = '';
          image.largeImageFileName = '';
          resolve(null);
        })
        .catch((error: any) => {
          reject(error);
        });
    } else {
      resolve(null);
    }
  });

  return Promise.all([smallImagePromise, largeImagePromise]);
};

// For deleting all images in the "images" array on an object.
export const deleteImages = (images: UploadedImage[]): Promise<any[]> => {
  const promiseArray: Promise<any>[] = [];
  images?.forEach((image) => {
    promiseArray.push(deleteImage(image));
  });
  return Promise.all(promiseArray);
};

export const deleteImage = (image: UploadedImage): Promise<any[]> => {
  const promiseArray: Promise<any>[] = [];
  if (image.largeImageStoragePath && image.largeImageFileName) {
    promiseArray.push(deleteFileFromStorage(image.largeImageStoragePath, image.largeImageFileName));
  }
  if (image.smallImageStoragePath && image.smallImageFileName) {
    promiseArray.push(deleteFileFromStorage(image.smallImageStoragePath, image.smallImageFileName));
  }
  const returnPromise = Promise.all(promiseArray);
  returnPromise.catch(() => {
    showError(`Could not delete images.`, null, true);
  });
  return returnPromise;
};

enum eImageType {
  small,
  large,
}

// This is used when saving an object to look at, and action upon, changes to the objects images array.
export const handleImages = (item: ItemWithImages, imageSizesAndKeysArray: { key: string; imageSizes: ImageSizes }[], promiseArray: Promise<any>[], storagePath: string) => {
  const anyItem: any = item;
  imageSizesAndKeysArray.forEach((ip) => {
    if (!anyItem[ip.key]) {
      anyItem[ip.key] = [];
    }

    const images: UploadedImage[] = (item as any)[ip.key];

    // Delete images marked for deletion.
    const imagesToDelete = images.filter((image: UploadedImage) => {
      const hasUploadedImage = image.smallImageUrl || image.largeImageUrl;
      return image.markedForDeletion && hasUploadedImage;
    });

    deleteImages(imagesToDelete);

    // Upload or delete and re-upload the images that have compressed file data in them.
    const imagesToUploadOrModify = images.filter((image: UploadedImage) => {
      const hasCompressedImageToUpload = image.smallCompressedImage || image.largeCompressedImage;
      return hasCompressedImageToUpload;
    });

    if (ip.imageSizes.small) {
      uploadImagesOfType(eImageType.small, imagesToUploadOrModify, promiseArray, storagePath);
    }
    if (ip.imageSizes.large) {
      uploadImagesOfType(eImageType.large, imagesToUploadOrModify, promiseArray, storagePath);
    }

    const imagesThatWereNotDeleted = images.filter((image: UploadedImage) => {
      if (image.markedForDeletion) {
        return false;
      }
      delete image.markedForDeletion; // We no longer need this field.
      return true;
    });

    anyItem[ip.key]  = imagesThatWereNotDeleted;
  });
};

export const uploadImagesOfType = (type: eImageType, images: UploadedImage[], promiseArray: Promise<any>[], storagePath: string) => {
  if (!images) {
    return;
  }

  images.forEach((image) => {
    let compressedImage: File | null = null;
    let fileName = '';

    // Attempt the use the filename if it exists, otherwise use a random id as the name.
    switch (type) {
      case eImageType.small:
        compressedImage = image.smallCompressedImage as File;
        fileName = `small_${image.smallCompressedImage?.name || 'noName'}`;
        break;
      case eImageType.large:
        compressedImage = image.largeCompressedImage as File;
        fileName = `large_${image.smallCompressedImage?.name || 'noName'}`;
        break;
    }

    if (!compressedImage) {
      return;
    }

    // Add file name extention if it does not already have one.
    fileName = addImageFileExtentionToFileName(fileName, compressedImage?.type);

    const fileSize = compressedImage.size;
    const fullPath = `${storagePath}/${fileName}`;
    const metadata: UploadMetadata = {
      customMetadata: {
        fullPath,
        userId: store.state.userId,
      },
    };

    if (isValidUpload(fileSize)) {
      promiseArray.push(
        new Promise((resolve, reject) => {
          // If this particular image is replacing one that was already uploaded, we need to first delete the old image to preserve an accurate quota.
          deleteCurrentUploadedSmallAndLargeImagesForUploadedImage(image)
            .then(() => {
              const uploadTask = uploadBytesResumable(ref(fbStorage, fullPath), compressedImage as File, metadata);

              const uploadTaskId = generateId();
              trackUploadProgress(uploadTask, uploadTaskId);

              uploadTask
                .then((uploadTaskSnapshot) => {
                  getDownloadURL(uploadTaskSnapshot.ref)
                    .then((downloadUrl: string) => {
                      stopTrackingUploadProgress(uploadTaskId);
                      // Push the uploaded image into data.
                      if (type === eImageType.small) {
                        image.smallImageUrl = downloadUrl;
                        image.smallImageStoragePath = storagePath;
                        image.smallImageFileName = fileName;
                        delete image.smallCompressedImage;
                      } else {
                        image.largeImageUrl = downloadUrl;
                        image.largeImageStoragePath = storagePath;
                        image.largeImageFileName = fileName;
                        delete image.largeCompressedImage;
                      }
                      delete image.toBeUploaded; // Since we have now uploaded the image.
                      delete image.smallImageBase64Preview; // Since we no longer need previews.
                      delete image.largeImageBase64Preview; // Sicne we no longer need previews.
                      const newStorageQuota = store.state.storageQuota + fileSize;
                      nonDebugLog('New storage quota:', newStorageQuota);
                      store.commit('storageQuota', newStorageQuota);
                      resolve(null);
                    })
                    .catch((error: any) => {
                      showError(`Unable to get the download URL for ${storagePath}.`, error, true);
                      reject();
                    });
                })
                .catch((error: any) => {
                  showError(`Unable to upload image to ${storagePath}.`, error, true);
                  reject();
                });
            })
            .catch((error: any) => {
              showError(`Unable to delete picture at ${fullPath}.`, error, true);
              reject();
            });
        })
      );
    }
  });
};
