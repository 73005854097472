
import Vue, { PropType } from 'vue';
import SitchModifierSelection from '@/components/custom-ui-components/SitchModifierSelection.vue';
import { t } from '@/util-functions/language-utils';
import { showError, generateId } from '@/util-functions/misc-firestore-utils';
import { isEmptyObject, getOrderItemTaxValues, formatCurrency } from '@/util-functions/misc-utils';
import { showSuccess } from '@/util-functions/notice-utils';
import { MAX_SHOP_ITEM_QUANTITY } from '@/constants';

export default Vue.extend({
  components: {
    SitchModifierSelection,
  },
  props: {
    value: {
      type: Object as PropType<ShopItem>,
    },
    mode: {
      type: Object as PropType<ShopMode>,
    },
    orderBreakdown: {
      type: Array,
      required: true,
    },
  },
  data(): {
    currentShopItem: ShopItem;
    quantity: number;
    showShopItemModal: boolean;
    showSpecialRequestTextArea: boolean;
    specialRequest: string;
    maxShopItemQuantity: number;
  } {
    return {
      currentShopItem: this.value,
      quantity: this.value?.minimumQuantity || 1,
      showShopItemModal: false,
      showSpecialRequestTextArea: false,
      specialRequest: '',
      maxShopItemQuantity: MAX_SHOP_ITEM_QUANTITY,
    };
  },
  watch: {
    value: {
      handler() {
        this.currentShopItem = this.value;
        if (this.currentShopItem) {
          this.quantity = this.currentShopItem?.minimumQuantity || 1;
          this.showShopItemModal = true;
        } else {
          this.showShopItemModal = false;
        }
        this.$emit('input', this.currentShopItem);
      },
      deep: true,
    },
  },
  computed: {
    taxRateAsALessThanOneFloat(): number {
      return this.mode.taxRate / 100;
    },
  },
  methods: {
    addToOrder() {
      const currentShopItem: ShopItem = this.currentShopItem;

      const onCanOrderConfirmed = () => {
        // Get the selected modifiers and package them together as a single SelectedModifiers object.
        const selectedModifiers: SelectedModifiers = {};
        let modifierFieldThatRequiresInput: Modifier | null = null;

        currentShopItem.modifierGroups?.forEach((modifierGroup: ModifierGroup) => {
          const modifiersForCurrGroup: OrderedModifier[] = [];
          if (modifierGroup.modifiers) {
            modifierGroup.modifiers.forEach((modifier: Modifier) => {
              if (modifier.quantitySelected) {
                if (modifier.requiresCustomerInput && !modifier.customerInput) {
                  modifierFieldThatRequiresInput = modifier;
                } else {
                  document.getElementById(`customer-input-${modifier.id}`)?.classList.remove('show-error');
                }
                const newOrderedModifier: OrderedModifier = {
                  name: modifier.name,
                  modifierId: modifier.id,
                  amountChange: modifier.amountChange || 0,
                  customerInput: modifier.customerInput || '',
                  quantity: modifier.quantitySelected,
                };
                modifiersForCurrGroup.push(newOrderedModifier);
              }
            });
          }
          selectedModifiers[modifierGroup.name] = modifiersForCurrGroup;
        });

        if (modifierFieldThatRequiresInput) {
          const modifier = modifierFieldThatRequiresInput as Modifier;
          document.getElementById(`customer-input-${modifier.id}`)?.classList.add('show-error');
          showError(t.thingRequired.supplant([modifier.customerInputLabel]));
          return;
        }

        let valid = true;
        currentShopItem.modifierGroups?.forEach((modifierGroup: ModifierGroup) => {
          if (!modifierGroup.name || !modifierGroup.modifiers.length) {
            return;
          }
          const totalQuantityOfSelectedOptions = selectedModifiers[modifierGroup.name].reduce((accumulator: number, modifier: OrderedModifier): number => {
            return modifier.quantity ? accumulator + modifier.quantity : 0;
          }, 0);
          // If the modifier group has selections and they haven't picked as many as they can without opting to purposefully select less.
          if (modifierGroup.numberOfSelections > 0 && totalQuantityOfSelectedOptions !== modifierGroup.numberOfSelections && !modifierGroup.userOptedToSelectLessThanMax) {
            valid = false;
            const scrollEl = this.$refs.belowCarouselSection as Element;
            if (scrollEl) {
              scrollEl.scrollIntoView();
            }
            if (modifierGroup.mustPickExactAmount) {
              showError(t?.mustChoose.supplant([modifierGroup.numberOfSelections, modifierGroup.name]));
            } else {
              showError(t?.mustChooseOrOptOut.supplant([modifierGroup.numberOfSelections, modifierGroup.name, (this.$refs.modifierSelection as any).optOutLabel(modifierGroup)]));
            }
          }
        });
        if (valid) {
          let amountWithModifiers = currentShopItem.price;

          if (!isEmptyObject(selectedModifiers)) {
            const arraryOfModifierArrays = Object.values(selectedModifiers) as OrderedModifier[][];
            arraryOfModifierArrays.forEach((arr: OrderedModifier[]) => {
              arr.forEach((modifier: OrderedModifier) => {
                amountWithModifiers += modifier.quantity * modifier.amountChange || 0;
              });
            });
          }

          const totalForCurrShopItem = amountWithModifiers * this.quantity;
          const { totalTaxAmount, totalAmountWithTax } = getOrderItemTaxValues(currentShopItem, this.mode.taxRate, totalForCurrShopItem);

          const orderItemToAdd: OrderItem = {
            id: generateId(),
            shopItemId: currentShopItem.id,
            name: currentShopItem.name,
            quantity: this.quantity,
            baseAmount: currentShopItem.price,
            specialRequest: '',
            amountWithModifiers,
            totalAmount: totalForCurrShopItem, // Modifiers and quantity.
            totalAmountString: formatCurrency(totalForCurrShopItem, this.mode.currency, false, false),
            totalTaxAmount,
            totalAmountWithTax, // Modifiers, quantity, and tax.
            selectedModifiers,
            hasUniqueTaxRate: currentShopItem.hasUniqueTaxRate || false,
            uniqueTaxRate: currentShopItem.uniqueTaxRate || 0,
            progenitorMinimumQuantity: currentShopItem.minimumQuantity,
          };

          if (orderItemToAdd.quantity) {
            this.onCloseShopItemModal();
            showSuccess(t?.addedToYourOrder.supplant([this.quantity, currentShopItem.name]));
            this.$emit('change', [...this.orderBreakdown, orderItemToAdd]);
          } else {
            showError(`Must have a quantity larger than 0.`, null, true); // Should not be seen normally.
          }
        }
      };
      onCanOrderConfirmed();
    },
    onCloseShopItemModal() {
      this.showShopItemModal = false;
      if (this.$parent) {
        this.$parent.$data.currentCheckoutShopItem = null;
      }
    },
  },
});
